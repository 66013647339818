import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from './AuthContext';
import { LinearProgress } from '@mui/material/';
import { Box } from '@mui/material';

const PrivateRoute = ({ element }) => {
    const { user, loading } = useContext(AuthContext);

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    backgroundColor: '#f4f6f8',
                    padding: 4,
                }}
            >
                {/* Logo */}
                <Box mb={4}>
                    <img
                        src="../../images/dashboard-white-mode.png"
                        alt="Your Logo"
                        style={{ width: '150px', objectFit: 'contain' }}
                    />
                </Box>

                {/* Linear Progress Bar */}
                <Box width="100%" maxWidth="400px">
                    <LinearProgress />
                </Box>
            </Box>
        ); // Show spinner and progress bar while checking auth status
    }

    return user ? element : <Navigate to="/login" />;
};

export default PrivateRoute;
