import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Box,
  IconButton,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Card,
  CardContent,
  Divider,
  Grid,
  Autocomplete,
} from '@mui/material';
import { useTheme } from '@mui/system';
import { tokens } from '../../theme';
import { Plus, Cog, X, Code, Globe, Wifi, Key, AlertTriangle, Layers, Zap, FileText } from 'lucide-react';
import api from '../../api/api';

// Animation variants for Framer Motion
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};

const PolicyConfig = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const policy = location.state?.policy || null;

  const [formValues, setFormValues] = useState({
    policyName: policy?.PolicyName || '',
    description: policy?.Description || '',
  });
  const [boxes, setBoxes] = useState([]); // Using JSON object
  const [isSaving, setIsSaving] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [categories, setCategories] = useState([]); // State for categories
  const [isFetchingCategories, setIsFetchingCategories] = useState(true); // Fetching state

  useEffect(() => {
    const fetchExpressions = async () => {
      try {
        const response = await api.get('/api/policy?action=read', { withCredentials: true });
        const policyData = response.data.find((expr) => expr.EntityId === policy?.id);

        if (policyData) {
          setBoxes(policyData.Attributes.conditions.map((group) => group.conditions));
        } else {
          setBoxes([[{ selector: '', operator: '', value: [] }]]);
        }
      } catch (error) {
        console.error('Failed to fetch policy expressions:', error);
        setBoxes([[{ selector: '', operator: '', value: [] }]]);
      }
    };

    if (policy) {
      setFormValues({
        policyName: policy.PolicyName || '',
        description: policy.description || '',
      });
      fetchExpressions();
    }
  }, [policy]);

  // Fetch categories from backend
  useEffect(() => {
    const fetchCategories = async () => {
      setIsFetchingCategories(true);
      try {
        const response = await api.get('/category/domain?action=read', { withCredentials: true });
        console.log('Fetched Categories Response:', response.data); // Debug the response structure
        const fetchedCategories = response.data.categories?.[0]?.categories || []; // Access the nested categories
        setCategories(fetchedCategories.map((cat) => cat.name)); // Map to extract category names
      } catch (error) {
        console.error('Error fetching categories:', error);
        setCategories([]); // Fallback to empty array on error
      } finally {
        setIsFetchingCategories(false);
      }
    };
    fetchCategories();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleConditionChange = useCallback((boxIndex, conditionIndex, field, value) => {
    setBoxes((prev) => {
      const newBoxes = [...prev];
      const updatedCondition = { ...newBoxes[boxIndex][conditionIndex], [field]: value };
  
      // For single-value inputs like Domain or URL, ensure `value` is an array
      if (field === 'value' && typeof value === 'string') {
        updatedCondition[field] = [value];
      }
  
      newBoxes[boxIndex][conditionIndex] = updatedCondition;
  
      return newBoxes;
    });
  
    // Clear errors for the updated condition
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[`box-${boxIndex}-condition-${conditionIndex}`];
      return updatedErrors;
    });
  }, []);

  const validateForm = () => {
    let isValid = true;
    const errors = {};
  
    // Iterate over all boxes and conditions
    boxes.forEach((group, boxIndex) => {
      group.forEach((condition, conditionIndex) => {
        const { selector, value } = condition;
  
        if (!selector || !value || value.length === 0) {
          isValid = false;
          errors[`box-${boxIndex}-condition-${conditionIndex}`] = "Please select a value for this condition.";
        } else {
          switch (selector) {
            case "IP":
              const ipRegex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/?(?:[0-9]|[1-2][0-9]|3[0-2])?$/;
              if (!ipRegex.test(value[0])) {
                isValid = false;
                errors[`box-${boxIndex}-condition-${conditionIndex}`] = "Enter a valid IP or CIDR (e.g., 192.168.0.1 or 192.168.0.0/24).";
              }
              break;
  
            case "Domain":
              const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
              if (!domainRegex.test(value[0])) {
                isValid = false;
                errors[`box-${boxIndex}-condition-${conditionIndex}`] = "Enter a valid domain (e.g., example.com).";
              }
              break;
  
            case "Url":
              const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/;
              if (!urlRegex.test(value[0])) {
                isValid = false;
                errors[`box-${boxIndex}-condition-${conditionIndex}`] = "Enter a valid URL (e.g., https://example.com).";
              }
              break;
  
            case "Keyword":
              if (value[0].trim() === "") {
                isValid = false;
                errors[`box-${boxIndex}-condition-${conditionIndex}`] = "Keyword cannot be empty.";
              }
              break;
  
            case "Category":
              if (value.length === 0) {
                isValid = false;
                errors[`box-${boxIndex}-condition-${conditionIndex}`] = "Select at least one category.";
              }
              break;
  
            default:
              break;
          }
        }
      });
    });
  
    setErrors(errors);
    return isValid;
  };
  

  // const validateConditions = () => {
  //   let isValid = true;

  //   for (const group of boxes) {
  //     for (const condition of group) {
  //       if (!condition.selector || condition.value.length === 0) {
  //         isValid = false;
  //         setErrors((prev) => ({
  //           ...prev,
  //           conditions: 'All conditions must have a selector and at least one value.',
  //         }));
  //       }
  //     }
  //   }

  //   return isValid;
  // };

  const addCondition = useCallback((boxIndex) => {
    setBoxes((prev) => {
      const newBoxes = [...prev];
      newBoxes[boxIndex].push({ selector: '', value: [] }); // Remove operator
      return newBoxes;
    });
  }, []);

  const addBox = useCallback(() => {
    setBoxes((prev) => [...prev, [{ selector: '', value: [] }]]); // Remove operator
  }, []);

  const removeCondition = useCallback((boxIndex, conditionIndex) => {
    setBoxes((prev) => {
      const newBoxes = [...prev];
      newBoxes[boxIndex] = newBoxes[boxIndex].filter((_, i) => i !== conditionIndex);
      if (newBoxes[boxIndex].length === 0) {
        newBoxes.splice(boxIndex, 1);
      }
      return newBoxes;
    });
  }, []);

  const handleSavePolicy = async (e) => {
    e.preventDefault();
  
    if (!validateForm()) {
      return;
    }
  
    setIsSaving(true);
  
    try {
      const combinedConditions = boxes.map((group, groupIndex) => ({
        operator: groupIndex === 0 ? 'AND' : 'OR',
        conditions: group.map(({ selector, value }) => ({
          selector,
          value,
        })),
      }));
  
      const data = {
        Attributes: {
          policyName: formValues.policyName.trim(),
          description: formValues.description.trim() || '',
          conditions: combinedConditions,
        },
      };
  
      console.log('Data being sent:', data);
  
      if (policy && policy.id) {
        await api.put(`/api/policy?action=update&EntityId=${policy.id}`, data, { withCredentials: true });
      } else {
        throw new Error('Policy ID is missing, unable to update policy');
      }
  
      navigate('/dashboard/policy');
    } catch (error) {
      console.error('Error saving policy:', error);
    } finally {
      setIsSaving(false);
    }
  };
  

  const handleCancel = () => {
    setOpenConfirmModal(true);
  };

  const handleConfirmClose = () => {
    setOpenConfirmModal(false);
    navigate('/dashboard/policy');
  };

  const handleCancelClose = () => {
    setOpenConfirmModal(false);
  };

  console.log("Policy received:", policy);

  if (!policy) {
    return (
      <Card className="w-full max-w-3xl mx-auto mt-8">
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            No Policy Found
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            Please return to the policy list.
          </Typography>
          <Button variant="contained" color="primary" onClick={() => navigate('/dashboard/policy')}>
            Go Back
          </Button>
        </CardContent>
      </Card>
    );
  }

  return (
    <motion.div
      className="container mx-auto p-4 max-w-4xl"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <motion.div variants={itemVariants} className="flex items-center mb-7 space-x-3 mx-4">
        <Typography variant="h4" component="h1" className="font-bold text-primary" sx={{ color: colors.blueAccent[500] }}>
          {/* <Shield className="w-6 h-6 text-primary" /> */}
          {policy.PolicyName}
        </Typography>
      </motion.div>

      <form onSubmit={handleSavePolicy}>
        <motion.div variants={itemVariants}>
          <Card className="mb-8 shadow-lg overflow-hidden">
            <CardContent className="p-6">
              <div className="flex items-center mb-6 space-x-3">
                <FileText className="w-6 h-6 text-primary" />
                <Typography variant="h6" component="h2" className="text-primary font-semibold">
                  Policy Details
                </Typography>
              </div>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="policyName"
                    name="policyName"
                    label="Policy Name"
                    placeholder="Enter policy name"
                    value={formValues.policyName}
                    onChange={handleInputChange}
                    required
                    error={!!errors.policyName}
                    helperText={errors.policyName || "Provide a unique name for your policy"}
                    className="mb-4"
                  />
                  {errors.policyName && (
                    <div className="flex items-center mt-1 text-red-500" role="alert">
                      <AlertTriangle className="w-4 h-4 mr-1" aria-hidden="true" />
                      <Typography variant="caption">{errors.policyName}</Typography>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="description"
                    name="description"
                    label="Description"
                    placeholder="Enter policy description (optional)"
                    value={formValues.description} // Ensure this is correctly bound
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                    className="mb-4"
                  />
                </Grid>
              </Grid>
              <Typography variant="body2" color="textSecondary" className="mt-4">
                Define the basic information for your policy. A clear name and description will help you manage multiple policies effectively.
              </Typography>
            </CardContent>
          </Card>
        </motion.div>

        <motion.div variants={itemVariants}>
          <Card className="mb-8 shadow-lg overflow-hidden">
            <CardContent className="p-6">
              <div className="flex items-center mb-4 space-x-3">
                <Cog className="w-6 h-6 text-secondary" />
                <Typography variant="h6" component="h2" className="text-secondary font-semibold">
                  Define Policy Conditions
                </Typography>
              </div>
              <Typography variant="body2" color="textSecondary" paragraph className="ml-9">
                Specify the criteria for your policy using the options below.
              </Typography>
              <div className="flex items-center mb-4 mt-6 space-x-3">
                <Layers className="w-5 h-5 text-primary" />
                <Typography variant="subtitle1" className="font-semibold text-primary">
                  Condition Groups
                </Typography>
              </div>
              <Divider className="mb-6" />
              {boxes.map((conditions, boxIndex) => (
                <React.Fragment key={boxIndex}>
                  {boxIndex > 0 && Array.isArray(conditions) && conditions.length > 0 && (
                    <Box className="flex items-center justify-center my-6">
                      <Chip
                        label="OR"
                        color="secondary"
                        variant="outlined"
                        className="font-semibold px-4 py-2"
                        icon={<Zap className="w-4 h-4" />}
                      />
                    </Box>
                  )}
                  <motion.div
                    variants={itemVariants}
                    className="mb-6 bg-gray-50 rounded-lg shadow-sm overflow-hidden border border-gray-200"
                  >
                    <CardContent className="p-6">
                      {Array.isArray(conditions) && conditions.map((condition, conditionIndex) => (
                        <motion.div
                          key={conditionIndex}
                          className="mb-6 relative p-6 bg-white rounded-lg shadow-sm border border-gray-100"
                          variants={itemVariants}
                        >
                          <Grid container spacing={4} alignItems="center">
                            {/* Selector Dropdown */}
                            <Grid item xs={12} md={3}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel id={`selector-label-${boxIndex}-${conditionIndex}`}>Condition Type</InputLabel>
                                <Select
                                  labelId={`selector-label-${boxIndex}-${conditionIndex}`}
                                  id={`selector-${boxIndex}-${conditionIndex}`}
                                  name="selector"
                                  value={condition.selector}
                                  onChange={(e) => handleConditionChange(boxIndex, conditionIndex, 'selector', e.target.value)}
                                  required
                                  label="Condition Type"
                                  className="bg-white"
                                  startAdornment={
                                    <Box className="mr-2 text-gray-400">
                                      {condition.selector === 'Category' && <Code className="w-5 h-5" />}
                                      {condition.selector === 'Domain' && <Globe className="w-5 h-5" />}
                                      {condition.selector === 'IP' && <Wifi className="w-5 h-5" />}
                                      {condition.selector === 'Keyword' && <Key className="w-5 h-5" />}
                                      {condition.selector === 'Url' && <Globe className="w-5 h-5" />}
                                    </Box>
                                  }
                                >
                                  <MenuItem value="Category">Category</MenuItem>
                                  <MenuItem value="Domain">Domain</MenuItem>
                                  <MenuItem value="IP">IP</MenuItem>
                                  <MenuItem value="Keyword">Keyword</MenuItem>
                                  <MenuItem value="Url">URL</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>

                            {/* Dynamic Form */}
                            <Grid item xs={12} md={8}>
                              {condition.selector === 'IP' && (
                               <TextField
                               fullWidth
                               variant="outlined"
                               type="text"
                               name="ipValue"
                               label="IP Address"
                               placeholder="Enter IP address (e.g., 192.168.0.1 or 192.168.0.0/24)"
                               value={condition.value[0] || ''}
                               onChange={(e) => handleConditionChange(boxIndex, conditionIndex, 'value', e.target.value)}
                               required
                               error={!!errors[`box-${boxIndex}-condition-${conditionIndex}`]}
                               helperText={errors[`box-${boxIndex}-condition-${conditionIndex}`] || ''}
                               className="bg-white"
                               InputProps={{
                                 startAdornment: (
                                   <Wifi className="w-5 h-5 mr-2 text-gray-400" />
                                 ),
                               }}
                             />
                              )}
                              {condition.selector === 'Domain' && (
                               <TextField
                               fullWidth
                               variant="outlined"
                               type="text"
                               name="domainValue"
                               label="Domain"
                               placeholder="Enter domain (e.g., example.com)"
                               value={condition.value[0] || ''}
                               onChange={(e) => handleConditionChange(boxIndex, conditionIndex, 'value', e.target.value)}
                               required
                               error={!!errors[`box-${boxIndex}-condition-${conditionIndex}`]}
                               helperText={errors[`box-${boxIndex}-condition-${conditionIndex}`]}
                               className="bg-white"
                               InputProps={{
                                 startAdornment: (
                                   <Globe className="w-5 h-5 mr-2 text-gray-400" />
                                 ),
                               }}
                             />                             
                              )}
                              {condition.selector === 'Category' && (
                                <Autocomplete
                                multiple
                                options={categories} // Use the fetched categories
                                value={condition.value || []}
                                onChange={(event, newValue) =>
                                  handleConditionChange(boxIndex, conditionIndex, 'value', newValue)
                                }
                                loading={isFetchingCategories} // Show loading indicator when fetching
                                renderTags={(value, getTagProps) =>
                                  value.map((option, idx) => (
                                    <Chip
                                      key={idx}
                                      variant="outlined"
                                      label={option}
                                      {...getTagProps({ index: idx })}
                                      className="m-1"
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Categories"
                                    placeholder={
                                      isFetchingCategories ? 'Loading...' : 'Select or enter categories'
                                    }
                                    error={!!errors[`box-${boxIndex}-condition-${conditionIndex}`]}
                                    helperText={
                                      errors[`box-${boxIndex}-condition-${conditionIndex}`]
                                    }
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: (
                                        <>
                                          <Code className="w-5 h-5 mr-2 text-gray-400" />
                                          {params.InputProps.startAdornment}
                                        </>
                                      ),
                                    }}
                                  />
                                )}
                              />                              
                              )}

                              {condition.selector === 'Keyword' && (
                                <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                name="keywordValue"
                                label="Keyword"
                                placeholder="Enter keyword (e.g., confidential)"
                                value={condition.value[0] || ''} // Bind the first value from the array
                                onChange={(e) =>
                                  handleConditionChange(boxIndex, conditionIndex, 'value', e.target.value)
                                }
                                required
                                error={!!errors[`box-${boxIndex}-condition-${conditionIndex}`]}
                                // helperText={
                                //   errors[`box-${boxIndex}-condition-${conditionIndex}`] ||
                                //   'Keyword cannot be empty.'
                                // }
                                className="bg-white"
                                InputProps={{
                                  startAdornment: (
                                    <Key className="w-5 h-5 mr-2 text-gray-400" />
                                  ),
                                }}
                              />                              
                              )}
                              {condition.selector === 'Url' && (
                               <TextField
                               type="text"
                               name="urlValue"
                               label="URL"
                               placeholder="Enter URL (e.g., https://example.com)"
                               value={condition.value[0] || ''} // Bind the first value from the array
                               onChange={(e) =>
                                 handleConditionChange(boxIndex, conditionIndex, 'value', e.target.value)
                               }
                               required
                               error={!!errors[`box-${boxIndex}-condition-${conditionIndex}`]}
                               helperText={
                                 errors[`box-${boxIndex}-condition-${conditionIndex}`]
                               }
                               className="bg-white"
                               InputProps={{
                                 startAdornment: (
                                   <Globe className="w-5 h-5 mr-2 text-gray-400" />
                                 ),
                               }}
                             />                             
                              )}
                            </Grid>

                            {/* Delete Condition */}
                            <Grid item xs={12} md={1} className="flex justify-end">
                              <IconButton
                                aria-label="delete condition"
                                size="small"
                                className="text-gray-500 hover:text-red-500 transition-colors duration-200"
                                onClick={() => removeCondition(boxIndex, conditionIndex)}
                              >
                                <X className="w-5 h-5" />
                              </IconButton>
                            </Grid>
                          </Grid>

                          {conditionIndex < conditions.length - 1 && (
                            <Box className="flex items-center justify-center my-4">
                              <Chip
                                label="AND"
                                color="primary"
                                variant="outlined"
                                className="font-semibold px-4 py-2"
                                icon={<Layers className="w-4 h-4" />}
                              />
                            </Box>
                          )}
                        </motion.div>
                      ))}
                      <Button
                        variant="outlined"
                        startIcon={<Plus className="w-4 h-4" />}
                        onClick={() => addCondition(boxIndex)}
                        className="mt-2"
                      >
                        Add Condition (AND)
                      </Button>
                    </CardContent>
                  </motion.div>
                </React.Fragment>
              ))}
              <Button
                variant="outlined"
                startIcon={<Plus className="w-4 h-4" />}
                onClick={addBox}
                className="mt-2"
              >
                Add Condition Group (OR)
              </Button>
            </CardContent>
          </Card>
        </motion.div>

        <motion.div variants={itemVariants} className="flex justify-end space-x-4">
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSaving}
            startIcon={isSaving ? <CircularProgress size={20} /> : null}
          >
            {isSaving ? 'Saving...' : 'Save Policy'}
          </Button>
        </motion.div>
      </form>

      <Dialog
        open={openConfirmModal}
        onClose={handleCancelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cancel Configuration</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to cancel? Any unsaved configurations will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClose} color="primary">
            No, Keep Editing
          </Button>
          <Button onClick={handleConfirmClose} color="primary" autoFocus>
            Yes, Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </motion.div>
  );
};

export default PolicyConfig;
