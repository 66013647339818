import React, { useState } from 'react';
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export default function ContentStep() {
  const [scanType, setScanType] = useState('Metadata & Content');

  const handleScanTypeChange = (event) => {
    setScanType(event.target.value);
  };

  return (
    <Box className="flex flex-col gap-4 p-4 min-h-screen">
      {/* Dropdown for Scan Type Selection */}
      <FormControl variant="outlined" sx={{ minWidth: 200 }}>
        <InputLabel id="scan-type-label">Scan Type</InputLabel>
        <Select
          labelId="scan-type-label"
          value={scanType}
          onChange={handleScanTypeChange}
          label="Scan Type"
        >
          <MenuItem value="Metadata & Content">Metadata & Content</MenuItem>
          <MenuItem value="Another Type">Another Type</MenuItem>
          {/* Add more options here as needed */}
        </Select>
      </FormControl>

      {/* Record Based Scan Option with Tooltip */}
      <Box sx={{ mt: 3, display: 'flex', alignItems: 'center' }}>
        <Typography variant="body1" sx={{ mr: 1 }}>
          Record Based Scan
        </Typography>
        <Tooltip title="Information about Record Based Scan" placement="top">
          <IconButton size="small">
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}
