import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
} from '@mui/material';
import api from '../../api/api';

const AddDomainForm = ({ onAddDomain, onEditDomain, initialValues }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingCategories, setIsFetchingCategories] = useState(true); // Track category fetching state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [categories, setCategories] = useState([]);
  const [customCategory, setCustomCategory] = useState('');
  const [isAddingCustomCategory, setIsAddingCustomCategory] = useState(false);

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      domainName: '',
      category: '',
    },
  });

  useEffect(() => {
    if (initialValues) {
      setValue('domainName', initialValues.name);
      setValue('category', initialValues.category);
    }
  }, [initialValues, setValue]);

  // Fetch categories from the backend
  useEffect(() => {
    const fetchCategories = async () => {
        setIsFetchingCategories(true);
        try {
            const response = await api.get('/category/domain?action=read', { withCredentials: true });
            console.log('Response:', response.data); // Debugging: Log the response
            const fetchedCategories = response.data.categories?.[0]?.categories || []; // Access the nested categories
            setCategories(fetchedCategories.map((cat) => cat.name)); // Map to an array of names
        } catch (error) {
            console.error('Error fetching categories:', error);
            setSnackbar({ open: true, message: 'Failed to fetch categories.', severity: 'error' });
        } finally {
            setIsFetchingCategories(false);
        }
    };

    fetchCategories();
}, []);

  const handleAddCustomCategory = () => {
    if (customCategory.trim() && customCategory.length <= 20) {
      setCategories((prev) => [...prev, customCategory]);
      setValue('category', customCategory); // Set the newly added category as the selected value
      setCustomCategory('');
      setIsAddingCustomCategory(false);
    } else {
      setSnackbar({
        open: true,
        message: 'Category must be a non-empty string and less than 20 characters.',
        severity: 'warning',
      });
    }
  };

  const handleCancelCustomCategory = () => {
    setIsAddingCustomCategory(false);
    setValue('category', ''); // Reset the selected value
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const transformedData = {
        Attributes: {
          domainName: data.domainName,
          category: data.category,
        },
      };

      if (initialValues) {
        // Update existing domain profile
        await onEditDomain({
          id: initialValues.id,
          name: data.domainName,
          category: data.category,
        });
      } else {
        // Create new domain profile
        const response = await api.post('/domain/profiles?action=create', transformedData, { withCredentials: true });

        if (response.status === 201) {
          onAddDomain({
            id: response.data.EntityId,
            name: response.data.Attributes.domainName,
            category: response.data.Attributes.category,
            lastEdited: new Date().toISOString(),
          });
          reset(); // Clear the form
          setSnackbar({ open: true, message: 'Domain created successfully!', severity: 'success' });
        }
      }
    } catch (error) {
      console.error('Error creating/updating domain:', error);
      setSnackbar({ open: true, message: 'Failed to create/update domain.', severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <Controller
          name="domainName"
          control={control}
          rules={{
            required: 'Domain name is required',
            pattern: {
              value: /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/,
              message: 'Invalid domain name format',
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Domain Name"
              variant="outlined"
              fullWidth
              error={!!errors.domainName}
              helperText={errors.domainName?.message}
            />
          )}
        />
        {!isAddingCustomCategory ? (
          <FormControl fullWidth variant="outlined">
            <InputLabel>Category</InputLabel>
            <Controller
              name="category"
              control={control}
              rules={{ required: 'Category is required' }}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Category"
                  fullWidth
                  error={!!errors.category}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200, // Make the dropdown scrollable
                      },
                    },
                  }}
                >
                  {isFetchingCategories ? (
                    <MenuItem disabled>Loading...</MenuItem>
                  ) : categories.length > 0 ? (
                    categories.map((cat) => (
                      <MenuItem key={cat} value={cat}>
                        {cat}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No categories available</MenuItem>
                  )}
                </Select>
              )}
            />
          </FormControl>

        ) : (
          <div className="flex items-center space-x-2">
            <TextField
              label="New Category"
              variant="outlined"
              fullWidth
              value={customCategory}
              onChange={(e) => setCustomCategory(e.target.value)}
              error={!customCategory.trim() || customCategory.length > 20}
              helperText={!customCategory.trim() ? 'Category is required' : customCategory.length > 20 ? 'Category must be less than 20 characters' : ''}
            />
            <Button
              onClick={handleAddCustomCategory}
              variant="contained"
              color="primary"
              disabled={!customCategory.trim() || customCategory.length > 20}
            >
              Add
            </Button>
            <Button
              onClick={handleCancelCustomCategory}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
          </div>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={!isValid || isLoading || (isAddingCustomCategory && (!customCategory.trim() || customCategory.length > 20))}
        >
          {isLoading ? <CircularProgress size={24} /> : initialValues ? 'Update Domain' : 'Add Domain'}
        </Button>
      </form>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddDomainForm;