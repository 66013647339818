import React from "react";
import {  useTheme, Tabs, Tab, Box } from "@mui/material";
import { tokens } from "../theme";

const TabSwitcher = ({ tabs, activeTab, setActiveTab }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
         <Box className="flex mt-4" sx={{
            borderBottom: 1,
            borderColor: 'divider',
         }}>
            <Tabs
                value={activeTab}
                onChange={handleChange}
                indicatorColor="primary"
                variant="fullWidth"
                sx={{
                    "& .MuiTabs-indicator": {
                        backgroundColor: colors.grey[400],
                    }
                }}
            >
                {tabs.map((tab, index) => (
                    <Tab
                        key={index}
                        label={tab}
                        color={colors.grey[100]}
                        sx={{
                            textTransform: "capitalize",
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                            color: activeTab === index ? colors.grey[400] : colors.grey[400],
                            "&.Mui-selected": {
                                color: colors.greenAccent[700], 
                            }
                        }
                        }
                    />
                ))}
            </Tabs>
        </Box>
    );
};

export default TabSwitcher;