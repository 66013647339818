import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button, CircularProgress, Snackbar, Alert, Box, Typography } from '@mui/material';
import api from '../../api/api';
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";

const AddNetworkForm = ({ onAddNetwork, onEditNetwork, initialValues }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    setError,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      networkName: '',
      ipAddress: '',
    },
  });

  useEffect(() => {
    if (initialValues) {
      setValue('networkName', initialValues.name);
      setValue('ipAddress', initialValues.ip);
    }
  }, [initialValues, setValue]);

  const checkNetworkNameUnique = async (networkName) => {
    try {
      const response = await api.get(`/network/profiles?action=check-name&name=${encodeURIComponent(networkName)}`);
      return response.data.isUnique;
    } catch (error) {
      console.error('Error checking network name uniqueness:', error);
      return false; // Treat errors as the name not being unique
    }
  };

  const checkIpAddressUnique = async (ipAddress) => {
    try {
      const response = await api.get(`/network/profiles?action=check-ip&ip=${encodeURIComponent(ipAddress)}`);
      return response.data.isUnique;
    } catch (error) {
      console.error('Error checking IP address uniqueness:', error);
      return false; // Treat errors as the IP address not being unique
    }
  };

  const onSubmit = async (data) => {
    setIsLoading(true);

    const trimmedNetworkName = data.networkName.trim();
    const trimmedIpAddress = data.ipAddress.trim();

    try {
      if (!initialValues || trimmedNetworkName !== initialValues.name) {
        // Check if the network name is unique
        const isUniqueName = await checkNetworkNameUnique(trimmedNetworkName);
        if (!isUniqueName) {
          setError("networkName", {
            type: "manual",
            message: "A network with this name already exists, please choose a different name.",
          });
          setIsLoading(false);
          return;
        }
      }

      if (!initialValues || trimmedIpAddress !== initialValues.ip) {
        // Check if the IP address is unique
        const isUniqueIp = await checkIpAddressUnique(trimmedIpAddress);
        if (!isUniqueIp) {
          setError("ipAddress", {
            type: "manual",
            message: "A network with this IP address already exists, please choose a different IP address.",
          });
          setIsLoading(false);
          return;
        }
      }

      const transformedData = {
        Attributes: {
          networkName: trimmedNetworkName,
          ipAddress: trimmedIpAddress,
        },
      };

      if (initialValues) {
        // Update existing network profile
        await onEditNetwork({
          id: initialValues.id,
          name: data.networkName,
          ip: data.ipAddress,
        });
      } else {
        // Create new network profile
        const response = await api.post('/network/profiles?action=create', transformedData, { withCredentials: true });

        if (response.status === 201) {
          onAddNetwork({
            id: response.data.EntityId,
            name: response.data.Attributes.networkName,
            ip: response.data.Attributes.ipAddress,
            lastEdited: new Date().toISOString(),
          });
          reset(); // Clear the form
          setSnackbar({ open: true, message: 'Network profile created successfully!', severity: 'success' });
        }
      }
    } catch (error) {
      console.error('Error creating/updating network profile:', error);
      setSnackbar({ open: true, message: 'Failed to create/update network profile.', severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      className="max-w-md mx-auto mt-8 p-6 rounded-lg"
      // sx={{
      //   backgroundColor: colors.primary[400],
      //   color: colors.grey[100],
      // }}
    >
      <Typography variant="h5" component="h2" className="text-center" sx={{ color: colors.grey[100] }}>
        Add Network
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <Controller
          name="networkName"
          control={control}
          rules={{
            required: 'Network name is required',
            minLength: { value: 3, message: 'Network name must be at least 3 characters long' },
            maxLength: { value: 50, message: 'Network name must not exceed 50 characters' },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Network Name"
              variant="outlined"
              fullWidth
              error={!!errors.networkName}
              helperText={errors.networkName?.message}
              inputProps={{ 'aria-label': 'Network Name' }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: colors.grey[100],
                  },
                  '&:hover fieldset': {
                    borderColor: colors.blueAccent[500],
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.blueAccent[500],
                  },
                },
                '& .MuiInputLabel-root': {
                  color: colors.grey[100],
                },
                '& .MuiFormHelperText-root': {
                  color: colors.redAccent[500],
                },
              }}
            />
          )}
        />
        <Controller
          name="ipAddress"
          control={control}
          rules={{
            required: 'IP address is required',
            pattern: {
              value: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/?(?:[0-9]|[1-2][0-9]|3[0-2])?$/,
              message: 'Invalid IP address or CIDR format',
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="IP Address or CIDR"
              variant="outlined"
              fullWidth
              error={!!errors.ipAddress}
              helperText={errors.ipAddress?.message}
              inputProps={{ 'aria-label': 'IP Address or CIDR' }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: colors.grey[100],
                  },
                  '&:hover fieldset': {
                    borderColor: colors.blueAccent[500],
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.blueAccent[500],
                  },
                },
                '& .MuiInputLabel-root': {
                  color: colors.grey[100],
                },
                '& .MuiFormHelperText-root': {
                  color: colors.redAccent[500],
                },
              }}
            />
          )}
        />
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: colors.blueAccent[500],
            '&:hover': {
              backgroundColor: colors.blueAccent[700],
            },
            color: colors.grey[100],
            fontWeight: 'bold',
            py: 2,
            px: 4,
            borderRadius: 1,
          }}
          fullWidth
          disabled={!isValid || isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : initialValues ? 'Update Network' : 'Add Network'}
        </Button>
      </form>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddNetworkForm;