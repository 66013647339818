import React, { useState } from 'react';
import {
    Box,
    Typography,
    Button,
    Modal,
    Menu,
    IconButton,
    MenuItem,   
    Chip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from "../../theme";
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Header from '../../components/Header';
import DrawerConfig from './DrawerConfig';
import PolicyForm from './PolicyForm'; // Assuming you have a form component
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const mockDataTeam = [
    { id: 1, PolicyName: 'Policy 1', PolicyID: 'ABC1-1234-1234-1234', action: 'BLOCK', enabled: 'On', lastEdited: '2023-07-30' },
    // Add more mock data as needed
];

function NetworkTab() {
    const theme = useTheme();
    const [rows, setRows] = useState(mockDataTeam);
    const [open, setOpen] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedPolicy, setSelectedPolicy] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();

    const handleOpen = () => {
        setSelectedPolicy(null);
        setIsEditMode(false);
        setOpen(true);
    };

    const handleClose = () => setOpen(false);
    const handleExit = () => setAnchorEl(null);

    const handleRowClick = (params) => {
        if (params.field === "PolicyName") {
            setSelectedPolicy(params.row);
            setDrawerOpen(true);
        }
    };

    const handleToggleEnabled = (id) => {
        setRows((prevRows) =>
            prevRows.map((row) =>
                row.id === id
                    ? { ...row, enabled: row.enabled === "On" ? "Off" : "On" }
                    : row
            )
        );
    };

    const handleDeleteRow = (policyName, policyId) => {
        setRows((prevRows) => prevRows.filter(row => !(row.PolicyName === policyName && row.PolicyID === policyId)));
        setAnchorEl(null); // Ensure the menu is closed
    };

    const handleAddPolicy = (data) => {
        if (isEditMode) {
            setRows((prevRows) =>
                prevRows.map((row) =>
                    row.id === selectedPolicy.id
                        ? {
                            ...row,
                            PolicyName: data.policyName,
                            action: data.action,
                            lastEdited: new Date().toLocaleString(),
                        }
                        : row
                )
            );
        } else {
            const newRow = {
                id: rows.length + 1,
                action: data.action,
                PolicyName: data.policyName,
                PolicyID: Math.random().toString(36).substr(2, 16),
                enabled: data.enabled ? 'On' : 'Off',
                lastEdited: new Date().toLocaleString()
            };
            setRows([...rows, newRow]);
        }
        handleClose();
    };

    const handleCopyPolicyId = (policyId) => {
        navigator.clipboard.writeText(policyId).then(() => {
            alert('Policy ID copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy policy ID: ', err);
        });
    };

    const handleConfigure = (policy) => {
        navigate('/policy/rule', { state: { policy } });
        setAnchorEl(null);
    };

    const handleEdit = (policy) => {
        setSelectedPolicy(policy);
        setIsEditMode(true);
        setOpen(true);
        setAnchorEl(null);
    };

    const getChipColor = (action) => {
        switch (action) {
            case 'BLOCK':
                return '#ffcccc'; // Light red
            case 'ALLOW':
                return '#ccffcc'; // Light green
            case 'WHITELIST':
                return '#cccccc'; // Grey
            default:
                return '#eeeeee'; // Default grey
        }
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const columns = [
        { field: "id", headerName: "#", width: 70 },
        {
            field: "PolicyName",
            // make the header name bold
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    Policy name
                </Typography>
            ),
            flex: 1,
            renderCell: (params) => (
                <Typography
                    sx={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        marginTop: "15px",
                        fontSize: "12px",
                    }}
                    color={colors.greenAccent[400]}
                >
                    {params.value}
                </Typography>
            ),
        },
        {
            field: "PolicyID",
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    Policy ID
                </Typography>
            ),
            width: 200,
            renderCell: (params) => (
                <>
                    {params.value}
                    <IconButton
                        aria-label="copy"
                        onClick={() => handleCopyPolicyId(params.value)}
                        size="small"
                        style={{ marginLeft: 'auto' }}
                    >
                        <ContentCopyIcon fontSize="small" />
                    </IconButton>
                </>
            ),
        },
        {
            field: "action",
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    Action
                </Typography>
            ),
            flex: 1,
            renderCell: (params) => (
                <Chip
                    size="small"
                    variant="outlined"
                    label={params.value}
                    sx={{
                        backgroundColor: getChipColor(params.value),
                        color: params.value === 'BLOCK' ? 'red' : (params.value === 'ALLOW' ? 'green' : 'black'),
                        width: '100px', // Adjust width as needed
                        justifyContent: 'center',
                    }}
                />
            ),
        },
        {
            field: "enabled",
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                     Enabled
                </Typography>
            ),
            flex: 1,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color={params.value === "On" ? "success" : "error"}
                    size="small"
                    onClick={() => handleToggleEnabled(params.id)}
                >
                    {params.value === "On" ? <ToggleOnOutlinedIcon /> : <ToggleOffOutlinedIcon />}
                </Button>
            ),
        },
        {
            field: "lastEdited",
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                     Last Edited
                </Typography>
            ),
            flex: 1,
            renderCell: ({ row: { lastEdited } }) => (
                <Typography variant="body2" color="textSecondary">
                    {lastEdited}
                </Typography>
            ),
        },
        {
            flex: 1,
            renderCell: (params) => (
                <Box>
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleExit}
                    >
                        <MenuItem onClick={() => handleConfigure(params.row)}><SettingsIcon />Configure</MenuItem>
                        <MenuItem onClick={() => handleEdit(params.row)}><EditIcon /> Edit</MenuItem>
                        <MenuItem onClick={() => handleDeleteRow(params.row.PolicyName, params.row.PolicyID)}><DeleteIcon /> Delete</MenuItem>
                    </Menu>
                </Box>
            ),
        },
    ];

    return (
        <div className="mx-8">
            <div className="flex items-center rounded-md justify-between">
                <div>
                    <Header subtitle="Create and manage Network policies" />
                    <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        onClick={handleOpen}
                    >
                        <AddIcon />
                        Add a Policy
                    </Button>
                </div>
                <div className="flex bg-primary-400 rounded-md w-1/4" style={{ backgroundColor: colors.primary[400], marginLeft: 'auto' }}>
                    <InputBase className="ml-2 flex-1" placeholder="Search values" />
                    <IconButton type="button" className="p-1">
                        <SearchIcon />
                    </IconButton>
                </div>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <PolicyForm onSubmit={handleAddPolicy} initialData={isEditMode ? selectedPolicy : null} />
                </Box>
            </Modal>

            <div className="mt-8">
                <div
                    className="bg-primary-400 w-full h-full"
                >
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[5, 10, 20]}
                        disableRowSelectionOnClick
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 8,
                                },
                            },
                        }}
                        onCellClick={handleRowClick}
                        style={{ height: `${window.innerHeight - 100}px` }}
                        sx={{ '&, [class^=MuiDataGrid]': { borderLeft: 'none', borderRight: 'none', borderBottom: 'none', } }}
                    />
                </div>
            </div>

            <DrawerConfig
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                selectedPolicy={selectedPolicy}
            />
        </div>
    );
}

export default NetworkTab;
