import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  Tabs,
  Tab,
  TextField,
  Button,
  Select,
  DialogTitle,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemSecondaryAction,
  Checkbox,
  Snackbar,
  Alert,
  Divider,
} from '@mui/material';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { isValidRegex, testRegex, isValidKeyword, testKeyword } from '../../../utils/validator';
import api from '../../../api/api';
import { useTheme } from '@mui/system';
import { tokens } from "../../../theme";


const StyledTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
});

const StyledTab = styled(Tab)({
  textTransform: 'none',
  minWidth: 0,
  fontWeight: 'bold',
  marginRight: '24px',
  '&.Mui-selected': {
    color: '#1890ff',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
});

const sensitivityOptions = ['Case Sensitive', 'High Sensitive', 'Medium Sensitive', 'Low Sensitive'];
const inputTypes = ['Keyword', 'Regex'];

export default function CustomStep() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { getValues, setValue, clearErrors, formState: { errors } } = useFormContext();
  const [activeTab, setActiveTab] = useState(0);
  const [identifierName, setIdentifierName] = useState('');
  const [sensitivity, setSensitivity] = useState('Case Sensitive');
  const [identifier, setIdentifier] = useState('');
  const [inputType, setInputType] = useState('Keyword');
  const [isValidationDialogOpen, setIsValidationDialogOpen] = useState(false);
  const [testInput, setTestInput] = useState('');
  const [validationResult, setValidationResult] = useState(null);
  const [savedIdentifiers, setSavedIdentifiers] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [selectedCustomIdentifiers, setSelectedCustomIdentifiers] = useState([]);

  useEffect(() => {
    fetchSavedIdentifiers();
  }, []);

  useEffect(() => {
    // Sync local state with form state on component mount
    const currentIdentifiers = getValues("selectedCustomIdentifiers") || [];
    setSelectedCustomIdentifiers(currentIdentifiers);
  }, [getValues]);

  const fetchSavedIdentifiers = async () => {
    try {
      const response = await api.get(`/custom/custom-identifiers?action=read`);
      setSavedIdentifiers(response.data);
    } catch (error) {
      console.error('Error fetching saved identifiers:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleIdentifierNameChange = (event) => {
    setIdentifierName(event.target.value);
  };

  const handleSensitivityChange = (event) => {
    setSensitivity(event.target.value);
  };

  const handleIdentifierChange = (event) => {
    setIdentifier(event.target.value);
  };

  const handleInputTypeChange = (event) => {
    setInputType(event.target.value);
    setIdentifier('');
  };

  const handleValidate = () => {
    if (inputType === 'Regex' && !isValidRegex(identifier)) {
      alert("Invalid regex pattern. Please correct it.");
    } else {
      setIsValidationDialogOpen(true);
    }
  };

  const handleTest = () => {
    let result;
    if (inputType === 'Regex') {
      if (!isValidRegex(identifier)) {
        setValidationResult("Invalid regex pattern.");
        return;
      }
      result = testRegex(identifier, testInput);
    } else {
      result = testKeyword(identifier, testInput);
    }
    setValidationResult(result ? "Match found!" : "No match found.");
  };

  const handleCloseValidationDialog = () => {
    setIsValidationDialogOpen(false);
    setTestInput('');
    setValidationResult(null);
  };

  const handleAddIdentifier = async () => {
    const newCondition = {
      identifierName,
      condition: {
        conditionType: inputType,
        conditionValue: identifier,
        operator: inputType === 'Regex' ? 'matches' : 'contains',
        sensitivity,
      },
    };

    try {
      const response = await api.post(`/custom/custom-identifiers?action=create`, newCondition, { withCredentials: true });
      setSavedIdentifiers([...savedIdentifiers, response.data]);
      setIdentifierName('');
      setIdentifier('');
      setSnackbarMessage('Custom Identifier saved successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error adding custom identifier:', error);
      setSnackbarMessage('Failed to save Custom Identifier');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDeleteIdentifier = async (id) => {
    try {
      await api.delete(`/custom/custom-identifiers?action=delete&EntityId=${id}`);
      setSavedIdentifiers(savedIdentifiers.filter(identifier => identifier.EntityId !== id));
      setSnackbarMessage('Custom Identifier deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error deleting custom identifier:', error);
      setSnackbarMessage('Failed to delete Custom Identifier');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCheckboxChange = (identifierName) => {
    const currentIdentifiers = getValues("selectedCustomIdentifiers") || [];
    const updatedIdentifiers = currentIdentifiers.some((identifier) => identifier.identifierName === identifierName)
      ? currentIdentifiers.filter((identifier) => identifier.identifierName !== identifierName)
      : [...currentIdentifiers, savedIdentifiers.find(identifier => identifier.Attributes.identifierName === identifierName).Attributes];

    setValue("selectedCustomIdentifiers", updatedIdentifiers, { shouldValidate: true });
    setSelectedCustomIdentifiers(updatedIdentifiers);

    if (updatedIdentifiers.length > 0) {
      clearErrors("selectedCustomIdentifiers");
    }

    console.log('Updated Identifiers:', updatedIdentifiers);
  };

  const isValidInput = inputType === 'Regex' ? isValidRegex(identifier) : isValidKeyword(identifier);

  return (
    <Box className="flex flex-col gap-4 p-4 min-h-dvh">
      <Box sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
        <StyledTabs value={activeTab} onChange={handleTabChange} aria-label="data identifier tabs">
          <StyledTab label="New Identifiers" />
          <StyledTab label="Saved Identifiers" />
        </StyledTabs>
      </Box>
      <Box sx={{ p: 3 }}>
        {activeTab === 0 && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Enter a name"
                value={identifierName}
                onChange={handleIdentifierNameChange}
                sx={{ flex: 1 }}
              />
              <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                <InputLabel id="sensitivity-label">Sensitivity</InputLabel>
                <Select
                  labelId="sensitivity-label"
                  value={sensitivity}
                  onChange={handleSensitivityChange}
                  label="Sensitivity"
                >
                  {sensitivityOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                <InputLabel id="input-type-label">Input Type</InputLabel>
                <Select
                  labelId="input-type-label"
                  value={inputType}
                  onChange={handleInputTypeChange}
                  label="Input Type"
                >
                  {inputTypes.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                onClick={handleValidate}
                sx={{
                  marginLeft: 'auto',
                  
                }}
              >
                {`VALIDATE ${inputType.toUpperCase()}`}
              </Button>
            </Box>
            {errors.selectedCustomIdentifiers?.message && ( // Ensure safe access
              <Typography variant="body2" color="error">
                {errors.selectedCustomIdentifiers.message}
              </Typography>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder={inputType === 'Regex' ? "Enter a regular expression" : "Enter a keyword"}
                value={identifier}
                onChange={handleIdentifierChange}
                error={identifier !== '' && !isValidInput} // Show error for invalid input
                helperText={
                  identifier !== '' && !isValidInput
                    ? inputType === 'Regex'
                      ? "Invalid regular expression"
                      : "Invalid keyword"
                    : ""
                }
                sx={{ flex: 1 }}
              />
              {errors.customKeywords?.message && ( // Ensure safe access
                        <Typography color="error" variant="body2">
                          {errors.customKeywords.message}
                        </Typography>
                      )}

              <IconButton
                onClick={handleAddIdentifier}
                disabled={identifierName === '' || identifier === '' || !isValidInput}
                sx={{
                  '&:hover': {
                    bgcolor: colors.primary[200],
                  },
                  '&:disabled': {
                    bgcolor: '#ccc',
                  },
                }}
              >
                <AddIcon />
              </IconButton>
            </Box>
            {inputType === 'Regex' && (
              <Box mt={1} color="gray">
                Example: Use `^[a-zA-Z0-9]+$` to match alphanumeric characters.
              </Box>
            )}
          </>
        )}
        {activeTab === 1 && (
          <Box>
            {savedIdentifiers.length === 0 ? (
              <Typography variant="body2" color="textSecondary">
                No saved identifiers
              </Typography>
            ) : (
              <List>
                {savedIdentifiers.map((identifier) => (
                  <React.Fragment key={identifier.EntityId}>
                    <ListItem sx={{ borderRadius: 1, mb: 1, boxShadow: 1 }}>
                    <Checkbox
                        checked={selectedCustomIdentifiers.some((item) => item.identifierName === identifier.Attributes.identifierName)}
                        onChange={() => handleCheckboxChange(identifier.Attributes.identifierName)}
                      />
                      <Box sx={{ flex: 1 }}>
                        <Typography variant="h6" component="div">
                          {identifier.Attributes.identifierName}
                        </Typography>
                        {identifier.Attributes.condition && (
                          <>
                            <Typography variant="body2" color="textSecondary">
                              Sensitivity: {identifier.Attributes.condition.sensitivity}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {identifier.Attributes.condition.conditionType}: {identifier.Attributes.condition.conditionValue}
                            </Typography>
                          </>
                        )}
                      </Box>
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteIdentifier(identifier.EntityId)}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            )}
          </Box>
        )}
      </Box>
      <Dialog open={isValidationDialogOpen} onClose={handleCloseValidationDialog}>
        <DialogTitle>{`Test ${inputType}`}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="test-input"
            label="Test Input"
            type="text"
            fullWidth
            variant="outlined"
            value={testInput}
            onChange={(e) => setTestInput(e.target.value)}
          />
          {validationResult !== null && (
            <Box mt={2} color={validationResult ? 'green' : 'red'}>
              {validationResult ? 'Match found!' : 'No match found.'}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseValidationDialog}>Close</Button>
          <Button onClick={handleTest} variant="contained" color="primary">
            Test
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}