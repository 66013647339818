import React, { useEffect, useState, useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { useTheme } from '@mui/system';
import { tokens } from "../../../theme";

const severityLevels = [
  { label: 'Low Severity', name: 'low', defaultValue: '5' },
  { label: 'Medium Severity', name: 'medium', defaultValue: '50' },
  { label: 'High Severity', name: 'high', defaultValue: '100' },
  { label: 'Critical Severity', name: 'critical', defaultValue: '1000' },
];

export default function SeverityThreshold() {
  const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const { control, watch, formState: { errors } } = useFormContext();
  const [policyActionLevel, setPolicyActionLevel] = useState('low');

  const watchPolicyActionLevel = watch('policyActionLevel');

  useEffect(() => {
    setPolicyActionLevel(watchPolicyActionLevel);
  }, [watchPolicyActionLevel]);

  const policyActionVisibility = useMemo(() => {
    const levels = ['low', 'medium', 'high', 'critical'];
    const selectedIndex = levels.indexOf(policyActionLevel);
    return levels.reduce((acc, level, index) => {
      acc[level] = index >= selectedIndex;
      return acc;
    }, {});
  }, [policyActionLevel]);

  return (
      <Box className="flex flex-col gap-4 p-4 min-h-dvh">
        <Typography variant="h6" sx={{ marginBottom: 2 }}>Set Threshold using:</Typography>
        <Controller
          name="thresholdType"
          control={control}
          defaultValue="record"
          render={({ field }) => (
            <RadioGroup {...field} row sx={{ marginBottom: 2 }}>
              <FormControlLabel value="record" control={<Radio />} label="Record" />
              <FormControlLabel value="aggregatedScore" control={<Radio />} label="Aggregated Score" />
            </RadioGroup>
          )}
        />

        <Controller
          name="countUniqueRecord"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} />}
              label="Count only unique record"
              sx={{ marginBottom: 2 }}
            />
          )}
        />

{severityLevels.map((level) => (
  <Box key={level.name} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
    <Typography sx={{ width: '150px' }}>{level.label}</Typography>
    <Controller
      name={`severityThresholds.${level.name}`}
      control={control}
      defaultValue={level.defaultValue}
      render={({ field }) => (
        <TextField
          {...field}
          type="number"
          variant="outlined"
          size="small"
          error={Boolean(errors?.severityThresholds?.[level.name])}
          helperText={errors?.severityThresholds?.[level.name]?.message || ''}
          sx={{ width: '100px', marginRight: 2 }}
        />
      )}
    />
    <Typography>Records or More</Typography>
    {policyActionVisibility[level.name] && (
      <Typography sx={{ marginLeft: '200px' }} className="text-red-600">
        Policy action will be taken
      </Typography>
    )}
  </Box>
))}

        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <Typography sx={{ marginRight: 2 }}>Take policy action at</Typography>
          <Controller
            name="policyActionLevel"
            control={control}
            defaultValue="low"
            render={({ field }) => (
              <FormControl size="small" sx={{ minWidth: 120 }}>
                <Select {...field}>
                  <MenuItem value="low">Low</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="high">High</MenuItem>
                  <MenuItem value="critical">Critical</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <Typography sx={{ marginLeft: 1 }}>Severity</Typography>
        </Box>

        <Typography sx={{ color: 'text.secondary' }}>
          Alerts will be generated at lower severities.
        </Typography>
      </Box>
  );
}
