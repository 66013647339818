import React, { useState, cloneElement } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  StepConnector,
  Typography,
  Box,
  IconButton,
  Container,
  Divider
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTheme } from '@mui/system';
import { tokens } from "../../theme"; 
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CloseIcon from "@mui/icons-material/Close"; 
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import SpeedIcon from "@mui/icons-material/Speed";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import PredefinedStep from "./steps/PredefinedStep";
import CustomStep from "./steps/CustomStep";
import AdvancedOptionsStep from "./steps/AdvancedOptionsStep";
import ContentStep from "./steps/ContentStep";
import SeverityThreshold from "./steps/SeverityThreshold";
import SetRule from "./steps/SetRule";

// Custom connector style with color and animation
const CustomConnector = () => (
  <StepConnector
    sx={{
      "& .MuiStepConnector-line": {
        borderColor: "#e0e0e0", // neutral gray
        borderTopWidth: 3,
      },
      "&.Mui-active .MuiStepConnector-line": {
        borderColor: "#3f51b5", // active blue
      },
      "&.Mui-completed .MuiStepConnector-line": {
        borderColor: "#4caf50", // completed green
      },
    }}
  />
);

// Custom Step Icons
const StepIcon = ({ icon, active, completed }) => {
  return completed ? (
    <CheckCircleIcon color="success" fontSize="large" />
  ) : (
    cloneElement(icon, {
      fontSize: "large",
      sx: {
        color: active ? "#3f51b5" : "#9e9e9e",
        transition: "transform 0.3s",
        transform: active ? "scale(1.1)" : "scale(1)",
      },
    })
  );
};

const stepIcons = [
  <AccountCircleOutlinedIcon />,
  <TuneOutlinedIcon />,
  <SettingsOutlinedIcon />,
  <ContentPasteIcon />,
  <SpeedIcon />,
  <PlaylistAddCheckIcon />,
];

const DLPProfileForm = ({ onClose }) => {
  const steps = [
    "PREDEFINED",
    "CUSTOM",
    "ADVANCED OPTIONS",
    "CONTENT",
    "SEVERITY THRESHOLD",
    "SET RULE",
  ];
  const [activeStep, setActiveStep] = useState(0);
  const methods = useForm();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode); // get the appropriate theme colors

  const isLastStep = activeStep === steps.length - 1;

  const handleNext = (data) => {
    if (isLastStep) {
      console.log("Form submitted:", data);
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const onSubmit = (data) => {
    handleNext(data);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <PredefinedStep />;
      case 1:
        return <CustomStep />;
      case 2:
        return <AdvancedOptionsStep />;
      case 3:
        return <ContentStep />;
      case 4:
        return <SeverityThreshold />;
      case 5:
        return <SetRule />;
      default:
        return null;
    }
  };

  return (
    <Box className="container py-10">
    <Container
    sx={{
      backgroundColor: colors.primary[400],
      maxWidth: "90vw", // Increase maximum width to cover more screen space
      width: "90vw", // Set width to 90% of the viewport width for more room
      maxHeight: "80vh", 
      overflow: "hidden",
      marginLeft: "-250px",
      marginRight: "auto",
    }}
  >
    {/* Header with Close Icon */}
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        marginBottom: "40px",
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        sx={{
          fontWeight: "bold",
          color: colors.blueAccent[500],
          maxWidth: { xs: "90%", sm: "70%", md: "700px" },
          textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
        }}
      >
        Create New DLP Rule
      </Typography>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  
    {/* Stepper */}
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<CustomConnector />}
      sx={{
        width: "100%",
        maxWidth: "90vw", // Adjust to fit the new container width
        marginBottom: 6,
        marginRight: 3,
        flexWrap: { xs: "wrap", sm: "nowrap" },
        "& .MuiStep-root": {
          flex: 1,
          minWidth: "100px",
        },
      }}
    >
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={() =>
              StepIcon({
                icon: stepIcons[index],
                active: activeStep === index,
                completed: activeStep > index,
              })
            }
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: "medium",
                color: activeStep === index ? colors.blueAccent[400] : colors.grey[500],
                transition: "color 0.3s ease",
              }}
            >
              {label}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  
    {/* Form Content */}
    <Box sx={{ overflowY: 'auto', maxHeight: '60vh', pr: 1 }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {renderStepContent(activeStep)}
  
          {/* Buttons */}
          <Box
          sx={{
            position: "sticky",
            bottom: 36,
            display: "flex",
            justifyContent: "space-between",
            py: 2,
            backgroundColor: "#f5f5f5",
          }}
        >
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            variant="outlined"
            color="secondary"
            sx={{
              borderColor: activeStep === 0 ? colors.grey[500] : colors.blueAccent[500],
              color: activeStep === 0 ? colors.grey[500] : colors.blueAccent[500],
              "&:hover": {
                borderColor: colors.blueAccent[400],
              },
              transition: "all 0.3s",
            }}
          >
            Back
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: colors.blueAccent[400],
              color: colors.grey[100],
              "&:hover": {
                backgroundColor: colors.blueAccent[300],
              },
              transition: "all 0.3s",
            }}
          >
            {isLastStep ? "Submit" : "Next"}
          </Button>
        </Box>
        </form>
      </FormProvider>
    </Box>
    <Divider />
  </Container>  
  </Box>
  );
};

export default DLPProfileForm;
