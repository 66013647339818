import React, { createContext, useState, useEffect } from 'react';
import api from '../../api/api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true); // Add a loading state


    useEffect(() => {
        const checkAuth = async () => {
            try {
                // fetching user data by sending a request to the `/auth/me` endpoint
                const response = await api.get('/auth/me', { withCredentials: true });
                setUser(response.data.user);
            } catch (error) {
                setUser(null); // No user is authenticated
            } finally {
                setLoading(false); // Stop loading when the check is complete
            }
        };

        checkAuth();
    }, []);

    const login = async (email, password) => {
        const response = await api.post('/auth/login', { email, password }, { withCredentials: true });
        setUser(response.data.user);
    };

    const signup = async (username, email, password) => {
        const response = await api.post('/auth/signup', { username, email, password }, { withCredentials: true });
        setUser(response.data.user);
    };

    const logout = async () => {
        await api.post('/auth/logout', {}, { withCredentials: true });
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, login, signup, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
