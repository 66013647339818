import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ThemeProvider,
  createTheme,
} from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
    },
  },
});

const SeverityThreshold = () => {
  const { control } = useFormContext();

  const severityLevels = [
    { label: 'Low Severity', value: 5 },
    { label: 'Medium Severity', value: 50 },
    { label: 'High Severity', value: 100 },
    { label: 'Critical Severity', value: 1000 },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ maxWidth: '800px', margin: 'auto', marginTop: 2, padding: -2 }}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>Set Threshold using:</Typography>
        <Controller
          name="thresholdType"
          control={control}
          defaultValue="record"
          render={({ field }) => (
            <RadioGroup {...field} row sx={{ marginBottom: 2 }}>
              <FormControlLabel value="record" control={<Radio />} label="Record" />
              <FormControlLabel value="aggregatedScore" control={<Radio />} label="Aggregated Score" />
            </RadioGroup>
          )}
        />

        <Controller
          name="countUniqueRecord"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} />}
              label="Count only unique record"
              sx={{ marginBottom: 2 }}
            />
          )}
        />

        {severityLevels.map((level) => (
          <Box key={level.label} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
            <Typography sx={{ width: '150px' }}>{level.label}</Typography>
            <Controller
              name={`${level.label.toLowerCase().replace(' ', '')}`}
              control={control}
              defaultValue={level.value}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  variant="outlined"
                  size="small"
                  sx={{ width: '100px', marginRight: 2 }}
                />
              )}
            />
            <Typography>Records or More</Typography>
            {/* add a right arrow */}
            <Typography sx={{ marginLeft: 'auto' }} className='text-red-600'>
              policy action will be taken
            </Typography>
          </Box>
        ))}

        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <Typography sx={{ marginRight: 2 }}>Take policy action at</Typography>
          <Controller
            name="policyActionLevel"
            control={control}
            defaultValue="low"
            render={({ field }) => (
              <FormControl size="small" sx={{ minWidth: 120 }}>
                <Select {...field}>
                  <MenuItem value="low">Low</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="high">High</MenuItem>
                  <MenuItem value="critical">Critical</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <Typography sx={{ marginLeft: 1 }}>Severity</Typography>
        </Box>

        <Typography sx={{ color: 'text.secondary' }}>
          Alerts will be generated at lower severities.
        </Typography>
      </Box>
    </ThemeProvider>
  );
};

export default SeverityThreshold;