import React, { useEffect, useState, useMemo, useContext } from 'react';
import { useTheme } from '@mui/system';
import { tokens } from "../../theme";
import {
  Button,
  Select,
  MenuItem,
  Typography,
  Box,
  Divider,
  CircularProgress,
  InputBase,
  Modal,
  Snackbar,
  Alert,
  Chip,
  IconButton,
  Menu,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import DLPProfileForm from './DLPProfileForm';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DLPProfileDrawer from './DLPProfileDrawer';
import AuthContext from "../../components/auth/AuthContext.js";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Header from '../../components/Header';
import api from '../../api/api';
import ChangesContext from '../TlsInspection/ChangesContext.js';
import { format } from "date-fns";
import { FileText, Folder, Ellipsis, Trash2, Settings } from 'lucide-react';
import axios from 'axios';


function DLP() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // State to handle filter selections
  const [dlpProfiles, setDlpProfiles] = useState([]);
  const [profileType, setProfileType] = useState('All Profile Types'); // Keep profile type filter
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedDlpProfile, setSelectedDlpProfile] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false); // Track whether we're showing a confirmation dialog
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // success, error, info, warning
  const [menuAnchorEl, setMenuAnchorEl] = useState(null); // Separate menu control from the selected policy
  const { hasChanges, setHasChanges } = useContext(ChangesContext);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null)
  const { user } = useContext(AuthContext);



  // Fetch DLP profiles
  const fetchProfiles = async () => {
    try {
      const response = await api.get('/dlp/profiles?action=read', { withCredentials: true });
      const formattedProfiles = response.data.map(profile => ({
        id: profile.EntityId,
        name: profile.Attributes.profileName || 'No name available',
        selectedIdentifiers: profile.Attributes.selectedIdentifiers || [],
        customKeywords: profile.Attributes.customKeywords || [],
        ruleExpression: profile.Attributes.ruleExpression || '',
        scanOptions: profile.Attributes.scanOptions || {},
        severityThresholds: profile.Attributes.severityThresholds || {},
        type: profile.Attributes.isCustom ? 'Custom' : 'Predefined',
        lastEdited: profile.updatedAt,
        description: profile.Attributes.description || 'No description available',
        severity: profile.Attributes.severityLevel,
        conditions: profile.Attributes.conditions || [],
      }));

      setDlpProfiles(formattedProfiles);
    } catch (error) {
      console.error('Error fetching DLP profiles:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetching dlp profile on component mount
  useEffect(() => {
    fetchProfiles();
  }, []);

  // Function to handle snackbar close
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Fucntion to trigger the snackbar with a message and severity
  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  // Function to handle the form submission success
  const handleProfileCreated = () => {
    const action = selectedDlpProfile ? 'Profile updated successfully' : 'New profile created successfully';
    showSnackbar(action); // Display the success message
    setHasChanges(true);
    setOpen(false);
    fetchProfiles();
  };

  const handleMenuOpen = (event, dlpProfile) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedDlpProfile(dlpProfile); // Set the dlpProfile associated with the row
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedRow(null);
  };

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmation(false);
  };

  const handleOpenDeleteConfirmation = () => {
    setDeleteConfirmation(true);
    handleMenuClose();
  };

  // function to get the user ip address
  async function getUserPublicIP() {
    try {
        const response = await axios.get('https://api64.ipify.org?format=json');
        return response.data.ip; // Returns public IP
    } catch (error) {
        console.error('Failed to retrieve public IP:', error);
        return null; // Return null if fetching fails
    }
}

// function for handling published policy
const handlePublish = async () => {
    try {
        setLoading(true); // Show loading spinner

        if (!user || !user.id) {
            throw new Error('User not authenticated or userId is missing.');
        }

        const userId = user.id;
        const userIp = await getUserPublicIP();

        // console.log('User IP:', userIp);

        // Send the policies for the logged-in user only
        const response = await api.post(`/icap/send-policy/${userId}`, { userIp: userIp, withCredentials: true });

        if (response.status === 200) {
          showSnackbar('All policies published successfully');
          setHasChanges(false); // Reset changes flag
        } else {
          showSnackbar('Failed to publish policies');
        }
    } catch (error) {
        console.error('Failed to publish policies:', error.message);
        showSnackbar('Failed to publish policies');
    } finally {
        setLoading(false); // Stop loading spinner
    }
};    
 


  // Function to handle deleting a profile
  const handleDeleteProfile = async () => {
    try {
      await api.delete(`/dlp/profiles?action=delete&EntityId=${selectedDlpProfile.id}`, { withCredentials: true });

      setDlpProfiles((prevDlpProfiles) =>
        prevDlpProfiles.filter((profile) => profile.id !== selectedDlpProfile.id)
      );

      showSnackbar('Profile deleted successfully');
      setDeleteConfirmation(false);
      setHasChanges(true);
    } catch (error) {
      console.error('Failed to delete profile:', error);
    }
  };

  // Function to handle searching and filtering by profile type
  const filteredRows = useMemo(() => {
    return dlpProfiles.filter((row) => {
      const matchesSearchText = row.name.toLowerCase().includes(searchText.toLowerCase());
      const matchesProfileType = profileType === 'All Profile Types' || row.type === profileType;
      return matchesSearchText && matchesProfileType;
    });
  }, [dlpProfiles, searchText, profileType]);

  const handleOpen = () => {
    setSelectedDlpProfile(null); // Reset selectedDLPProfile for a fresh add form
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  const handleRowClick = (params) => {
    if (params.field === "name") {
      setSelectedDlpProfile(params.row);
      setDrawerOpen(true);
    }
  };

  const columns = [
    // { field: 'id', headerName: '', width: 70 },
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      renderCell: (params) => (
        <Tooltip title="Click to view details" arrow>
          <Typography
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
              fontSize: '14px',
              marginTop: '15px',
              marginLeft: '10px',
            }}
            color={colors.greenAccent[400]}
          >
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      renderCell: (params) => (
        params.value === "Custom" ? (
          <Chip
            icon={<FileText size={12} className='mx-auto' />}
            label="Custom"
            variant="outlined"
            color="secondary"
            size="small"
            sx={{ padding: '2px 14px' }}
          />
        ) : (
          <Chip
            icon={<Folder size={12} />}
            label="Predefined"
            variant="outlined"
            size="small"
            sx={{ padding: '2px 6px' }}
          />
        )
      ),
    },
    {
      field: "lastEdited",
      renderHeader: (params) => (
        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
          Last Edited
        </Typography>
      ),
      flex: 1,
      renderCell: (params) => {
        // Convert ISO 8601 timestamp to a human-readable format
        const formattedDate = format(new Date(params.value), "MMMM d, yyyy");

        return (
          <Typography variant="body2" sx={{ marginTop: "15px" }}>
            {formattedDate}
          </Typography>
        );
      },
    },
    {
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className='flex justify-end'>
            <IconButton
              aria-label="open menu"
              onClick={(event) => handleMenuOpen(event, params.row)}
              size="small"
            >
              <Ellipsis fontSize="small" />
            </IconButton>
            <Menu
              anchorEl={menuAnchorEl}
              open={Boolean(menuAnchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem
                onClick={() => { handleEditProfile(); handleMenuClose(); }}
                disabled={selectedDlpProfile?.type !== 'Custom'}
              >
                <span className="text-green-600"><EditIcon /> Edit</span>              </MenuItem>
              <MenuItem
                onClick={handleOpenDeleteConfirmation}
                disabled={selectedDlpProfile?.type !== 'Custom'}
              >
                <span className="text-red-600"><DeleteIcon /> Delete</span>              </MenuItem>
            </Menu>
          </Box>
        );
      },
    }
  ];

  const handleEditProfile = () => {
    if (selectedDlpProfile) {
      setOpen(true); // Ensure modal opens
    } else {
      console.error("No profile selected for editing");
    }
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center">
        <Header title="DLP" subtitle="Create and manage DLP profiles." />
      </div>
      {/* Top Buttons */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Box display="flex" gap={2}>
          <Button
            onClick={handleOpen}
            type="submit"
            color="secondary"
            variant="contained"
          >
            <AddIcon className='mr-2 h-4 w-4' /> New Profile
          </Button>
        </Box>
      </Box>

      {/* Filters and Search */}
      <Box display="flex" justifyContent="space-between" gap={2} mb={4}>
        <Box display="flex" gap={2}>
          <Select
            value={profileType}
            onChange={(e) => setProfileType(e.target.value)}
            variant="outlined"
            sx={{ width: '200px', borderRadius: '5px', height: '40px' }}
          >
            <MenuItem value="All Profile Types">All Profile Types</MenuItem>
            <MenuItem value="Custom">Custom</MenuItem>
            <MenuItem value="Predefined">Predefined</MenuItem>
          </Select>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box display="flex" alignItems="center" className="p-1 rounded border" sx={{ borderRadius: '5px', borderColor: 'gray.300', width: '300px' }}>
            <SearchIcon />
            <InputBase
              placeholder="Search keywords"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              sx={{ ml: 1, flex: 1, height: '36px', padding: '6px' }}
            />
          </Box>
          <Box className="flex justify-end">
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              disabled={!hasChanges}
              onClick={handlePublish}
            >
              <SendIcon />
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Apply Changes'}
            </Button>
          </Box>
        </Box>
      </Box>

      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={open} // controls whether the modal is open or not
        onClose={handleClose} // closes the modal when clicking outside or pressing escape
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: '700px' }, // Responsive width
            p: 4,
            maxHeight: '90vh', // Ensures it doesn't overflow vertically
          }}
        >
          <DLPProfileForm
            onClose={handleClose}
            onProfileCreated={handleProfileCreated}
            selectedDlpProfile={selectedDlpProfile}
          />
        </Box>
      </Modal>

      {/* Snackbar Notification */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        className='text-center my-9'
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* DataGrid Section */}
      <div className="mt-1" sx={{ height: 'calc(100vh - 100px)', width: '100%' }}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
          pageSize={8}
          rowsPerPageOptions={[5, 10, 20]}
          disableRowSelectionOnClick
          disableColumnResize
          disableColumnFilter
          disableColumnMenu
          disableColumnSorting
          loading={loading}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 8,
              },
            },
          }}
          onCellClick={handleRowClick}
          style={{ height: `${window.innerHeight - 100}px` }}
          sx={{
            '&, [class^=MuiDataGrid]': {
              borderLeft: 'none',
              borderRight: 'none',
              borderBottom: 'none'
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none', // Removes the focus border when a cell is focused
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none', // Ensures there's no border when focus is within the cell
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none', // Removes the focus border from the header cells
            },
            '& .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none', // Removes the focus border within the header cells
            },
            // remove the scrollbar
            '& .MuiDataGrid-scrollContainer': {
              overflow: 'hidden',
              display: 'none',
            },
          }}
        />
      </div>

      <DLPProfileDrawer
        isOpen={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        selectedDlpProfile={selectedDlpProfile}
      />
      {/* Descriptive message upon deleting */}
      <Dialog open={deleteConfirmation} onClose={handleCloseDeleteConfirmation} maxWidth="xs" fullWidth>
        <DialogTitle>
          <strong className="text-lg font-bold">Are you sure?
            <div className="float-right cursor-pointer">
              <CloseIcon onClick={handleCloseDeleteConfirmation} />
            </div>
          </strong>
        </DialogTitle>

        <DialogContent className="p-4">
          {/* Warning message */}
          <div className="bg-red-100 text-red-600 p-2 rounded-md mb-4">
            <h1>
              Unexpected bad things will happen if you don't read this!
            </h1>
          </div>

          {/* Descriptive message */}
          <Typography variant="body2" className="mb-4">
            This action <span className="font-semibold">CANNOT</span> be undone.
            This will permanently delete the <span className="font-semibold underline">{selectedDlpProfile?.profileName}</span> profile and remove all associated data.
          </Typography>
        </DialogContent>

        <Box className="p-4">
          <Button
            onClick={handleDeleteProfile}
            color="error"
            variant="contained"
            className="w-full h-8 bg-red-600 text-white text-lg font-semibold"
          >
            I understand, delete this profile
          </Button>
        </Box>
      </Dialog>
    </div>
  );
}

export default DLP;