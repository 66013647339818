/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Box, TextField, MenuItem, Button, Typography, CircularProgress } from '@mui/material';
import { useForm, Controller, FormProvider } from "react-hook-form";
import api from '../../api/api';
import { useTheme } from '@mui/system';
import { tokens } from "../../theme";

const PolicyForm = ({ onSubmit, selectedPolicy }) => {
    const methods = useForm({
        defaultValues: {
            policyName: "",
            action: "ALLOW",
            description: "",
            condition: {},
        },
    });

    const { reset, handleSubmit, control, watch, formState: { errors, isValid } } = methods;
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [customAction, setCustomAction] = useState('');

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            setIsFormDirty(true);
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (selectedPolicy) {
            const isCustomAction = !actionOptions.some(option => option.value === selectedPolicy.action);
            reset({
                policyName: selectedPolicy.PolicyName,
                action: isCustomAction ? 'Other' : selectedPolicy.action,
                customAction: isCustomAction ? selectedPolicy.action : "", // include this line
                description: selectedPolicy.description,
                condition: selectedPolicy.Conditions,
            });
            if (isCustomAction) {
                setCustomAction(selectedPolicy.action);
            }
            setIsFormDirty(false);
        }
    }, [selectedPolicy, reset]);

    const handleFormSubmit = async (data) => {
        setIsLoading(true);

        const trimmedPolicyName = data.policyName.trim();
        const action = data.action === 'Other' ? data.customAction.trim() : data.action;

        try {
            if (!selectedPolicy || trimmedPolicyName !== selectedPolicy.PolicyName) {
                const isUnique = await checkPolicyNameUnique(trimmedPolicyName);
                if (!isUnique) {
                    methods.setError("policyName", {
                        type: "manual",
                        message: "A policy with this name already exists, please choose a different name.",
                    });
                    setIsLoading(false);
                    return;
                }
            }

            const transformedData = {
                Attributes: {
                    policyName: trimmedPolicyName,
                    action: action,
                    description: data.description,
                    ...(selectedPolicy ? {} : { conditions: {} }),
                },
            };
            console.log("Transformed data:", transformedData);

            const endpoint = selectedPolicy
                ? `/api/policy?action=update&EntityId=${selectedPolicy.id}`
                : "/api/policy?action=create";

            const response = await api.post(endpoint, transformedData, { withCredentials: true });

            if (response.status === 201 || response.status === 200) {
                console.log("Policy created/updated:", response.data);
                setIsFormDirty(false);
                onSubmit();
            } else {
                console.error("Error creating/updating policy:", response.data);
            }
        } catch (error) {
            console.error("Error during form submission:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const actionOptions = [
        { value: "ALLOW", label: "ALLOW" },
        { value: "BLOCK", label: "BLOCK" },
        { value: "ISOLATE", label: "ISOLATE" },
        { value: "WHITELIST", label: "WHITELIST" },
        { value: "ALERT", label: "ALERT" },
        { value: "USER ALERT", label: "USER ALERT" },
        { value: "Other", label: "Other" },
    ];

    const checkPolicyNameUnique = async (policyName) => {
        try {
            const response = await api.get(`/api/policy?action=check-name&name=${encodeURIComponent(policyName)}`);
            return response.data.isUnique;
        } catch (error) {
            console.error("Error checking policy name uniqueness:", error);
            return false;
        }
    };

    return (
        <FormProvider {...methods}>
            <Box component="form" onSubmit={handleSubmit(handleFormSubmit)} sx={{ backgroundColor: colors.primary[400], padding: 3, borderRadius: 2 }}>
                <Typography variant="h5" component="h1" gutterBottom sx={{ color: colors.grey[100] }}>
                    {selectedPolicy ? "Edit Policy" : "Add Your New Policy Here"}
                </Typography>

                <Controller
                    name="policyName"
                    control={control}
                    rules={{ required: "Policy name is required" }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            label="Policy Name"
                            variant="outlined"
                            margin="normal"
                            placeholder="e.g., Block all traffic"
                            error={!!errors.policyName}
                            helperText={errors.policyName?.message}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: colors.grey[100],
                                    },
                                    '&:hover fieldset': {
                                        borderColor: colors.blueAccent[500],
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: colors.blueAccent[500],
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    color: colors.grey[100],
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: colors.blueAccent[500],
                                },
                                '& .MuiFormHelperText-root': {
                                    color: colors.redAccent[500],
                                },
                            }}
                        />
                    )}
                />

                <Controller
                    name="action"
                    control={control}
                    rules={{ required: "Action is required" }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            select
                            label="Action"
                            variant="outlined"
                            margin="normal"
                            error={!!errors.action}
                            helperText={errors.action?.message}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: colors.grey[100],
                                    },
                                    '&:hover fieldset': {
                                        borderColor: colors.blueAccent[500],
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: colors.blueAccent[500],
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    color: colors.grey[100],
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: colors.blueAccent[500],
                                },
                                '& .MuiFormHelperText-root': {
                                    color: colors.redAccent[500],
                                },
                            }}
                        >
                            {actionOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                />

                {watch('action') === 'Other' && (
                    <Controller
                        name="customAction"
                        control={control}
                        rules={{ required: "Custom action is required" }}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label="Custom Action"
                                variant="outlined"
                                margin="normal"
                                error={!!errors.customAction}
                                helperText={errors.customAction?.message}
                                placeholder="Enter custom action"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: colors.grey[100],
                                        },
                                        '&:hover fieldset': {
                                            borderColor: colors.blueAccent[500],
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: colors.blueAccent[500],
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: colors.grey[100],
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: colors.blueAccent[500],
                                    },
                                }}
                            />
                        )}
                    />
                )}


                <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            label="Description"
                            multiline
                            rows={3}
                            variant="outlined"
                            margin="normal"
                            placeholder="Enter a description"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: colors.grey[100],
                                    },
                                    '&:hover fieldset': {
                                        borderColor: colors.blueAccent[500],
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: colors.blueAccent[500],
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    color: colors.grey[100],
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: colors.blueAccent[500],
                                },
                            }}
                        />
                    )}
                />

                <Box display="flex" justifyContent="center" marginTop={4}>
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        disabled={isLoading || !isValid || !isFormDirty}
                        sx={{
                            width: '150px',
                            position: 'relative',
                            backgroundColor: colors.blueAccent[500],
                            '&:hover': {
                                backgroundColor: colors.blueAccent[700],
                            },
                            '&.Mui-disabled': {
                                backgroundColor: colors.grey[500],
                            },
                        }}
                    >
                        {isLoading ? (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: colors.grey[100],
                                    position: 'absolute',
                                }}
                            />
                        ) : "Save"}
                    </Button>
                </Box>
            </Box>
        </FormProvider>
    );
};

export default PolicyForm;