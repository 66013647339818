import React, { useState } from 'react';
import { Divider } from '@mui/material';
import Header from '../../components/Header';
import NetworkTab from './NetworkTab';
import HttpTab from './HttpTab';
import TabSwitcher from '../../components/TabSwitcher';
import PolicyConfig from './PolicyConfig';
import DrawerConfig from './DrawerConfig';

function Policy() {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [isConfigOpen, setIsConfigOpen] = useState(false);

  const openConfig = (policy) => {
    setSelectedPolicy(policy);
    setIsConfigOpen(true);
  };

  const closeConfig = () => {
    setSelectedPolicy(null);
    setIsConfigOpen(false);
  };

  return (
    <div className="mx-8">
      <div className="mt-8">
        <div className="flex justify-between items-center">
          <Header title="Policy" subtitle="Build a robust protection framework with customized policies. Create and manage policies for HTTP and Network traffic." />
        </div>
        <TabSwitcher
          tabs={["HTTP", "Network"]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <Divider />
        <div
          className="bg-primary-400 h-96 w-full"
          style={{ height: 400, width: '100%' }}
        >
          {!isConfigOpen && activeTab === 0 && <HttpTab />}
          {!isConfigOpen && activeTab === 1 && <NetworkTab />}
          {isConfigOpen && <PolicyConfig policy={selectedPolicy} closeConfig={closeConfig} />}
          <DrawerConfig open={isConfigOpen} onClose={closeConfig} selectedPolicy={selectedPolicy} openConfig={openConfig} />
        </div>
      </div>
    </div>
  );
}
// add configOpen
export default Policy;
