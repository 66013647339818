"use client"

/* eslint-disable */
import { useState, useContext } from "react"
import {
  Box,
  IconButton,
  useTheme,
  Menu,
  Avatar,
  CircularProgress,
  Typography,
  Divider,
  Button,
  Tooltip,
  Paper,
  Badge,
  ListItemIcon,
  MenuItem,
  ListItemText,
  alpha,
} from "@mui/material"
import { tokens, ColorModeContext } from "../../theme"
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined"
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined"
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined"
import LogoutIcon from "@mui/icons-material/Logout"
import PersonOutlineIcon from "@mui/icons-material/PersonOutline"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import { useNavigate } from "react-router-dom"
import AuthContext from "../auth/AuthContext"

const Topbar = ({ isCollapsed }) => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const colorMode = useContext(ColorModeContext)
  const { logout, user, loading } = useContext(AuthContext)
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    await logout()
    navigate("/login")
  }

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="64px"
        sx={{ backgroundColor: colors.primary[400] }}
      >
        <CircularProgress size={24} sx={{ color: colors.blueAccent[400] }} />
      </Box>
    )
  }

  const isDark = theme.palette.mode === "dark"

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: colors.primary[400],
          borderRadius: 0,
          transition: "background-color 0.3s ease",
          borderBottom: `1px solid ${colors.grey[isDark ? 800 : 300]}`,
        }}
      >
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          p={2}
          sx={{
            height: "64px",
          }}
        >
          {/* ICONS */}
          <Box display="flex" alignItems="center" gap={1}>
            <Tooltip title="Help">
              <IconButton
                sx={{
                  backgroundColor: alpha(colors.grey[700], 0.2),
                  borderRadius: "8px",
                  width: 38,
                  height: 38,
                  transition: "all 0.2s ease",
                  "&:hover": {
                    backgroundColor: alpha(colors.grey[700], 0.3),
                    transform: "translateY(-2px)",
                  },
                }}
              >
                <HelpOutlineIcon sx={{ color: colors.grey[100] }} />
              </IconButton>
            </Tooltip>

            <Tooltip title={isDark ? "Light Mode" : "Dark Mode"}>
              <IconButton
                onClick={colorMode.toggleColorMode}
                sx={{
                  backgroundColor: alpha(colors.grey[700], 0.2),
                  borderRadius: "8px",
                  width: 38,
                  height: 38,
                  transition: "all 0.2s ease",
                  "&:hover": {
                    backgroundColor: alpha(colors.grey[700], 0.3),
                    transform: "translateY(-2px)",
                  },
                }}
              >
                {isDark ? (
                  <LightModeOutlinedIcon
                    sx={{
                      color: colors.redAccent[400],
                      transition: "color 0.3s ease",
                    }}
                  />
                ) : (
                  <DarkModeOutlinedIcon
                    sx={{
                      color: colors.blueAccent[400],
                      transition: "color 0.3s ease",
                    }}
                  />
                )}
              </IconButton>
            </Tooltip>

            <Tooltip title="Notifications">
              <IconButton
                sx={{
                  backgroundColor: alpha(colors.grey[700], 0.2),
                  borderRadius: "8px",
                  width: 38,
                  height: 38,
                  transition: "all 0.2s ease",
                  "&:hover": {
                    backgroundColor: alpha(colors.grey[700], 0.3),
                    transform: "translateY(-2px)",
                  },
                }}
              >
                <Badge
                  badgeContent={3}
                  color="error"
                  sx={{
                    "& .MuiBadge-badge": {
                      backgroundColor: colors.redAccent[500],
                    },
                  }}
                >
                  <NotificationsOutlinedIcon
                    sx={{
                      color: colors.grey[100],
                      transition: "color 0.3s ease",
                    }}
                  />
                </Badge>
              </IconButton>
            </Tooltip>

            <Box sx={{ ml: 1 }}>
              <Tooltip title={user?.username || "Guest"}>
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{
                    p: 0,
                    transition: "transform 0.2s ease",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      width: 38,
                      height: 38,
                      backgroundColor: user?.email
                        ? colors.blueAccent[isDark ? 600 : 700]
                        : colors.grey[isDark ? 700 : 400],
                      color: user?.email ? colors.grey[100] : colors.blueAccent[500],
                      fontWeight: "bold",
                      border: `2px solid ${alpha(colors.grey[100], 0.2)}`,
                      transition: "all 0.2s ease",
                    }}
                  >
                    {user?.email?.substring(0, 2).toUpperCase() || "U"}
                  </Avatar>
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Paper>

      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 6,
          sx: {
            mt: 1.5,
            minWidth: "280px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.15))",
            borderRadius: "12px",
            transition: "transform 0.2s ease, opacity 0.2s ease",
            opacity: open ? 1 : 0,
            transform: open ? "translateY(0)" : "translateY(-10px)",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: colors.primary[300],
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
            // backgroundColor: colors.primary[isDark ? 400 : 300],
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box p={2.5}>
          <Box display="flex" alignItems="center" gap={2} mb={2.5}>
            <Avatar
              sx={{
                width: 48,
                height: 48,
                backgroundColor: user?.email ? colors.blueAccent[isDark ? 600 : 700] : colors.grey[isDark ? 700 : 400],
                color: user?.email ? colors.grey[100] : colors.blueAccent[500],
                fontWeight: "bold",
                border: `2px solid ${alpha(colors.grey[100], 0.2)}`,
              }}
            >
              {user?.email?.substring(0, 2).toUpperCase() || "U"}
            </Avatar>
            <Box>
              <Typography variant="h5" fontWeight="bold" color={colors.grey[100]}>
                {user?.username || "Guest"}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: colors.grey[isDark ? 400 : 500],
                  fontSize: "0.875rem",
                }}
              >
                {user?.email || "No email"}
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ borderColor: colors.grey[isDark ? 800 : 300], my: 1.5 }} />

          <MenuItem
            onClick={() => {
              handleClose()
              navigate("/profile")
            }}
            sx={{
              borderRadius: "8px",
              mb: 1,
              "&:hover": {
                backgroundColor: alpha(colors.grey[700], 0.2),
              },
            }}
          >
            <ListItemIcon>
              <PersonOutlineIcon sx={{ color: colors.grey[100] }} />
            </ListItemIcon>
            <ListItemText primary="My Profile" sx={{ color: colors.grey[100] }} />
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleClose()
              navigate("/settings")
            }}
            sx={{
              borderRadius: "8px",
              mb: 1,
              "&:hover": {
                backgroundColor: alpha(colors.grey[700], 0.2),
              },
            }}
          >
            <ListItemIcon>
              <SettingsOutlinedIcon sx={{ color: colors.grey[100] }} />
            </ListItemIcon>
            <ListItemText primary="Settings" sx={{ color: colors.grey[100] }} />
          </MenuItem>

          <Button
            variant="contained"
            fullWidth
            onClick={handleLogout}
            startIcon={<LogoutIcon />}
            sx={{
              mt: 2,
              py: 1,
              backgroundColor: colors.redAccent[isDark ? 600 : 500],
              color: colors.grey[100],
              borderRadius: "8px",
              textTransform: "none",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: colors.redAccent[isDark ? 700 : 600],
              },
            }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Sign out"}
          </Button>
        </Box>
      </Menu>
    </>
  )
}

export default Topbar

