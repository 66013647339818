import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, TextField, Typography, ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3', // Light blue color similar to the image
    },
  },
});

const Overview = () => {
  // Placeholder for Overview component
  return null;
};

const SetRule = () => {
  const { register } = useFormContext();

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ 
        maxWidth: '800px', 
        margin: 'auto', 
        padding: 3, 
        height: '50vh', 
        display: 'flex', 
        flexDirection: 'column' 
      }}>
              <h2 className="font-semibold">RULE NAME</h2>
        <TextField
          {...register("SetRule")}
          placeholder="Set Rule Name"
          variant="outlined"
          fullWidth
          sx={{ marginBottom: 2 }}
        />
        <Box sx={{ flexGrow: 1 }} /> {/* This creates the white space */}
        <Box sx={{ marginLeft: 'auto' }}>
          <Overview />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default SetRule;