import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Divider,
  Collapse,
  Paper,
  IconButton,
  Tooltip,
  Chip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/system';
import { tokens } from "../../../theme";
import { useFormContext } from 'react-hook-form';

export default function AdvancedOptionsStep() {
  const [isGlobalExpanded, setGlobalExpanded] = useState(false);
  const [nearValue, setNearValue] = useState(100);
  const { setValue, watch, formState: { errors } } = useFormContext();
  const formExpression = watch("ruleExpression");
  const [expression, setExpression] = useState(formExpression || '');
  const selectedIdentifiers = watch("selectedIdentifiers") || {};
  const selectedCustomIdentifiers = watch("selectedCustomIdentifiers") || [];
  // const initialLoad = useRef(true);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    // Sync form value with local state
    setExpression(formExpression);
  }, [formExpression]);

  const handleAddToExpression = (value) => {
    const stringValue = typeof value === "string" ? value : value.identifierName || JSON.stringify(value);
    const newExpression = expression ? `${expression} ${stringValue}` : stringValue;
    setExpression(newExpression);
    setValue("ruleExpression", newExpression);
  };

  const handleOperatorClick = (operator) => {
    // Insert operator at the end, ensuring space separation
    const newExpression = expression ? `${expression} ${operator}` : `${operator}`;
    setExpression(newExpression);
    setValue('ruleExpression', newExpression);
  };

  const toggleGlobalIdentifiers = () => {
    setGlobalExpanded((prev) => !prev);
  };

  return (
    <Box className="flex flex-col gap-4 p-4 min-h-screen">
      <Box className="flex items-center mb-2">
        <Typography variant="subtitle1" className="font-bold">
          RULE EXPRESSION
        </Typography>
        <Tooltip title="This expression is initialized with your selected identifiers. You can modify it or add more operators.">
          <IconButton size="small" className="ml-2">
            <InfoIcon fontSize="small" className="text-blue-500" />
          </IconButton>
        </Tooltip>
      </Box>
      <TextField
        fullWidth
        multiline
        minRows={3}
        variant="outlined"
        value={expression}
        error={!!errors.ruleExpression} // Add error state
        helperText={errors.ruleExpression?.message} // Show validation message
        placeholder="Define your rule expression"
        onChange={(e) => {
          setExpression(e.target.value);
          setValue('ruleExpression', e.target.value);
        }}
        className="mb-6"
        
      />

      <Box className="flex flex-col md:flex-row gap-6" >
        <Paper elevation={3} className="flex-1 p-4 rounded-lg shadow-md" sx={{ backgroundColor: colors.primary[400] }}>
          <Typography variant="subtitle1" className="font-bold mb-4">
            DATA IDENTIFIER
          </Typography>
          <div className="space-y-4">
            <Typography variant="subtitle2" className="font-bold">
              Predefined Identifiers
            </Typography>
            {Object.entries(selectedIdentifiers).map(([categoryId, identifiers]) => (
              <div key={categoryId} className="rounded-lg p-3">
                <Typography className="font-semiboldmb-2">
                  {getCategoryName(parseInt(categoryId))}
                </Typography>
                <div className="flex flex-wrap gap-2">
                  {Array.isArray(identifiers) && identifiers.map((identifier) => (
                    <Chip
                      key={identifier}
                      label={typeof identifier === 'string' ? identifier : JSON.stringify(identifier)}
                      onClick={() => handleAddToExpression(identifier)}
                      className="cursor-pointer transition-colors duration-200"
                      deleteIcon={<AddIcon />}
                      onDelete={() => handleAddToExpression(identifier)}
                    />
                  ))}
                </div>
              </div>
            ))}
            <Divider />
            <Typography variant="subtitle2" className="font-bold">
              Custom Identifiers
            </Typography>
            {selectedCustomIdentifiers.filter(identifier => !categories.some(cat => cat.dataIdentifiers.includes(identifier.identifierName))).map((identifier) => (
              <Chip
                key={identifier.identifierName || identifier}
                label={identifier.identifierName || JSON.stringify(identifier)}
                onClick={() => handleAddToExpression(identifier)}
                className="cursor-pointer transition-colors duration-200"
                deleteIcon={<AddIcon />}
                onDelete={() => handleAddToExpression(identifier)}
              />
            ))}
          </div>
        </Paper>

        <Paper elevation={3} className="flex-1 p-4 rounded-lg shadow-md" sx={{ backgroundColor: colors.primary[400] }}>
          <Typography variant="subtitle1" className="font-bold mb-4">
            OPERATOR
          </Typography>
          <div className="grid grid-cols-2 gap-2">
            {['AND', 'OR', 'NOT', '(', ')'].map((operator) => (
              <Button
                key={operator}
                variant="outlined"
                onClick={() => handleOperatorClick(operator)}
                className="w-full py-2 transition-colors duration-200"
                sx={{ color: colors.grey[100] }}
              >
                {operator}
              </Button>
            ))}
            <Button
              variant="outlined"
              onClick={() => handleOperatorClick(`NEAR ${nearValue}`)}
              className="col-span-2 w-full py-2 border-blue-400 text-blue-600 hover:bg-blue-50 transition-colors duration-200 flex justify-between items-center"
            >
              <h1>NEAR</h1> &nbsp;&nbsp;
              <TextField
                size="small"
                variant="outlined"
                value={nearValue}
                onChange={(e) => setNearValue(parseInt(e.target.value) || 0)}
                className="w-20 ml-2"
                inputProps={{ type: 'number', min: 0, className: 'text-center' }}
              />
            </Button>
          </div>
        </Paper>
      </Box>

      <Box className="mt-6">
        <Divider />
        <Button
          endIcon={<ExpandMoreIcon />}
          onClick={toggleGlobalIdentifiers}
          className="w-full py-3 justify-between text-left normal-case"
        >
          <Typography variant="subtitle1" className="font-bold text-gray-700">
            Global Identifiers
          </Typography>
        </Button>
        <Collapse in={isGlobalExpanded}>
          <Box className="mt-4 p-4 bg-gray-50 rounded-lg">
            <Typography variant="body2" className="text-gray-600">
              No global identifiers available.
            </Typography>
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
}

const categories = [
  { id: 1, name: 'Personal Names', dataIdentifiers: ['Name', 'First Name', 'Last Name'] },
  { id: 2, name: 'Personal Numbers', dataIdentifiers: ['SSN', 'Passport Number'] },
  { id: 3, name: 'Financial Data', dataIdentifiers: ['Credit Card Number', 'Bank Account Number'] },
  { id: 4, name: 'Medical Data', dataIdentifiers: ['Medical Record Number', 'Health Insurance Number'] },
  { id: 5, name: 'Postal Addresses', dataIdentifiers: ['Address', 'Postal Code'] },
  { id: 6, name: 'Dates', dataIdentifiers: ['Date of Birth', 'Anniversary Date'] },
  { id: 7, name: 'Biodata', dataIdentifiers: ['Fingerprint', 'DNA Sequence'] },
  { id: 8, name: 'Computing', dataIdentifiers: ['IP Address', 'MAC Address'] },
];

function getCategoryName(categoryId) {
  return categories.find(cat => cat.id === categoryId)?.name || 'Unknown Category';
}