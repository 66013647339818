import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  Tabs,
  Tab,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';

const StyledTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
});

const StyledTab = styled(Tab)({
  textTransform: 'none',
  minWidth: 72,
  fontWeight: 'bold',
  marginRight: '24px',
  '&.Mui-selected': {
    color: '#1890ff',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
});

const sensitivityOptions = ['Case Sensitive', 'High Sensitive', 'Medium Sensitive', 'Low Sensitive'];

export default function CustomStep() {
  const { setValue, getValues } = useFormContext();
  const [activeTab, setActiveTab] = useState(0);
  const [sensitivity, setSensitivity] = useState('Case Sensitive');
  const [identifier, setIdentifier] = useState('');

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSensitivityChange = (event) => {
    setSensitivity(event.target.value);
  };

  const handleIdentifierChange = (event) => {
    setIdentifier(event.target.value);
  };

  const handleValidate = () => {
    console.log('Validating:', { sensitivity, identifier });
  };

  
  const handleAddIdentifier = () => {
    const customKeywords = getValues('customKeywords') || [];
    setValue('customKeywords', [...customKeywords, { identifier, sensitivity }]);
    setIdentifier('');
  };

  return (
    <Box className="flex flex-col gap-4 p-4 min-h-dvh">
      <StyledTabs value={activeTab} onChange={handleTabChange} aria-label="data identifier tabs">
        <StyledTab label="New Identifiers" />
        <StyledTab label="Saved Identifiers" />
      </StyledTabs>
      <Box sx={{ p: 3 }}>
        {activeTab === 0 && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                <InputLabel id="sensitivity-label">Sensitivity</InputLabel>
                <Select
                  labelId="sensitivity-label"
                  value={sensitivity}
                  onChange={(e) => setSensitivity(e.target.value)}
                  label="Sensitivity"
                >
                  {sensitivityOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                onClick={handleValidate}
                sx={{
                  bgcolor: '#f5f5f5',
                  color: '#000',
                  marginLeft: 'auto',
                  '&:hover': {
                    bgcolor: '#e0e0e0',
                  },
                }}
              >
                VALIDATE REGEX
              </Button>
            </Box>

            {/* Second Row with TextField and Add Icon */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Add a predefined data identifier, keyword, or regex"
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
                sx={{ flex: 1 }}
              />
              <IconButton
                color="primary"
                onClick={handleAddIdentifier}
                sx={{
                  bgcolor: '#1890ff',
                  color: '#fff',
                  '&:hover': {
                    bgcolor: '#1572c4',
                  },
                }}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </>
        )}
        {activeTab === 1 && (
          <Box>
            {/* Content for Saved Identifiers tab */}
            <p>No saved identifiers available.</p>
          </Box>
        )}
      </Box>
    </Box>
  );
}
