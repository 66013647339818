import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import api from '../../api/api';
import { tokens } from "../../theme";

const AddDomainForm = ({ onAddDomain, onEditDomain, initialValues }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingCategories, setIsFetchingCategories] = useState(true); // Track category fetching state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [categories, setCategories] = useState([]);
  const [customCategory, setCustomCategory] = useState('');
  const [isAddingCustomCategory, setIsAddingCustomCategory] = useState(false);

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    setError,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      domainName: '',
      category: '',
    },
  });

  useEffect(() => {
    if (initialValues) {
      setValue('domainName', initialValues.name);
      setValue('category', initialValues.category);
    }
  }, [initialValues, setValue]);

  // Fetch categories from the backend
  useEffect(() => {
    const fetchCategories = async () => {
      setIsFetchingCategories(true);
      try {
        const response = await api.get('/category/domain?action=read', { withCredentials: true });
        console.log('Response:', response.data); // Debugging: Log the response
        const fetchedCategories = response.data.categories?.[0]?.categories || []; // Access the nested categories
        setCategories(fetchedCategories.map((cat) => cat.name)); // Map to an array of names
      } catch (error) {
        console.error('Error fetching categories:', error);
        setSnackbar({ open: true, message: 'Failed to fetch categories.', severity: 'error' });
      } finally {
        setIsFetchingCategories(false);
      }
    };

    fetchCategories();
  }, []);

  const handleAddCustomCategory = () => {
    if (customCategory.trim() && customCategory.length <= 20) {
      setCategories((prev) => [...prev, customCategory]);
      setValue('category', customCategory); // Set the newly added category as the selected value
      setCustomCategory('');
      setIsAddingCustomCategory(false);
    } else {
      setSnackbar({
        open: true,
        message: 'Category must be a non-empty string and less than 20 characters.',
        severity: 'warning',
      });
    }
  };

  const handleCancelCustomCategory = () => {
    setIsAddingCustomCategory(false);
    setValue('category', ''); // Reset the selected value
  };

  const checkDomainNameUnique = async (domainName) => {
    try {
      const response = await api.get(`/domain/profiles?action=check-name&name=${encodeURIComponent(domainName)}`);
      return response.data.isUnique;
    } catch (error) {
      console.error("Error checking domain name uniqueness:", error);
      return false; // Treat errors as the name not being unique
    }
  };

  const onSubmit = async (data) => {
    setIsLoading(true);

    const trimmedDomainName = data.domainName.trim();

    try {
      // Check if the domain name has been modified
      if (!initialValues || trimmedDomainName !== initialValues.name) {
        // Check if the domain name is unique
        const isUnique = await checkDomainNameUnique(trimmedDomainName);
        if (!isUnique) {
          setError("domainName", {
            type: "manual",
            message: "A domain with this name already exists, please choose a different name.",
          });
          setIsLoading(false);
          return;
        }
      }

      const transformedData = {
        Attributes: {
          domainName: trimmedDomainName,
          category: data.category,
        },
      };

      if (initialValues) {
        // Update existing domain profile
        await onEditDomain({
          id: initialValues.id,
          name: data.domainName,
          category: data.category,
        });
      } else {
        // Create new domain profile
        const response = await api.post('/domain/profiles?action=create', transformedData, { withCredentials: true });

        if (response.status === 201) {
          onAddDomain({
            id: response.data.EntityId,
            name: response.data.Attributes.domainName,
            category: response.data.Attributes.category,
            lastEdited: new Date().toISOString(),
          });
          reset(); // Clear the form
          setSnackbar({ open: true, message: 'Domain created successfully!', severity: 'success' });
        }
      }
    } catch (error) {
      console.error('Error creating/updating domain:', error);
      setSnackbar({ open: true, message: 'Failed to create/update domain.', severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      className="max-w-md mx-auto mt-8 p-6 rounded-lg"
      // sx={{
      //   backgroundColor: colors.primary[400],
      //   color: colors.grey[100],
      // }}
    >
      <Typography variant="h5" component="h2" className="text-center" sx={{ color: colors.grey[100] }}>
        Add Domain
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <Controller
          name="domainName"
          control={control}
          rules={{
            required: 'Domain name is required',
            pattern: {
              value: /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/,
              message: 'Invalid domain name format',
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Domain Name"
              variant="outlined"
              fullWidth
              error={!!errors.domainName}
              helperText={errors.domainName?.message}
              inputProps={{ 'aria-label': 'Domain Name' }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: colors.grey[100],
                  },
                  '&:hover fieldset': {
                    borderColor: colors.blueAccent[500],
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.blueAccent[500],
                  },
                },
                '& .MuiInputLabel-root': {
                  color: colors.grey[100],
                },
                '& .MuiFormHelperText-root': {
                  color: colors.redAccent[500],
                },
              }}
            />
          )}
        />
        {!isAddingCustomCategory ? (
          <FormControl fullWidth variant="outlined">
            <InputLabel sx={{ color: colors.grey[100] }}>Category</InputLabel>
            <Controller
              name="category"
              control={control}
              rules={{ required: 'Category is required' }}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Category"
                  fullWidth
                  error={!!errors.category}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200, // Make the dropdown scrollable
                      },
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: colors.grey[100],
                      },
                      '&:hover fieldset': {
                        borderColor: colors.blueAccent[500],
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: colors.blueAccent[500],
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: colors.grey[100],
                    },
                    '& .MuiFormHelperText-root': {
                      color: colors.redAccent[500],
                    },
                  }}
                >
                  {isFetchingCategories ? (
                    <MenuItem disabled>Loading...</MenuItem>
                  ) : categories.length > 0 ? (
                    categories.map((cat) => (
                      <MenuItem key={cat} value={cat}>
                        {cat}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No categories available</MenuItem>
                  )}
                </Select>
              )}
            />
          </FormControl>
        ) : (
          <Box className="flex items-center space-x-2">
            <TextField
              label="New Category"
              variant="outlined"
              fullWidth
              value={customCategory}
              onChange={(e) => setCustomCategory(e.target.value)}
              error={!customCategory.trim() || customCategory.length > 20}
              helperText={!customCategory.trim() ? 'Category is required' : customCategory.length > 20 ? 'Category must be less than 20 characters' : ''}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: colors.grey[100],
                  },
                  '&:hover fieldset': {
                    borderColor: colors.blueAccent[500],
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.blueAccent[500],
                  },
                },
                '& .MuiInputLabel-root': {
                  color: colors.grey[100],
                },
                '& .MuiFormHelperText-root': {
                  color: colors.redAccent[500],
                },
              }}
            />
            <Button
              onClick={handleAddCustomCategory}
              variant="contained"
              sx={{
                backgroundColor: colors.blueAccent[500],
                '&:hover': {
                  backgroundColor: colors.blueAccent[700],
                },
                color: colors.grey[100],
              }}
              disabled={!customCategory.trim() || customCategory.length > 20}
            >
              Add
            </Button>
            <Button
              onClick={handleCancelCustomCategory}
              variant="outlined"
              sx={{
                borderColor: colors.redAccent[500],
                color: colors.redAccent[500],
                '&:hover': {
                  borderColor: colors.redAccent[700],
                  color: colors.redAccent[700],
                },
              }}
            >
              Cancel
            </Button>
          </Box>
        )}
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: colors.blueAccent[500],
            '&:hover': {
              backgroundColor: colors.blueAccent[700],
            },
            color: colors.grey[100],
            fontWeight: 'bold',
            py: 2,
            px: 4,
            borderRadius: 1,
          }}
          fullWidth
          disabled={!isValid || isLoading || (isAddingCustomCategory && (!customCategory.trim() || customCategory.length > 20))}
        >
          {isLoading ? <CircularProgress size={24} /> : initialValues ? 'Update Domain' : 'Add Domain'}
        </Button>
      </form>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddDomainForm;