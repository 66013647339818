import React from 'react'

function Settings() {
  return (
    <div>
      
    </div>
  )
}

export default Settings;
