import React, { useState, useEffect, useMemo } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Search, Plus, Edit2, Settings, Trash2, MoreVertical } from 'lucide-react'
import { useTheme } from '@mui/material/styles'
import Header from '../../components/Header';
import FileProfileDrawer from './FileProfileDrawer';
import FileProfileForm from './FileProfileForm';
import { tokens } from "../../theme";
import {
  Button,
  MenuItem,
  Typography,
  Box,
  Divider,
  InputBase,
  IconButton,
  Menu,
  Tooltip,
  Snackbar,
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Modal
} from '@mui/material'
import { Ellipsis } from "lucide-react";
import api from '../../api/api';
import CloseIcon from '@mui/icons-material/Close';
import { format } from "date-fns";

// Make sure to have an API instance configured

export default function FileProtection() {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const [fileProfiles, setFileProfiles] = useState([])
  const [searchText, setSearchText] = useState("")
  const [selectedRowId, setSelectedRowId] = useState(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedFileProfile, setSelectedFileProfile] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // success, error, info, warning
  const [deleteConfirmation, setDeleteConfirmation] = useState(false); // Track whether we're showing a confirmation dialog
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null)
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false);

  // Fetching file profiles on component mount
  useEffect(() => {
    fetchFileProfiles();
  }, []);

  // Fetching file profiles from backend

  const fetchFileProfiles = async () => {
    try {
      setLoading(true);
      const response = await api.get('/file/profiles?action=read', { withCredentials: true }); // Adjust the endpoint as needed
      console.log('File profiles:', response.data);
      const formattedProfiles = response.data.map(profile => ({
        id: profile.EntityId,
        profile_name: profile.Attributes.profile_name,
        file_names: profile.Attributes.file_names,
        file_types: profile.Attributes.file_types,
        file_hash: profile.Attributes.file_hash,
        object_id: profile.Attributes.object_id,
        file_size: profile.Attributes.file_size,
        protected_encrypted: profile.Attributes.protected_encrypted,
        lastEdited: profile.updatedAt,
      }));
      setFileProfiles(formattedProfiles);
    } catch (error) {
      console.error('Failed to fetch file profiles:', error.message);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle deleting a file profile
  const handleDeleteProfile = async () => {
    // if (!selectedRow) return;
    try {
      const response = await api.delete(`/file/profiles?action=delete&EntityId=${selectedRowId}`, { withCredentials: true });
      if (response.status === 200) {
        console.log('File profile deleted successfully');
        fetchFileProfiles();
      } else {
        console.error('Failed to delete file profile:', response.data);
      }
    } catch (error) {
      console.error('Error deleting file profile:', error.message);
    } finally {
      showSnackbar('Profile deleted successfully');
      setDeleteConfirmation(false);
      handleMenuClose();
    }
  }

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmation(false);
  };

  const handleOpenDeleteConfirmation = () => {
    setDeleteConfirmation(true);
    handleMenuClose();
  };

  // Function to handle snackbar close
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Fucntion to trigger the snackbar with a message and severity
  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleRowClick = (params) => {
    if (params.field === "profile_name") {
      setSelectedFileProfile(params.row);
      setDrawerOpen(true);
    }
  };

  // Filter rows based on search text
  const rows = useMemo(() => {
    return fileProfiles.filter((row) => {
      return row.profile_name?.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [fileProfiles, searchText]);

  const handleMenuOpen = (event, row) => {
    setMenuAnchorEl(event.currentTarget)
    setSelectedRowId(row.id)
    setSelectedRow(row);
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null)
    setSelectedRow(null)
  }

  const handleOpen = () => {
    setSelectedFileProfile(null); // Clear selectedFileProfile for new profile creation
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  // Callback for when a new file profile is created
  const handleFileProfileCreated = () => {
    const action = selectedFileProfile ? 'Profile updated successfully' : 'New profile created successfully';
    showSnackbar(action); // Display the success message
    setOpen(false);
    fetchFileProfiles(); // Refresh the file profiles list
    setSelectedFileProfile(null); // Reset selectedFileProfile after use
  };


  const columns = [
    // { field: 'id', headerName: '#', width: 70 },
    {
      field: 'profile_name',
      headerName: 'File Name',
      flex: 1,
      width: 70,
      renderCell: (params) => (
        <Typography
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
            marginTop: "15px",
            fontSize: "12px",
            marginLeft: '10px',
          }}
          color={colors.greenAccent[400]}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "lastEdited",
      renderHeader: (params) => (
        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
          Last Edited
        </Typography>
      ),
      flex: 1,
      renderCell: (params) => {
        // Convert ISO 8601 timestamp to a human-readable format
        const formattedDate = format(new Date(params.value), "MMMM d, yyyy, h:mm a");

        return (
          <Typography variant="body2" sx={{ marginTop: "15px" }}>
            {formattedDate}
          </Typography>
        );
      },
    },
    {
      flex: 1,
      renderCell: (params) => (
        <Box className='flex justify-end'>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(event) => handleMenuOpen(event, params.row)}
          >
            <Ellipsis fontSize="small" />
          </IconButton>
        </Box>
      ),
    }
  ];

  const handleEditProfile = () => {
    if (selectedRow) {
      setSelectedFileProfile(selectedRow); // Ensure selectedRow data is set as selectedFileProfile
      setOpen(true); // Open the modal
    }
  };


  return (
    <div className="p-6 space-y-6">
      <Header title="File Attribute" subtitle="Create and manage file attributes" />

      <Box className="flex justify-between items-center">
        <Box className="flex gap-2">
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            onClick={handleOpen}
            startIcon={<Plus size={20} />}
          >
            New Profile
          </Button>
          {/* <Button
            variant='outlined'
            color="secondary"
            startIcon={<Edit2 size={20} />}
          >
            Edit Profile
          </Button> */}
        </Box>
      </Box>

      <Box className="flex items-center p-2 rounded border border-gray-300 w-[300px]">
        <Search size={20} />
        <InputBase
          placeholder="Search keywords"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          sx={{ ml: 1, flex: 1 }}
        />
      </Box>

      {/* Snackbar Notification */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        className='text-center my-9'
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Box sx={{ height: 'calc(100vh - 300px)', width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row.id} // Ensure correct row ID mapping
          components={{
            Toolbar: GridToolbar,
          }}
          disableRowSelectionOnClick
          disableColumnResize
          disableColumnFilter
          disableColumnMenu
          disableColumnSorting
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 8,
              },
            },
          }}
          onCellClick={handleRowClick}
          pageSizeOptions={[5, 10, 20]}
          loading={loading}
          sx={{
            '&, [class^=MuiDataGrid]': {
              borderLeft: 'none',
              borderRight: 'none',
              borderBottom: 'none'
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
          }}
        />
      </Box>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Typography variant="subtitle2" sx={{ px: 2, py: 1 }}>
          Actions
        </Typography>
        <Divider />
        <MenuItem onClick={() => { handleEditProfile(); handleMenuClose(); }}>
          <Settings className="mr-2" size={16} />
          <span>Edit</span>
        </MenuItem>
        <MenuItem onClick={handleOpenDeleteConfirmation}>
          <Trash2 className="mr-2" size={16} />
          <span>Delete</span>
        </MenuItem>
      </Menu>

      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: '700px' },
            p: 4,
            maxHeight: '90vh',
          }}
        >
          <FileProfileForm
            onClose={handleClose}
            onProfileCreated={handleFileProfileCreated}
            selectedFileProfile={selectedFileProfile} // Pass selectedFileProfile here
          />
        </Box>
      </Modal>
      <FileProfileDrawer
        isOpen={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        selectedFileProfile={selectedFileProfile}
      />
      {/* Descriptive message upon deleting */}
      <Dialog open={deleteConfirmation} onClose={handleCloseDeleteConfirmation} maxWidth="xs" fullWidth>
        <DialogTitle>
          <strong className="text-lg font-bold">Are you sure?
            <div className="float-right cursor-pointer">
              <CloseIcon onClick={handleCloseDeleteConfirmation} />
            </div>
          </strong>
        </DialogTitle>

        <DialogContent className="p-4">
          {/* Warning message */}
          <div className="bg-red-100 text-red-600 p-2 rounded-md mb-4">
            <h1>
              Unexpected bad things will happen if you don't read this!
            </h1>
          </div>

          {/* Descriptive message */}
          <Typography variant="body2" className="mb-4">
            This action <span className="font-semibold">CANNOT</span> be undone.
            This will permanently delete the <span className="font-semibold underline">{selectedRow?.profile_name}</span> profile and remove all associated data.
          </Typography>
        </DialogContent>

        <Box className="p-4">
          <Button
            onClick={handleDeleteProfile}
            color="error"
            variant="contained"
            className="w-full h-8 bg-red-600 text-white text-lg font-semibold"
          >
            I understand, delete this profile
          </Button>
        </Box>
      </Dialog>
    </div>
  )
}