import React, { useState, useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { styled, useTheme } from '@mui/material/styles';
import {
    Box,
    MenuItem,
    Select,
    List,
    ListItem,
    ListItemText,
    Typography,
    TextField,
    Card,
    CardContent,
    IconButton,
    Checkbox,
    Divider,
} from '@mui/material';
import {
    InsertDriveFile,
    Fingerprint,
    Storage,
    Memory,
    Lock,
    Search,
    Visibility,
} from '@mui/icons-material';

const sidebarItems = [
    { name: "Name or Extension", icon: InsertDriveFile },
    { name: "File Type", icon: Storage },
    { name: "File Hash", icon: Fingerprint },
    { name: "Object ID", icon: Memory },
    { name: "File Size", icon: Memory },
    { name: "Protected/Encrypted", icon: Lock },
];

const fileTypes = [
    { name: "Analytics", count: 37 },
    { name: "Archive and Compressed", count: 237 },
    { name: "Audio", count: 72 },
    { name: "Auth and Token", count: 64 },
    { name: "Binary and Executable", count: 63 },
    { name: "Communication", count: 32 },
    { name: "Database", count: 72 },
    { name: "Design", count: 111 },
    { name: "Desktop Publishing", count: 56 },
    { name: "Font", count: 22 },
    { name: "GIS (Geospatial)", count: 14 },
    { name: "Image (Raster)", count: 14 },
    { name: "Image (Vector)", count: 14 },
    { name: "Presentation", count: 14 },
    { name: "Schedule", count: 14 },
    { name: "Scientific", count: 14 },
    { name: "Spreadsheet", count: 14 },
    { name: "Text", count: 14 },
    { name: "Video", count: 14 },
    { name: "Word Processor", count: 14 },
    { name: "Miscellaneous", count: 14 },
    { name: "Other", count: 14 },
];


const SidebarButton = styled(Box)(({ theme }) => ({
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 2),
    width: '100%',
    borderRadius: 0,
    cursor: 'pointer',
}));

const ScrollArea = styled(Box)(({ theme }) => ({
    height: '100vh',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
        width: '0.4em',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
    },
}));

export default function FileAttributeStep() {
    const { control, formState: { errors } } = useFormContext();
    const [selectedItem, setSelectedItem] = useState("Name or Extension");
    const [searchTerm, setSearchTerm] = useState("");

    const filteredFileTypes = useMemo(() => {
        return fileTypes.filter(type =>
            type.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [searchTerm]);

    const renderContent = useMemo(() => {
        switch (selectedItem) {
            case "Name or Extension":
                return (
                    <Box className="flex flex-col gap-4">
                        <Typography variant="h6">File Names</Typography>
                        <Controller
                            name="file_names"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'File names are required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    error={!!errors.file_names}
                                    helperText={errors.file_names?.message || ""}
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    fullWidth
                                    // add a descriptive placeholder
                                    placeholder="Enter file names or extensions separated by new lines e.g. (file1.txt, file2.docx)"
                                />
                            )}
                        />
                    </Box>
                );
                case "File Type":
                    return (
                        <Box className="flex flex-col gap-4">
                            <Typography variant="h6">File Types</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Search file types"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                InputProps={{
                                    startAdornment: <Search className="text-gray-400 mr-2" />,
                                }}
                            />
                            {/* Error message above the list */}
                            {errors.file_types && (
                                <Typography variant="caption" color="error" sx={{ mt: 1 }}>
                                    {errors.file_types.message}
                                </Typography>
                            )}
                            <Box className="bg-white border rounded-md" sx={{ maxHeight: 300, overflowY: "auto", mt: 1 }}>
                                <List>
                                    {filteredFileTypes.map((type) => (
                                        <ListItem key={type.name} className="px-0">
                                            <Controller
                                                name="file_types"
                                                control={control}
                                                defaultValue={[]}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        checked={field.value.includes(type.name)}
                                                        onChange={() => {
                                                            const newFileTypes = field.value.includes(type.name)
                                                                ? field.value.filter((item) => item !== type.name)
                                                                : [...field.value, type.name];
                                                            field.onChange(newFileTypes);
                                                        }}
                                                    />
                                                )}
                                            />
                                            <ListItemText primary={type.name} secondary={`${type.count} file types`} />
                                            <IconButton edge="end">
                                                <Visibility />
                                            </IconButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Box>
                    );
                
            case "File Hash":
                return (
                    <Box className="flex flex-col gap-4">
                        <Typography variant="h6">File Hash</Typography>
                        <Controller
                            name="file_hash"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Enter file hash"
                                />
                            )}
                        />
                    </Box>
                );
            case "Object ID":
                return (
                    <Box className="flex flex-col gap-4">
                        <Typography variant="h6">Object ID</Typography>
                        <Controller
                            name="object_id"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Enter object ID"
                                />
                            )}
                        />
                    </Box>
                );
            case "File Size":
                return (
                    <Box className="flex flex-col gap-4">
                        <Typography variant="h6">File Size</Typography>
                        <Box className="flex items-center gap-2">
  <Controller
    name="file_size_operator"
    control={control}
    defaultValue=""
    render={({ field }) => (
      <Select
        {...field}
        variant="outlined"
        fullWidth
        displayEmpty
        error={!!errors.file_size_operator}
      >
        <MenuItem value="" disabled>Select Operator</MenuItem>
        <MenuItem value="greater">Greater Than</MenuItem>
        <MenuItem value="less">Less Than</MenuItem>
        <MenuItem value="greater than or equal">Greater Than or Equal</MenuItem>
        <MenuItem value="less than or equal">Less Than or Equal</MenuItem>
        <MenuItem value="equal">Equal To</MenuItem>
      </Select>
    )}
  />
  <Controller
    name="file_size_value"
    control={control}
    defaultValue=""
    render={({ field }) => (
      <TextField
        {...field}
        type="number"
        variant="outlined"
        placeholder="Enter Size"
        fullWidth
        error={!!errors.file_size_value}
        helperText={errors.file_size_value?.message || ""}
      />
    )}
  />
  <Controller
    name="file_size_unit"
    control={control}
    defaultValue=""
    render={({ field }) => (
      <Select
        {...field}
        variant="outlined"
        fullWidth
        displayEmpty
        error={!!errors.file_size_unit}
      >
        <MenuItem value="" disabled>Select Unit</MenuItem>
        <MenuItem value="bytes">Bytes</MenuItem>
        <MenuItem value="KB">Kilobyte</MenuItem>
        <MenuItem value="MB">Megabyte</MenuItem>
        <MenuItem value="GB">Gigabyte</MenuItem>
      </Select>
    )}
  />
</Box>
{errors.file_size_operator && (
  <Typography variant="caption" color="error">
    {errors.file_size_operator.message}
  </Typography>
)}

                    </Box>
                );
            case "Protected/Encrypted":
                return (
                    <Box className="flex flex-col gap-4">
                        <Typography variant="h6">Protected/Encrypted</Typography>
                        <Controller
                            name="protected_encrypted"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Enter protection or encryption status"
                                />
                            )}
                        />
                    </Box>
                );
            default:
                return <Typography>Select an item from the sidebar</Typography>;
        }
    }, [selectedItem, control, errors, filteredFileTypes, searchTerm]);

    return (
        <Box className="flex min-h-screen bg-gray-100">
            <Box className="w-60 border-r border-gray-200">
                <ScrollArea>
                    <List component="nav">
                        {sidebarItems.map((item, index) => (
                            <React.Fragment key={item.name}>
                                <ListItem disablePadding>
                                    <SidebarButton
                                        onClick={() => setSelectedItem(item.name)}
                                        className={`${selectedItem === item.name ? 'bg-blue-100 text-blue-600' : 'text-gray-700'
                                            }`}
                                    >
                                        <ListItemText primary={item.name} />
                                    </SidebarButton>
                                </ListItem>
                                {/* Add dividers with 'AND' between specific items */}
                                {index === 3 && (
                                    <Divider className="my-2" variant="middle">
                                        <Typography variant="subtitle2" color="textSecondary">AND</Typography>
                                    </Divider>
                                )}
                                {index === 4 && (
                                    <Divider className="my-2" variant="middle">
                                        <Typography variant="subtitle2" color="textSecondary">AND</Typography>
                                    </Divider>
                                )}
                            </React.Fragment>
                        ))}
                    </List>
                </ScrollArea>
            </Box>

            <Box className="flex-grow p-6 overflow-auto">
                <Card className="max-w-3xl mx-auto">
                    <CardContent className="p-6">{renderContent}</CardContent>
                </Card>
            </Box>
        </Box>
    );
}
