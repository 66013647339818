/* eslint-disable */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Box,
  IconButton,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Card,
  CardContent,
  Divider,
  Grid,
  Autocomplete,
} from '@mui/material';
import { useTheme } from '@mui/system';
import { tokens } from '../../theme';
import { Plus, Cog, X, Code, Globe, Wifi, Key, AlertTriangle, Layers, Zap, FileText } from 'lucide-react';
import api from '../../api/api';
import ChangesContext from '../TlsInspection/ChangesContext';

// Animation variants for Framer Motion
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};

const PolicyConfig = ({ onConfigChange }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const policy = location.state?.policy || null;

  const [formValues, setFormValues] = useState({
    policyName: '',
    description: '',
  });
  const [boxes, setBoxes] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [categories, setCategories] = useState([]);
  const [isFetchingCategories, setIsFetchingCategories] = useState(true);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [initialBoxes, setInitialBoxes] = useState([]);
  const { hasChanges, setHasChanges } = useContext(ChangesContext);
  

  const setHasChangesTrue = useCallback(() => {
    setHasChanges(true);
    if (onConfigChange) {
      onConfigChange(true);
    }
  }, [onConfigChange]);

  const resetHasChanges = useCallback(() => {
    setHasChanges(false);
    if (onConfigChange) {
      onConfigChange(false);
    }
  }, [onConfigChange]);

  useEffect(() => {
    if (policy) {
      setFormValues({
        policyName: policy.PolicyName || '',
        description: policy.description || '',
      });
  
      const fetchExpressions = async () => {
        try {
          const response = await api.get('/api/policy?action=read', { withCredentials: true });
          const policyData = response.data.find((expr) => expr.EntityId === policy.id);
  
          if (policyData && policyData.Attributes && policyData.Attributes.conditions) {
            const conditions = policyData.Attributes.conditions.map((group) => group.conditions);
            setBoxes(conditions);
            setInitialBoxes(JSON.parse(JSON.stringify(conditions)));
          } else {
            setBoxes([]);
            setInitialBoxes([]);
          }
        } catch (error) {
          console.error('Failed to fetch policy expressions:', error);
          setBoxes([]);
          setInitialBoxes([]);
        }
      };
  
      fetchExpressions();
  
      setInitialFormValues({
        policyName: policy.PolicyName || '',
        description: policy.description || '',
      });
  
      resetHasChanges(); // Reset hasChanges after fetching initial data
    }
  }, [policy, resetHasChanges]);

  useEffect(() => {
    const checkForChanges = () => {
      const formChanged = JSON.stringify(initialFormValues) !== JSON.stringify(formValues);
      const boxesChanged = JSON.stringify(initialBoxes) !== JSON.stringify(boxes);
      const newHasChanges = formChanged || boxesChanged;
      
      if (newHasChanges !== hasChanges) {
        setHasChanges(newHasChanges);
      }
    };

    checkForChanges();
  }, [formValues, boxes, initialFormValues, initialBoxes, hasChanges]);

  useEffect(() => {
    const fetchCategories = async () => {
      setIsFetchingCategories(true);
      try {
        const response = await api.get('/category/domain?action=read', { withCredentials: true });
        const fetchedCategories = response.data.categories?.[0]?.categories || [];
        setCategories(fetchedCategories.map((cat) => cat.name));
      } catch (error) {
        console.error('Error fetching categories:', error);
        setCategories([]);
      } finally {
        setIsFetchingCategories(false);
      }
    };
    fetchCategories();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
    setHasChangesTrue(); // Set hasChanges to true when input changes
  };

  const addCondition = useCallback((boxIndex) => {
    setBoxes((prev) => {
      const newBoxes = [...prev];
      newBoxes[boxIndex].push({ selector: '', value: [] }); // Add new condition
      setHasChangesTrue(); // Set hasChanges to true
      return newBoxes;
    });
  }, [setHasChangesTrue]);

  const handleConditionChange = useCallback((boxIndex, conditionIndex, field, value) => {
    setBoxes((prev) => {
      const newBoxes = [...prev];
      const updatedCondition = { ...newBoxes[boxIndex][conditionIndex], [field]: value };
  
      if (field === 'value' && typeof value === 'string') {
        updatedCondition[field] = [value];
      }
  
      newBoxes[boxIndex][conditionIndex] = updatedCondition;
      setHasChangesTrue(); // Set hasChanges to true
  
      return newBoxes;
    });
  
    // Clear errors for the updated condition
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[`box-${boxIndex}-condition-${conditionIndex}`];
      return updatedErrors;
    });
  }, [setHasChangesTrue]);

  const addBox = useCallback(() => {
    setBoxes((prev) => {
      const newBoxes = [...prev, [{ selector: '', value: [] }]]; // Add new box
      setHasChangesTrue(); // Set hasChanges to true
      return newBoxes;
    });
  }, [setHasChangesTrue]);

  const removeCondition = useCallback((boxIndex, conditionIndex) => {
    setBoxes((prev) => {
      const newBoxes = [...prev];
      newBoxes[boxIndex] = newBoxes[boxIndex].filter((_, i) => i !== conditionIndex);
      if (newBoxes[boxIndex].length === 0) {
        newBoxes.splice(boxIndex, 1);
      }
      setHasChangesTrue(); // Set hasChanges to true
      return newBoxes;
    });
  }, [setHasChangesTrue]);

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    boxes.forEach((group, boxIndex) => {
      group.forEach((condition, conditionIndex) => {
        const { selector, value } = condition;

        if (!selector || !value || value.length === 0) {
          isValid = false;
          errors[`box-${boxIndex}-condition-${conditionIndex}`] = "Please select a value for this condition.";
        } else {
          switch (selector) {
            case "IP":
              const ipRegex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/?(?:[0-9]|[1-2][0-9]|3[0-2])?$/;
              if (!ipRegex.test(value[0])) {
                isValid = false;
                errors[`box-${boxIndex}-condition-${conditionIndex}`] = "Enter a valid IP or CIDR (e.g., 192.168.0.1 or 192.168.0.0/24).";
              }
              break;

            case "Domain":
              const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
              if (!domainRegex.test(value[0])) {
                isValid = false;
                errors[`box-${boxIndex}-condition-${conditionIndex}`] = "Enter a valid domain (e.g., example.com).";
              }
              break;

            case "Url":
              const urlRegex = /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/;
              if (!urlRegex.test(value[0])) {
                isValid = false;
                errors[`box-${boxIndex}-condition-${conditionIndex}`] = "Enter a valid URL (e.g., https://example.com).";
              }
              break;

            case "Keyword":
              if (value[0].trim() === "") {
                isValid = false;
                errors[`box-${boxIndex}-condition-${conditionIndex}`] = "Keyword cannot be empty.";
              }
              break;

            case "Category":
              if (value.length === 0) {
                isValid = false;
                errors[`box-${boxIndex}-condition-${conditionIndex}`] = "Select at least one category.";
              }
              break;

            default:
              break;
          }
        }
      });
    });

    setErrors(errors);
    return isValid;
  };

  const handleSavePolicy = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSaving(true);

    try {
      const combinedConditions = boxes.map((group, groupIndex) => ({
        operator: groupIndex === 0 ? 'AND' : 'OR',
        conditions: group.map(({ selector, value }) => ({
          selector,
          value,
        })),
      }));

      const data = {
        Attributes: {
          policyName: formValues.policyName.trim(),
          description: formValues.description.trim() || '',
          conditions: combinedConditions,
        },
      };

      if (policy && policy.id) {
        await api.put(`/api/policy?action=update&EntityId=${policy.id}`, data, { withCredentials: true });
      } else {
        throw new Error('Policy ID is missing, unable to update policy');
      }

      resetHasChanges();
      localStorage.setItem('isPolicySaved', 'true'); // Save state to local storage
      navigate('/dashboard/policy', { state: { policySaved: true } });
    } catch (error) {
      console.error('Error saving policy:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    setOpenConfirmModal(true);
  };

  const handleConfirmClose = () => {
    setOpenConfirmModal(false);
    navigate('/dashboard/policy');
  };

  const handleCancelClose = () => {
    setOpenConfirmModal(false);
  };

  if (!policy) {
    return (
      <Card className="w-full max-w-3xl mx-auto mt-8" sx={{ backgroundColor: colors.primary[400], color: colors.grey[100] }}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            No Policy Found
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            Please return to the policy list.
          </Typography>
          <Button variant="contained" color="primary" onClick={() => navigate('/dashboard/policy')}>
            Go Back
          </Button>
        </CardContent>
      </Card>
    );
  }

  return (
    <motion.div
      className="container mx-auto p-4 max-w-4xl"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <motion.div variants={itemVariants} className="flex items-center mb-7 space-x-3 mx-4">
        <Typography variant="h4" component="h1" className="font-bold text-primary" sx={{ color: colors.blueAccent[500] }}>
          {policy.PolicyName}
        </Typography>
      </motion.div>

      <form onSubmit={handleSavePolicy}>
        <motion.div variants={itemVariants}>
        <Card className="mb-8 shadow-lg overflow-hidden" sx={{ backgroundColor: colors.primary[400], color: colors.grey[100] }}>
        <CardContent className="p-6">
              <div className="flex items-center mb-6 space-x-3">
                <FileText className="w-6 h-6 text-primary" />
                <Typography variant="h6" component="h2" className="text-primary font-semibold">
                  Policy Details
                </Typography>
              </div>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="policyName"
                    name="policyName"
                    label="Policy Name"
                    placeholder="Enter policy name"
                    value={formValues.policyName}
                    onChange={handleInputChange}
                    required
                    error={!!errors.policyName}
                    helperText={errors.policyName}
                    className="mb-4"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: colors.grey[100],
                        },
                        '&:hover fieldset': {
                          borderColor: colors.blueAccent[500],
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: colors.blueAccent[500],
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: colors.grey[100],
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: colors.blueAccent[500],
                      },
                      '& .MuiFormHelperText-root': {
                        color: colors.redAccent[500],
                      },
                    }}
                  />
                  {errors.policyName && (
                    <div className="flex items-center mt-1 text-red-500" role="alert">
                      <AlertTriangle className="w-4 h-4 mr-1" aria-hidden="true" />
                      <Typography variant="caption">{errors.policyName}</Typography>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="description"
                    name="description"
                    label="Description"
                    placeholder="Enter policy description (optional)"
                    value={formValues.description}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                    className="mb-4"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: colors.grey[100],
                        },
                        '&:hover fieldset': {
                          borderColor: colors.blueAccent[500],
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: colors.blueAccent[500],
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: colors.grey[100],
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: colors.blueAccent[500],
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <Typography variant="body2" color="textSecondary" className="mt-4">
                Define the basic information for your policy. A clear name and description will help you manage multiple policies effectively.
              </Typography>
            </CardContent>
          </Card>
        </motion.div>

        <motion.div variants={itemVariants}>
        <Card className="mb-8 shadow-lg overflow-hidden" sx={{ backgroundColor: colors.primary[400], color: colors.grey[100] }}>
        <CardContent className="p-6">
              <div className="flex items-center mb-4 space-x-3">
                <Cog className="w-6 h-6 text-secondary" />
                <Typography variant="h6" component="h2" className="text-secondary font-semibold">
                  Define Policy Conditions
                </Typography>
              </div>
              <Typography variant="body2" color="textSecondary" paragraph className="ml-9">
                Specify the criteria for your policy using the options below.
              </Typography>
              <div className="flex items-center mb-4 mt-6 space-x-3">
                <Layers className="w-5 h-5 text-primary" />
                <Typography variant="subtitle1" className="font-semibold text-primary">
                  Condition Groups
                </Typography>
              </div>
              <Divider className="mb-6" />
              {boxes.length > 0 ? (
                boxes.map((conditions, boxIndex) => (
                  <React.Fragment key={boxIndex}>
                    {boxIndex > 0 && (
                      <Box className="flex items-center justify-center my-6">
                        <Chip
                          label="OR"
                          color="secondary"
                          variant="outlined"
                          className="font-semibold px-4 py-2"
                          icon={<Zap className="w-4 h-4" />}
                          sx={{
                            color: colors.grey[100],
                            borderColor: colors.grey[100],
                          }}
                        />
                      </Box>
                    )}
                    <motion.div
                      variants={itemVariants}
                      className="mb-6 bg-gray-50 rounded-lg shadow-sm overflow-hidden border border-gray-200"
                      sx={{ backgroundColor: colors.primary[500], borderColor: colors.primary[600] }}
                    >
                      <CardContent className="p-6" sx={{ backgroundColor: colors.primary[400], color: colors.grey[100] }}>
                        {conditions.map((condition, conditionIndex) => (
                          <motion.div
                            key={conditionIndex}
                            className="mb-6 relative p-6 rounded-lg shadow-lg"
                            variants={itemVariants}
                            sx={{ backgroundColor: colors.primary[400], borderColor: colors.primary[500] }}
                          >
                            <Grid container spacing={4} alignItems="center" sx={{ backgroundColor: colors.primary[400], color: colors.grey[100] }}>
                              <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined">
                                <InputLabel id={`selector-label-${boxIndex}-${conditionIndex}`} sx={{ color: colors.grey[100] }}>Condition Type</InputLabel>
                                <Select
                                    labelId={`selector-label-${boxIndex}-${conditionIndex}`}
                                    id={`selector-${boxIndex}-${conditionIndex}`}
                                    name="selector"
                                    value={condition.selector}
                                    onChange={(e) => handleConditionChange(boxIndex, conditionIndex, 'selector', e.target.value)}
                                    required
                                    label="Condition Type"
                                    sx={{
                                      '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                          borderColor: colors.grey[100],
                                        },
                                        '&:hover fieldset': {
                                          borderColor: colors.blueAccent[500],
                                        },
                                        '&.Mui-focused fieldset': {
                                          borderColor: colors.blueAccent[500],
                                        },
                                      },
                                      '& .MuiInputLabel-root': {
                                        color: colors.grey[100],
                                      },
                                      '& .MuiInputLabel-root.Mui-focused': {
                                        color: colors.blueAccent[500],
                                      },
                                    }}
                                    startAdornment={
                                      <Box className="mr-2 text-gray-400">
                                        {condition.selector === 'Category' && <Code className="w-5 h-5" />}
                                        {condition.selector === 'Domain' && <Globe className="w-5 h-5" />}
                                        {condition.selector === 'IP' && <Wifi className="w-5 h-5" />}
                                        {condition.selector === 'Keyword' && <Key className="w-5 h-5" />}
                                        {condition.selector === 'Url' && <Globe className="w-5 h-5" />}
                                      </Box>
                                    }
                                  >
                                    <MenuItem value="Category">Category</MenuItem>
                                    <MenuItem value="Domain">Domain</MenuItem>
                                    <MenuItem value="IP">IP</MenuItem>
                                    <MenuItem value="Keyword">Keyword</MenuItem>
                                    <MenuItem value="Url">URL</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item xs={12} md={8}>
                                {condition.selector === 'IP' && (
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    name="ipValue"
                                    sx={{
                                      '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                          borderColor: colors.grey[100],
                                        },
                                        '&:hover fieldset': {
                                          borderColor: colors.blueAccent[500],
                                        },
                                        '&.Mui-focused fieldset': {
                                          borderColor: colors.blueAccent[500],
                                        },
                                      },
                                      '& .MuiInputLabel-root': {
                                        color: colors.grey[100],
                                      },
                                      '& .MuiInputLabel-root.Mui-focused': {
                                        color: colors.blueAccent[500],
                                      },
                                    }}
                                    label="IP Address"
                                    placeholder="Enter IP address (e.g., 192.168.0.1 or 192.168.0.0/24)"
                                    value={condition.value[0] || ''}
                                    onChange={(e) => handleConditionChange(boxIndex, conditionIndex, 'value', e.target.value)}
                                    required
                                    error={!!errors[`box-${boxIndex}-condition-${conditionIndex}`]}
                                    helperText={errors[`box-${boxIndex}-condition-${conditionIndex}`] || ''}
                                    InputProps={{
                                      startAdornment: (
                                        <Wifi className="w-5 h-5 mr-2 text-gray-400" />
                                      ),
                                    }}
                                  />
                                )}
                                {condition.selector === 'Domain' && (
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    name="domainValue"
                                    label="Domain"
                                    placeholder="Enter domain (e.g., example.com)"
                                    value={condition.value[0] || ''}
                                    onChange={(e) => handleConditionChange(boxIndex, conditionIndex, 'value', e.target.value)}
                                    required
                                    sx={{
                                      '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                          borderColor: colors.grey[100],
                                        },
                                        '&:hover fieldset': {
                                          borderColor: colors.blueAccent[500],
                                        },
                                        '&.Mui-focused fieldset': {
                                          borderColor: colors.blueAccent[500],
                                        },
                                      },
                                      '& .MuiInputLabel-root': {
                                        color: colors.grey[100],
                                      },
                                      '& .MuiInputLabel-root.Mui-focused': {
                                        color: colors.blueAccent[500],
                                      },
                                    }}
                                    error={!!errors[`box-${boxIndex}-condition-${conditionIndex}`]}
                                    helperText={errors[`box-${boxIndex}-condition-${conditionIndex}`]}
                                    InputProps={{
                                      startAdornment: (
                                        <Globe className="w-5 h-5 mr-2 text-gray-400" />
                                      ),
                                    }}
                                  />
                                )}
                                {condition.selector === 'Category' && (
                                  <Autocomplete
                                    multiple
                                    options={categories}
                                    value={condition.value || []}
                                    sx={{
                                      '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                          borderColor: colors.grey[100],
                                        },
                                        '&:hover fieldset': {
                                          borderColor: colors.blueAccent[500],
                                        },
                                        '&.Mui-focused fieldset': {
                                          borderColor: colors.blueAccent[500],
                                        },
                                      },
                                      '& .MuiInputLabel-root': {
                                        color: colors.grey[100],
                                      },
                                      '& .MuiInputLabel-root.Mui-focused': {
                                        color: colors.blueAccent[500],
                                      },
                                    }}
                                    onChange={(event, newValue) =>
                                      handleConditionChange(boxIndex, conditionIndex, 'value', newValue)
                                    }
                                    loading={isFetchingCategories}
                                    renderTags={(value, getTagProps) =>
                                      value.map((option, idx) => (
                                        <Chip
                                          key={idx}
                                          variant="outlined"
                                          label={option}
                                          {...getTagProps({ index: idx })}
                                          className="m-1"
                                        />
                                      ))
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Categories"
                                        placeholder={
                                          isFetchingCategories ? 'Loading...' : 'Select or enter categories'
                                        }
                                        error={!!errors[`box-${boxIndex}-condition-${conditionIndex}`]}
                                        helperText={
                                          errors[`box-${boxIndex}-condition-${conditionIndex}`]
                                        }
                                        InputProps={{
                                          ...params.InputProps,
                                          startAdornment: (
                                            <>
                                              <Code className="w-5 h-5 mr-2 text-gray-400" />
                                              {params.InputProps.startAdornment}
                                            </>
                                          ),
                                        }}
                                      />
                                    )}
                                  />
                                )}
                                {condition.selector === 'Keyword' && (
                                  <TextField
                                    fullWidth
                                    sx={{
                                      '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                          borderColor: colors.grey[100],
                                        },
                                        '&:hover fieldset': {
                                          borderColor: colors.blueAccent[500],
                                        },
                                        '&.Mui-focused fieldset': {
                                          borderColor: colors.blueAccent[500],
                                        },
                                      },
                                      '& .MuiInputLabel-root': {
                                        color: colors.grey[100],
                                      },
                                      '& .MuiInputLabel-root.Mui-focused': {
                                        color: colors.blueAccent[500],
                                      },
                                    }}
                                    variant="outlined"
                                    type="text"
                                    name="keywordValue"
                                    label="Keyword"
                                    placeholder="Enter keyword (e.g., confidential)"
                                    value={condition.value[0] || ''}
                                    onChange={(e) =>
                                      handleConditionChange(boxIndex, conditionIndex, 'value', e.target.value)
                                    }
                                    required
                                    error={!!errors[`box-${boxIndex}-condition-${conditionIndex}`]}
                                    helperText={errors[`box-${boxIndex}-condition-${conditionIndex}`]}
                                    InputProps={{
                                      startAdornment: (
                                        <Key className="w-5 h-5 mr-2 text-gray-400" />
                                      ),
                                    }}
                                  />
                                )}
                                {condition.selector === 'Url' && (
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    name="urlValue"
                                    label="URL"
                                    sx={{
                                      '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                          borderColor: colors.grey[100],
                                        },
                                        '&:hover fieldset': {
                                          borderColor: colors.blueAccent[500],
                                        },
                                        '&.Mui-focused fieldset': {
                                          borderColor: colors.blueAccent[500],
                                        },
                                      },
                                      '& .MuiInputLabel-root': {
                                        color: colors.grey[100],
                                      },
                                      '& .MuiInputLabel-root.Mui-focused': {
                                        color: colors.blueAccent[500],
                                      },
                                    }}
                                    placeholder="Enter URL (e.g., https://example.com)"
                                    value={condition.value[0] || ''}
                                    onChange={(e) =>
                                      handleConditionChange(boxIndex, conditionIndex, 'value', e.target.value)
                                    }
                                    required
                                    error={!!errors[`box-${boxIndex}-condition-${conditionIndex}`]}
                                    helperText={errors[`box-${boxIndex}-condition-${conditionIndex}`]}
                                    InputProps={{
                                      startAdornment: (
                                        <Globe className="w-5 h-5 mr-2 text-gray-400" />
                                      ),
                                    }}
                                  />
                                )}
                              </Grid>

                              <Grid item xs={12} md={1} className="flex justify-end">
                                <IconButton
                                  aria-label="delete condition"
                                  size="small"
                                  className="text-gray-500 hover:text-red-500 transition-colors duration-200"
                                  onClick={() => removeCondition(boxIndex, conditionIndex)}
                                >
                                  <X className="w-5 h-5" />
                                </IconButton>
                              </Grid>
                            </Grid>

                            {conditionIndex < conditions.length - 1 && (
                              <Box className="flex items-center justify-center my-4" sx={{ backgroundColor: colors.primary[400], color: colors.grey[100] }}>
                                <Chip
                                  label="AND"
                                  color="primary"
                                  variant="outlined"
                                  sx={{ backgroundColor: colors.primary[400], color: colors.grey[100] }}
                                  className="font-semibold px-4 py-2"
                                  icon={<Layers className="w-4 h-4" />}
                                />
                              </Box>
                            )}
                          </motion.div>
                        ))}
                        <Button
                          variant="outlined"
                          startIcon={<Plus className="w-4 h-4" />}
                          onClick={() => addCondition(boxIndex)}
                          className="mt-2"
                          sx={{
                            color: colors.blueAccent[500],
                            borderColor: colors.blueAccent[500],
                            '&:hover': {
                              backgroundColor: colors.blueAccent[100],
                              borderColor: colors.blueAccent[500],
                            },
                          }}
                        >
                          Add Condition (AND)
                        </Button>
                      </CardContent>
                    </motion.div>
                  </React.Fragment>
                ))
              ) : (
                <Typography variant="body1" className="text-center my-4">
                  No conditions added yet. Click "Add Condition Group (OR)" to start.
                </Typography>
              )}
              <br />
              <Button
                variant="outlined"
                startIcon={<Plus className="w-4 h-4" />}
                onClick={addBox}
                sx={{
                  color: colors.blueAccent[500],
                  borderColor: colors.blueAccent[500],
                  '&:hover': {
                    backgroundColor: colors.blueAccent[100],
                    borderColor: colors.blueAccent[500],
                  },
                }}
                className="mt-2"
              >
                Add Condition Group (OR)
              </Button>
            </CardContent>
          </Card>
        </motion.div>

        <motion.div variants={itemVariants}>
        <Box display="flex" justifyContent="space-between" mt={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCancel}
              sx={{
                backgroundColor: colors.redAccent[500],
                '&:hover': {
                  backgroundColor: colors.redAccent[700],
                },
              }}
            >
              Cancel
            </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSaving || !hasChanges}
            sx={{
              backgroundColor: colors.greenAccent[500],
              '&:hover': {
                backgroundColor: colors.greenAccent[700],
              },
            }}
            startIcon={isSaving ? <CircularProgress size={20} /> : null}
          >
            {isSaving ? 'Saving...' : 'Save Policy'}
          </Button>
          </Box>
        </motion.div>
      </form>

      <Dialog
        open={openConfirmModal}
        onClose={handleCancelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cancel Configuration</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to cancel? Any unsaved configurations will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClose} color="primary">
            No, Keep Editing
          </Button>
          <Button onClick={handleConfirmClose} color="primary" autoFocus>
            Yes, Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </motion.div>
  );
};

export default PolicyConfig;