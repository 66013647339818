import React, { useState } from "react";
import {
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    MenuItem
} from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { useTheme } from '@mui/system';
import { tokens } from "../../theme";
import DrawerLogs from './DrawerLogs';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// Sample logs data
const initialLogs = [
    { id: 1, time: "18/08/2024 10:15:30", name: "Block-PDF-FILE", alertType: "Login", action: "Success", activity: "File Download", user: "Jane Smith", application: "App 1" },
    { id: 2, time: "18/08/2024 10:16:45", name: "Block-PDF-FILE", alertType: "Login", action: "Failed", activity: "Create", user: "Jane Smith", application: "App 1" },
    { id: 3, time: "18/08/2024 10:17:00", name: "Block-PDF-FILE", alertType: "Login", action: "Success", activity: "File Download", user: "Jane Smith", application: "App 1" },
    { id: 4, time: "18/08/2024 10:17:15", name: "Block-PDF-FILE", alertType: "Login", action: "Failed", activity: "File Download", user: "Jane Smith", application: "App 1" },
    // Add more log entries as needed
];


function Logs() {
    const [logs] = useState(initialLogs);
    const theme = useTheme();
    const [selectedLog, setSelectedLog] = useState(null);
    const colors = tokens(theme.palette.mode);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [filters, setFilters] = useState({
        applicationName: "",
        acknowledged: "",
        policyName: "",
    });

    const handleInputChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };

    const applyFilters = () => {
        // Implement filtering logic based on filters state
        console.log("Filters applied:", filters);
    };


    const handleRowClick = (params) => {
        const selectedLog = logs.find(log => log.id === params.id);
        setSelectedLog(selectedLog);
        setDrawerOpen(true);
      };

    // Define columns for the DataGrid
    const columns = [
        { field: "id", headerName: "#", width: 70 },
        {
            field: "time",
            // make the header name bold
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    TIME
                </Typography>
            ),
            flex: 1,
            renderCell: (params) => (
                <Typography
                    sx={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        marginTop: "15px",
                        fontSize: "12px",
                    }}
                    color={colors.greenAccent[400]}
                >
                    {params.value}
                </Typography>
            ),
        },
        {
            field: "name",
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    NAME
                </Typography>
            ),
            width: 200,
            renderCell: (params) => (
                <>
                    <span
                        className="font-bold"
                    >{params.value}</span>
                </>
            ),
        },

        {
            field: "alertType",
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    ALERT TYPE
                </Typography>
            ),
            width: 200,
            renderCell: (params) => (
                <>
                    <span
                        className="font-bold"
                    >{params.value}</span>
                </>
            ),
        },
        {
            field: "action",
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    ACTION
                </Typography>
            ),
            width: 200,
            renderCell: (params) => (
                <>
                    <span
                        className="font-bold"
                    >{params.value}</span>
                </>
            ),
        },
        {
            field: "activity",
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    ACTIVITY
                </Typography>
            ),
            width: 200,
            renderCell: (params) => (
                <>
                    <span
                        className="font-bold"
                    >{params.value}</span>
                </>
            ),
        },
        {
            field: "user",
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    USER
                </Typography>
            ),
            width: 200,
            renderCell: (params) => (
                <>
                    <span
                        className="font-bold"
                    >{params.value}</span>
                </>
            ),
        },
        {
            field: "application",
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    APPLICATION
                </Typography>
            ),
            width: 200,
            renderCell: (params) => (
                <>
                    <span
                        className="font-bold"
                    >{params.value}</span>
                </>
            ),
        },
    ];
    return (
        <Box className="m-5">
            {/* Header Component */}
            <Header title="Logs" subtitle="View logs and monitor activity" />

            {/* Filters Section */}
            <Typography variant="h6" className="font-bold mb-4" sx={{ color: colors.greenAccent[400] }}>
                FILTERS <ArrowDownwardIcon className="text-lg" />
            </Typography>
            <div className="h-2" />
            <Box className="flex justify-start space-x-4 mb-4">
                <TextField
                    label="Application Name"
                    variant="outlined"
                    size="small"
                    name="applicationName"
                    value={filters.applicationName}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Policy Name"
                    variant="outlined"
                    size="small"
                    name="policyName"
                    value={filters.policyName}
                    onChange={handleInputChange}
                />
                {/* Dropdown filter for 'Acknowledged' */}
                <TextField
                    select
                    label="Acknowledged"
                    variant="outlined"
                    size="small"
                    name="acknowledged"
                    value={filters.acknowledged}
                    onChange={handleInputChange}
                    sx={{ width: '100px' }}
                >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="true">Acknowledged</MenuItem>
                    <MenuItem value="false">Not Acknowledged</MenuItem>
                
                </TextField>
                <Button variant="contained" onClick={applyFilters} sx={{ backgroundColor: colors.greenAccent[400], color: 'white' }}>
                    + Add Filter
                </Button>
            </Box>

            {/* DataGrid for logs */}
            <Box sx={{ height: `calc(100vh - 150px)`, width: '100%' }}>
                <DataGrid
                    rows={logs}
                    columns={columns}
                    pageSize={8}
                    rowsPerPageOptions={[5, 10, 20]}
                    disableRowSelectionOnClick
                    disableColumnResize
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnSorting
                    onCellClick={handleRowClick}
                    sx={{
                        '& .MuiDataGrid-root': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: colors.primary[600],
                            color: colors.grey[100],
                            fontSize: '14px',
                            fontWeight: 'bold',
                            borderBottom: 'none',
                        },
                        '& .MuiDataGrid-cell': {
                            color: colors.grey[300],
                            borderBottom: 'none',
                            fontSize: '12px',
                        },
                        '& .MuiDataGrid-cell:hover': {
                            backgroundColor: colors.primary[400],  // Hover effect for rows
                            cursor: 'pointer',
                        },
                        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                    }}
                />
            </Box>

            <DrawerLogs
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                logDetails={selectedLog}  // Pass the selected log details
            />
        </Box>
    );
};

export default Logs;
