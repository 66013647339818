import { useFormContext } from 'react-hook-form';
import { Box, TextField, Typography } from '@mui/material';

const SetProfileStep = () => {
    const { register, formState: { errors } } = useFormContext();

    return (
        <Box className="flex flex-col gap-4 p-2 min-h-screen">
            <Typography variant="h6">Set Profile Name</Typography>
            <TextField
                label="Profile Name"
                variant="outlined"
                fullWidth
                error={!!errors.profile_name}
                helperText={errors.profile_name?.message}
                {...register("profile_name", { required: "Profile name is required" })}
            />
        </Box>
    );
};

export default SetProfileStep;