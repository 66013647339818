import { Box, IconButton, useTheme, Menu, Avatar, CircularProgress, Typography, Divider, Button } from "@mui/material";
import React, { useState, useContext } from "react";
import { tokens, ColorModeContext } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from '@mui/material/Tooltip';
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import AuthContext from "../auth/AuthContext";

const Topbar = ({ isCollapsed }) => {  // Accept isCollapsed as a prop
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const { logout, user, loading } = useContext(AuthContext);
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        await logout();
        navigate("/login");
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Box display="flex" justifyContent="space-between" p={2}>
                {/* Search Bar */}
                <Box
                    display="flex"
                    backgroundColor={colors.primary[400]}
                    borderRadius="8px"
                    sx={{
                        width: { xs: '100%', sm: '50%', md: '25%' }, // Responsive width
                        marginLeft: 'auto',
                        marginTop: '-2px',
                        marginBottom: '3px',
                    }}
                >
                    <InputBase
                        sx={{ ml: 2, flex: 1 }}
                        placeholder="Search"
                    />
                    <IconButton type="button" sx={{ p: 1 }}>
                        <SearchIcon />
                    </IconButton>
                </Box>

                {/* ICONS */}
                <Box display="flex">
                    <IconButton onClick={colorMode.toggleColorMode}>
                        {theme.palette.mode === "dark" ? (
                            <DarkModeOutlinedIcon
                                className="text-grey-500"
                                sx={{ transition: 'color 0.3s ease' }}
                            />
                        ) : (
                            <LightModeOutlinedIcon
                                className="text-yellow-500"
                                sx={{ transition: 'color 0.3s ease' }}
                            />
                        )}
                    </IconButton>
                    {/* Notifications Icon with Badge */}
                    {/* <IconButton>
  <Badge 
    badgeContent={4} // Example notification count
    color="error"
    sx={{
      "& .MuiBadge-badge": {
        right: 10,
        top: 10,
        padding: '0 4px',
        backgroundColor: colors.redAccent[500], // Customize badge color
      },
    }}
  >
    <NotificationsOutlinedIcon sx={{ transition: 'color 0.3s ease' }} />
  </Badge>
</IconButton> */}

                    <Box>
                        <Tooltip title={user?.username || "Guest"}>
                            <IconButton onClick={handleClick} size="small">
                                <Avatar
                                    sx={{
                                        backgroundColor: user?.email ? colors.blueAccent[700] : colors.grey[100],
                                        color: user?.email ? colors.grey[100] : colors.blueAccent[500],
                                        transition: 'transform 0.2s ease, background-color 0.2s ease', // Smooth animation
                                        "&:hover": {
                                            transform: 'scale(1.1)', // Slight scaling on hover
                                            backgroundColor: user?.email ? colors.blueAccent[800] : colors.grey[200], // Background color change on hover
                                        }
                                    }}
                                >
                                    {user?.email?.substring(0, 2).toUpperCase() || "U"}
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Menu
                        id="user-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            elevation: 4,
                            sx: {
                                mt: 1.5,
                                minWidth: "240px",
                                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // Smooth shadow
                                borderRadius: "8px", // Rounded corners for a modern look
                                overflow: "visible",
                                transition: 'transform 0.2s ease, opacity 0.2s ease', // Smooth transitions
                                opacity: open ? 1 : 0, // Fade in/out
                                transform: open ? 'translateY(0)' : 'translateY(-10px)', // Slide down
                            },
                        }}
                    >
                        <div className="p-4 rounded-lg">
                            <Box display="flex" alignItems="center" gap={2} mb={2}>
                                <Avatar
                                    sx={{
                                        backgroundColor: user?.email ? "#1E3A8A" : "#E5E7EB",
                                        color: user?.email ? "#fff" : "#1E3A8A",
                                        width: 40,
                                        height: 40
                                    }}
                                >
                                    {user?.email?.substring(0, 2).toUpperCase() || "U"}
                                </Avatar>
                                <Box>
                                    <Typography variant="h6" fontWeight="bold">
                                        {user?.username || "Guest"}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {user?.email || "No email"}
                                    </Typography>
                                </Box>
                            </Box>

                            <Divider />

                            <Button
                                variant="outlined"
                                color="error"
                                fullWidth
                                onClick={handleLogout}
                                startIcon={<LogoutIcon />}
                                sx={{ mt: 2 }}
                            >
                                {loading ? <CircularProgress size={24} /> : "Sign out"}
                            </Button>
                        </div>
                    </Menu>

                </Box>
            </Box>
            <Divider
                sx={{
                    "&.MuiDivider-root": {
                        height: "1px",
                        width: isCollapsed ? "calc(100% - 2px)" : "calc(100% - 57px)",  // Adjust width based on collapse state
                        margin: "auto",
                        marginTop: "-17px",
                        backgroundColor: colors.grey[900], // Light divider color
                    },
                }}
            />

        </>
    );
};

export default Topbar;
