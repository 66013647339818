"use client"

import { useState, useEffect } from "react"
import {
  Box,
  Typography,
  Chip,
  Button,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Tooltip,
} from "@mui/material"
import { Search, KeyboardArrowDown, CalendarToday } from "@mui/icons-material"
import { useTheme } from "@mui/material/styles"
import { tokens } from "../../theme"
import api from "../../api/api";
import DrawerLogs from "./DrawerLogs" // Import the DrawerLogs component
import { formatDate } from "../../utils/formatDate"; // Import the formatDate function

export default function Logs() {
  const [logs, setLogs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("")
  const [sortBy, setSortBy] = useState("id")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const [conclusion, setConclusion] = useState("all")
  const [alertType, setAlertType] = useState("all")
  const [source, setSource] = useState("all")
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedLog, setSelectedLog] = useState(null) // State to track the selected log
  const [drawerOpen, setDrawerOpen] = useState(false) // State to track drawer open status

  const handleStatusClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleStatusClose = () => {
    setAnchorEl(null)
  }

  const getColorForAction = (action) => {
    switch (action) {
      case "BLOCK":
        return "linear-gradient(45deg, #ef4444, #f87171)";
      case "ALLOW":
        return "linear-gradient(45deg, #10b981, #34d399)";
      case "WHITELIST":
        return "linear-gradient(45deg, #7c3aed, #a78bfa)";
      case "ALERT":
        return "linear-gradient(45deg, #3b82f6, #60a5fa)";
      case "USER ALERT":
        return "linear-gradient(45deg, #8884D8, #a5b4fc)";
      default:
        return "linear-gradient(45deg, #7d78e4, #a5b4fc)";
    }
  };


  // Fetch stored logs from the API on component mount
  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await api.get("/api/logs/?action=read", { withCredentials: true });
        console.log(response.data);
        setLogs(
          response.data.map((log) => ({
            id: log.EntityId, // Ensure each row has a unique id property
            userId: log.Attributes.user_id,
            action: log.Attributes.action,
            policyName: log.Attributes.policy_name,
            tenantId: log.Attributes.tenant_id,
            entityId: log.Attributes.entity_id,
            sourceIp: log.Attributes.source_ip,
            domainName: log.Attributes.domain_name,
            url: log.Attributes.url,
            ipAddress: log.Attributes.ip_address,
            ts: formatDate(log.Attributes.ts), // Format the timestamp
          }))
        );
      } catch (error) {
        console.error("Error fetching logs:", error);
      }
    };

    fetchLogs();
  }, []);

  const open = Boolean(anchorEl)
  const popoverId = open ? "status-popover" : undefined

  const handleRowClick = (log) => {
    setSelectedLog(log)
    setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
    setSelectedLog(null)
  }

  // Filter logs based on search query and selected conclusion
  const filteredLogs = logs.filter((log) => {
    const matchesSearchQuery =
      log.policyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      log.action.toLowerCase().includes(searchQuery.toLowerCase()) ||
      log.domainName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      log.ipAddress.toLowerCase().includes(searchQuery.toLowerCase()) ||
      log.sourceIp.toLowerCase().includes(searchQuery.toLowerCase());

    const matchesConclusion =
      conclusion === "all" || log.action.toLowerCase() === conclusion.toLowerCase();

    return matchesSearchQuery && matchesConclusion;
  });


  // Sort logs based on selected sort option
  const sortedLogs = filteredLogs.sort((a, b) => {
    if (sortBy === "id") {
      return a.id - b.id
    } else if (sortBy === "date") {
      return new Date(a.ts) - new Date(b.ts)
    }
    return 0
  })

  return (
    <Box className="flex flex-col h-screen p-6">
      {/* Main content */}
      <Box className="flex-1 overflow-auto">
        <Box className="flex items-center justify-between mb-6">
          <Box>
            <Typography variant="h4" className="font-bold mb-4">
              Logs
            </Typography>
            <Box className="flex gap-2 items-center">

              {/* for future chips */}
            </Box>
          </Box>
        </Box>

        {/* Filters */}
        <Box className="flex gap-3 mb-6 flex-wrap">
          <FormControl size="small" className="w-[150px]">
            <InputLabel id="conclusion-label">Conclusion</InputLabel>
            <Select
              labelId="conclusion-label"
              value={conclusion}
              onChange={(e) => setConclusion(e.target.value)}
              label="Conclusion"
              sx={{ backgroundColor: colors.primary[400] }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="user alert">User Alert</MenuItem>
              <MenuItem value="whitelist">Whitelist</MenuItem>
              <MenuItem value="isolate">Isolate</MenuItem>
              <MenuItem value="alert">Alert</MenuItem>
              <MenuItem value="block">Block</MenuItem>
            </Select>
          </FormControl>

          <Button
            disabled
            variant="outlined"
            size="small"
            startIcon={<CalendarToday />}
            className="h-10"
            sx={{ color: colors.grey[100], borderColor: colors.grey[100] }}
          >
            Date
          </Button>

          <TextField
            size="small"
            placeholder="Search by action, ipaddress,or domain"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="ml-auto w-[300px]"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />

          <FormControl size="small" className="w-[150px]">
            <InputLabel id="sort-by-label">Sort by</InputLabel>
            <Select
              labelId="sort-by-label"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              label="Sort by"
              sx={{ backgroundColor: colors.primary[400] }}
              endAdornment={<KeyboardArrowDown />}
            >
              <MenuItem value="id">ID</MenuItem>
              <MenuItem value="date">Date</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Table */}
        <TableContainer className="rounded-md">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Box className="flex items-center gap-1">
                    <Typography variant="body2">Conclusion</Typography>
                    <KeyboardArrowDown fontSize="small" />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box className="flex items-center gap-1">
                    <Typography variant="body2">Alert Time</Typography>
                    <KeyboardArrowDown fontSize="small" />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box className="flex items-center gap-1">
                    <Typography variant="body2">Entity ID</Typography>
                    <KeyboardArrowDown fontSize="small" />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box className="flex items-center gap-1">
                    <Typography variant="body2">Tenant ID</Typography>
                    <KeyboardArrowDown fontSize="small" />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box className="flex items-center gap-1">
                    <Typography variant="body2">Alert</Typography>
                    <KeyboardArrowDown fontSize="small" />
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">Entities</Typography>
                </TableCell>
                <TableCell>
                  <Box className="flex items-center gap-1">
                    <Typography variant="body2">Source</Typography>
                    <KeyboardArrowDown fontSize="small" />
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredLogs.length > 0 ? (
                filteredLogs.map((log) => (
                  <TableRow key={log.id} hover onClick={() => handleRowClick(log)} sx={{ cursor: "pointer" }}>
                    <TableCell>
                      <Chip
                        label={log.action}
                        size="small"
                        sx={{
                          background: getColorForAction(log.action),
                          color: "#0f0f0f",
                          borderRadius: "4px",
                          fontWeight: 550,
                          px: 1.7,
                        }}
                      />
                    </TableCell>
                    <TableCell className="text-gray-400">{log.ts}</TableCell>
                    <TableCell>
                      <Chip
                        label={`ENT-${log.entityId}`}
                        size="small"
                        sx={{
                          backgroundColor: (theme) => theme.palette.primary[900],
                          color: (theme) => theme.palette.grey[600],
                          borderRadius: "16px",
                          fontWeight: 500,
                          px: 2,
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={`TEN-${log.tenantId}`}
                        size="small"
                        sx={{
                          backgroundColor: (theme) => theme.palette.primary[900],
                          color: (theme) => theme.palette.grey[600],
                          borderRadius: "16px",
                          fontWeight: 500,
                          px: 2,
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography variant="body2" className="font-medium">
                          {log.policyName}
                        </Typography>
                        <Typography variant="caption" className="text-gray-400">
                          {log.ts}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Chip label={log.domainName} size="small" className="bg-gray-800 mb-1 text-xs" />
                        <Box className="flex flex-col gap-1">
                          <Box className="flex items-center gap-1">
                            <Box className="w-3 h-3 rounded-full bg-blue-500 flex items-center justify-center">
                              <Typography variant="caption" className="text-[8px]">
                                IP
                              </Typography>
                            </Box>
                            <Typography variant="caption">{log.ipAddress}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>denovocore</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Typography variant="body2" color="textSecondary">
                      No logs available. Please adjust your filters or try again later.
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>

          </Table>
        </TableContainer>
      </Box>

      {/* Drawer for log details */}
      <DrawerLogs open={drawerOpen} onClose={handleDrawerClose} logDetails={selectedLog} />
    </Box>
  )
}