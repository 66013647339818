import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  Menu,
  MenuItem,
  Divider,
  IconButton,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Search, Add, Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import api from "../../api/api";
import { Ellipsis, Trash2, Settings } from 'lucide-react';
import Header from "../../components/Header";
import AddDomainForm from "./AddDomainForm"; // Assuming the AddDomainForm is in the same directory
import CloseIcon from '@mui/icons-material/Close';
import { format } from "date-fns";

export default function Domain() {
  const [domains, setDomains] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null)
  const [loading, setLoading] = useState(true); // Track loading state
  const [deleteConfirmation, setDeleteConfirmation] = useState(false); // Track whether we're showing a confirmation dialog
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [open, setOpen] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Fetch domains from the backend
  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const response = await api.get("/domain/profiles?action=read", { withCredentials: true });
        setDomains(
          response.data.map((profile) => ({
            id: profile.EntityId, // Ensure each row has a unique id property
            name: profile.Attributes.domainName,
            category: profile.Attributes.category,
            lastEdited: profile.updatedAt, // Replace with actual last edited field if available
          }))
        );
      } catch (error) {
        console.error("Error fetching domains:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDomains();
  }, []);

  // Function to handle deleting a domain profile
  const handleDeleteProfile = async () => {
    // if (!selectedRow) return;
    try {
      const response = await api.delete(`/domain/profiles?action=delete&EntityId=${selectedRow.id}`, { withCredentials: true });

      // Remove the deleted domain profile from the rows state
      setDomains((prevDomains) => prevDomains.filter((domain) => domain.id !== selectedRow.id));

      // Show snackbar message
      handleSnackbar('Domain profile deleted successfully');

      // Close the delete confirmation dialog
      setDeleteConfirmation(false);
    } catch (error) {
      console.error('Failed to delete domain profile:', error);
      handleSnackbar('Failed to delete domain profile');
    }
  };

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmation(false);
  };

  const handleOpenDeleteConfirmation = () => {
    setDeleteConfirmation(true);
    handleMenuClose();
  };

  const handleSearch = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);

  const filteredDomains = domains.filter((domain) =>
    Object.values(domain).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleMenuOpen = (event, domain) => {
    setMenuAnchorEl(event.currentTarget)
    setSelectedDomain(domain);
    setSelectedRow(domain); // Set the selected row when the menu is opened
  }

  const handleSnackbar = (message, severity) => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const handleAddDomain = (newDomain) => {
    // Add the new domain to the domains state by EntitiyId
    setDomains((prevDomains) => [...prevDomains, { ...newDomain, id: newDomain.id || newDomain.EntityId }]);
    setIsModalOpen(false); // Close modal after adding the domain
    handleSnackbar('Domain added successfully', 'success');
  };

  const handleEditDomain = async (updatedDomain) => {
    try {
      const response = await api.put(`/domain/profiles?action=update&EntityId=${updatedDomain.id}`, {
        Attributes: {
          domainName: updatedDomain.name,
          category: updatedDomain.category,
        },
      }, { withCredentials: true });

      if (response.status === 200) {
        setDomains((prevDomains) =>
          prevDomains.map((domain) =>
            domain.id === updatedDomain.id ? { ...domain, ...updatedDomain } : domain
          )
        );
        setIsModalOpen(false); // Close modal after updating the domain
        handleSnackbar('Domain updated successfully', 'success');
      }
    } catch (error) {
      console.error('Failed to update domain profile:', error);
      handleSnackbar('Failed to update domain profile', 'error');
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRow(null);
  };

  const handleEditProfile = () => {
    setIsModalOpen(true);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null)
  }

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{
            textDecoration: "underline",
            cursor: "pointer",
            marginTop: "15px",
            fontSize: "12px",
            marginLeft: "10px",
          }}
          color={colors.greenAccent[400]}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
    },
    {
      field: "lastEdited",
      renderHeader: (params) => (
          <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
              Last Edited
          </Typography>
      ),
      flex: 1,
      renderCell: (params) => {
          // Convert ISO 8601 timestamp to a human-readable format
          const formattedDate = format(new Date(params.value), "MMMM d, yyyy, h:mm a");
  
          return (
              <Typography variant="body2" sx={{ marginTop: "15px" }}>
                  {formattedDate}
              </Typography>
          );
      },
  },
    {
      flex: 1,
      renderCell: (params) => (
        <Box className='flex justify-end'>
          <IconButton
            aria-label="open menu"
            onClick={(event) => handleMenuOpen(event, params.row)}
            size="small"
          >
            <Ellipsis fontSize="small" />
          </IconButton>
        </Box>
      ),
    }
  ]

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column", p: 3 }}>
      <Header title="Domains" subtitle="View and manage your domains" />

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <TextField
          label="Search Domain"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          startIcon={<Add />}
          onClick={() => {
            setSelectedRow(null);
            setIsModalOpen(true);
          }}
        >
          Add Domain
        </Button>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <DataGrid
          rows={filteredDomains}
          columns={columns}
          pageSize={8}
          rowsPerPageOptions={[5, 10, 20]}
          disableRowSelectionOnClick
          disableColumnResize
          disableColumnFilter
          disableColumnMenu
          disableColumnSorting
          sx={{
            "&, [class^=MuiDataGrid]": {
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "none",
            },
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus-within": {
              outline: "none",
            },
          }}
        />
      </Box>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Typography variant="subtitle2" sx={{ px: 2, py: 1 }}>
          Actions
        </Typography>
        <Divider />
        <MenuItem onClick={() => { handleEditProfile(); handleMenuClose(); }}>
          <Settings className="mr-2" size={16} />
          <span>Edit</span>
        </MenuItem>
        <MenuItem onClick={handleOpenDeleteConfirmation}>
          <Trash2 className="mr-2" size={16} />
          <span>Delete</span>
        </MenuItem>
      </Menu>

      <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle>
          {selectedRow ? 'Edit Domain' : 'Add New Domain'}
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <AddDomainForm
            onAddDomain={handleAddDomain}
            onEditDomain={handleEditDomain}
            initialValues={selectedRow}
          />
        </DialogContent>
      </Dialog>
      {/* Descriptive message upon deleting */}
      <Dialog open={deleteConfirmation} onClose={handleCloseDeleteConfirmation} maxWidth="xs" fullWidth>
        <DialogTitle>
          <strong className="text-lg font-bold">Are you sure?
            <div className="float-right cursor-pointer">
              <CloseIcon onClick={handleCloseDeleteConfirmation} />
            </div>
          </strong>
        </DialogTitle>

        <DialogContent className="p-4">
          {/* Warning message */}
          <div className="bg-red-100 text-red-600 p-2 rounded-md mb-4">
            <h1>
              Unexpected bad things will happen if you don't read this!
            </h1>
          </div>

          {/* Descriptive message */}
          <Typography variant="body2" className="mb-4">
            This action <span className="font-semibold">CANNOT</span> be undone.
            This will permanently delete the <span className="font-semibold underline">{selectedRow?.profile_name}</span> profile and remove all associated data.
          </Typography>
        </DialogContent>

        <Box className="p-4">
          <Button
            onClick={handleDeleteProfile}
            color="error"
            variant="contained"
            className="w-full h-8 bg-red-600 text-white text-lg font-semibold"
          >
            I understand, delete this profile
          </Button>
        </Box>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}