"use client"

import React from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  Box,
  Typography,
  Divider,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import {
  AccessTime as ClockIcon,
  Security as ShieldIcon,
  Warning as AlertIcon,
  Insights as ActivityIcon,
  Person as UserIcon,
  Apps as AppIcon,
  Close,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";

const DrawerLogs = ({ open, onClose, logDetails }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const {
    ts = "N/A",
    policyName = "N/A",
    alertType = "N/A",
    action = "N/A",
    activity = "N/A",
    userId = "N/A",
    application = "N/A",
  } = logDetails || {};

  const getChipColor = (value) => {
    if (value === "Blocked") return "error";
    if (value === "Warning") return "warning";
    return "default";
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box className="w-80 p-6">
        {/* Header */}
        <Box className="flex justify-between items-center mb-4">
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              color: colors.greenAccent[400],
            }}
          >
            <ShieldIcon sx={{ marginRight: 1 }} />
            Log Details
          </Typography>
          <IconButton onClick={onClose} size="small" sx={{ color: colors.grey[100] }}>
            <Close />
          </IconButton>
        </Box>
        <Divider />

        {/* Log Details */}
        <Box className="text-center">
          <List disablePadding>
            <ListItem>
              <ListItemText
                primary="Time"
                secondary={ts}
                primaryTypographyProps={{ fontWeight: "bold" }}
                secondaryTypographyProps={{ color: colors.grey[200] }}
              />
              <ClockIcon sx={{ color: colors.grey[500] }} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Policy Name"
                secondary={policyName}
                primaryTypographyProps={{ fontWeight: "bold" }}
                secondaryTypographyProps={{ color: colors.grey[200] }}
              />
              <ShieldIcon sx={{ color: colors.grey[500] }} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Alert Type"
                secondary={alertType}
                primaryTypographyProps={{ fontWeight: "bold" }}
                secondaryTypographyProps={{ color: colors.grey[200] }}
              />
              <AlertIcon sx={{ color: colors.grey[500] }} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Action"
                secondary={action}
                primaryTypographyProps={{ fontWeight: "bold" }}
                secondaryTypographyProps={{ color: colors.grey[200] }}
              />
              {/* <Chip
                label={action}
                color={getChipColor(action)}
                size="small"
                sx={{ marginLeft: 1 }}
              /> */}
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Activity"
                secondary={activity}
                primaryTypographyProps={{ fontWeight: "bold" }}
                secondaryTypographyProps={{ color: colors.grey[200] }}
              />
              <ActivityIcon sx={{ color: colors.grey[500] }} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="User"
                secondary={userId}
                primaryTypographyProps={{ fontWeight: "bold" }}
                secondaryTypographyProps={{ color: colors.grey[200] }}
              />
              <UserIcon sx={{ color: colors.grey[500] }} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Application"
                secondary={application}
                primaryTypographyProps={{ fontWeight: "bold" }}
                secondaryTypographyProps={{ color: colors.grey[200] }}
              />
              <AppIcon sx={{ color: colors.grey[500] }} />
            </ListItem>
          </List>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Footer */}
        <Box sx={{ p: 2 }}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={onClose}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

DrawerLogs.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  logDetails: PropTypes.shape({
    time: PropTypes.string,
    policyName: PropTypes.string,
    alertType: PropTypes.string,
    action: PropTypes.string,
    activity: PropTypes.string,
    user: PropTypes.string,
    application: PropTypes.string,
  }),
};

export default DrawerLogs;