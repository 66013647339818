import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Search, Add, Close } from "@mui/icons-material";
import { Ellipsis, Trash2, Settings } from "lucide-react";
import { useTheme } from "@mui/material/styles";
import api from "../../api/api";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AddNetworkForm from "./AddNetworkForm";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { format } from "date-fns";

export default function Network() {
  const [networks, setNetworks] = useState([]);
  const [loading, setLoading] = useState(true); // Track loading state
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false); // Track whether we're showing a confirmation dialog
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Fetch network profiles from the backend
  useEffect(() => {
    const fetchNetworks = async () => {
      try {
        const response = await api.get("/network/profiles?action=read", { withCredentials: true });
        setNetworks(
          response.data.map((profile) => ({
            id: profile.EntityId, // Ensure each row has a unique id property
            name: profile.Attributes.networkName,
            ip: profile.Attributes.ipAddress,
            lastEdited: profile.updatedAt, // Replace with actual last edited field if available
          }))
        );
      } catch (error) {
        console.error("Error fetching network profiles:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNetworks();
  }, []);

  const handleSearch = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);

  const filteredNetworks = networks.filter((network) =>
    Object.values(network).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleMenuOpen = (event, network) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedNetwork(network);
    setSelectedRow(network); // Set the selected row when the menu is opened
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRow(null);
  };

  const handleAddNetwork = (newNetwork) => {
    setNetworks((prevNetworks) => [...prevNetworks, { ...newNetwork, id: newNetwork.id || newNetwork.EntityId }]);
    setIsModalOpen(false); // Close modal after adding the network
    handleSnackbar('Network profile added successfully', 'success');
  };

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmation(false);
  };

  const handleOpenDeleteConfirmation = () => {
    setDeleteConfirmation(true);
    handleMenuClose();
  };

  // Function to handle deleting a network profile
  const handleDeleteProfile = async () => {
    try {
      await api.delete(`/network/profiles?action=delete&EntityId=${selectedRow.id}`, { withCredentials: true });

      // Remove the deleted network profile from the rows state
      setNetworks((prevNetworks) => prevNetworks.filter((network) => network.id !== selectedRow.id));

      // Show snackbar message
      handleSnackbar('Network profile deleted successfully', 'success');

      // Close the delete confirmation dialog
      setDeleteConfirmation(false);
    } catch (error) {
      console.error('Failed to delete network profile:', error);
      handleSnackbar('Failed to delete network profile', 'error');
    }
  };

  const handleEditProfile = () => {
    setIsModalOpen(true);
    handleMenuClose();
  };


  const handleEditNetwork = async (updatedNetwork) => {
    try {
      const response = await api.put(
        `/network/profiles?action=update&EntityId=${updatedNetwork.id}`,
        {
          Attributes: {
            networkName: updatedNetwork.name,
            ipAddress: updatedNetwork.ip,
          },
        },
        { withCredentials: true }
      );

      if (response.status === 200) {
        setNetworks((prevNetworks) =>
          prevNetworks.map((network) =>
            network.id === updatedNetwork.id ? { ...network, ...updatedNetwork } : network
          )
        );
        setIsModalOpen(false);
        handleSnackbar('Network profile updated successfully', 'success');
      }
    } catch (error) {
      console.error('Failed to update network profile:', error);
      handleSnackbar('Failed to update network profile', 'error');
    }
  };


  const handleSnackbar = (message, severity) => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{
            marginTop: "15px",
            fontSize: "14px",
            marginLeft: "10px",
          }}
          color={colors.greenAccent[400]}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "ip",
      headerName: "IP",
      flex: 1,
    },
    {
      field: "lastEdited",
      renderHeader: (params) => (
        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
          Last Edited
        </Typography>
      ),
      flex: 1,
      renderCell: (params) => {
        // Convert ISO 8601 timestamp to a human-readable format
        const formattedDate = format(new Date(params.value), "MMMM d, yyyy, h:mm a");

        return (
          <Typography variant="body2" sx={{ marginTop: "15px" }}>
            {formattedDate}
          </Typography>
        );
      },
    },
    {
      flex: 1,
      renderCell: (params) => (
        <Box className="flex justify-end">
          <IconButton
            aria-label="open menu"
            onClick={(event) => handleMenuOpen(event, params.row)}
            size="small"
          >
            <Ellipsis fontSize="small" />
          </IconButton>
        </Box>
      ),
    },
  ];

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column", p: 3 }}>
      <Header title="Network" subtitle="View Network and monitor activity" />

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          startIcon={<Add />}
          onClick={() => {
            setSelectedRow(null);
            handleOpenModal();
          }}
        >
          Add Network
        </Button>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <DataGrid
          rows={filteredNetworks}
          columns={columns}
          pageSize={8}
          rowsPerPageOptions={[5, 10, 20]}
          disableRowSelectionOnClick
          disableColumnResize
          disableColumnFilter
          disableColumnMenu
          disableColumnSorting
          sx={{
            "&, [class^=MuiDataGrid]": {
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "none",
            },
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus-within": {
              outline: "none",
            },
          }}
        />
      </Box>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleEditProfile}>
          <span className="text-green-600"><EditIcon /> Edit</span>        </MenuItem>
        <MenuItem onClick={handleOpenDeleteConfirmation}>
          <span className="text-red-600"><DeleteIcon /> Delete</span>           </MenuItem>
      </Menu>

      <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle className="text-2xl font-bold mb-6"
          sx={{ backgroundColor: colors.primary[400], borderColor: colors.primary[500] }}

        >
          {selectedRow ? 'Edit Network Profile' : 'Add New Network'}
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{ backgroundColor: colors.primary[400], borderColor: colors.primary[500] }}
        >
          <AddNetworkForm
            onAddNetwork={handleAddNetwork} // Pass callback to handle network addition
            onEditNetwork={handleEditNetwork}
            initialValues={selectedRow}
          />
        </DialogContent>
      </Dialog>
      {/* Descriptive message upon deleting */}
      <Dialog open={deleteConfirmation} onClose={handleCloseDeleteConfirmation} maxWidth="xs" fullWidth>
        <DialogTitle>
          <strong className="text-lg font-bold">Are you sure?
            <div className="float-right cursor-pointer">
              <CloseIcon onClick={handleCloseDeleteConfirmation} />
            </div>
          </strong>
        </DialogTitle>

        <DialogContent className="p-4">
          {/* Warning message */}
          <div className="bg-red-100 text-red-600 p-2 rounded-md mb-4">
            <h1>
              Unexpected bad things will happen if you don't read this!
            </h1>
          </div>

          {/* Descriptive message */}
          <Typography variant="body2" className="mb-4">
            This action <span className="font-semibold">CANNOT</span> be undone.
            This will permanently delete the <span className="font-semibold underline">{selectedRow?.profile_name}</span> profile and remove all associated data.
          </Typography>
        </DialogContent>

        <Box className="p-4">
          <Button
            onClick={handleDeleteProfile}
            color="error"
            variant="contained"
            className="w-full h-8 bg-red-600 text-white text-lg font-semibold"
          >
            I understand, delete this profile
          </Button>
        </Box>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        className='text-center my-9'
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}