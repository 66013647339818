"use client"

import { useState } from "react"
import { Box, useTheme } from "@mui/material"
import Sidebar from "./Sidebar"
import Topbar from "./Topbar"
import { tokens } from "../../theme"

const Layout = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const isDark = theme.palette.mode === "dark"

  const handleCollapse = (collapsed) => {
    setIsCollapsed(collapsed)
  }

  const drawerWidth = isCollapsed ? 68 : 260

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          height: "100vh",
          zIndex: 10,
        }}
      >
        <Sidebar onCollapse={handleCollapse} />
      </Box>
      <Box
        component="main"
        sx={{
          marginLeft: `${drawerWidth}px`,
          width: `calc(100% - ${drawerWidth}px)`,
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          transition: "margin-left 0.3s ease-in-out, width 0.3s ease-in-out",
        }}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            width: "100%",
            zIndex: 9,
          }}
        >
          <Topbar isCollapsed={isCollapsed} />
        </Box>
        <Box
          sx={{
            padding: "20px",
            overflowY: "auto",
            height: "calc(100vh - 64px)",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default Layout

