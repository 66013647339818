import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Divider,
  Collapse,
  Paper,
  IconButton,
  Tooltip,
  Chip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/system';
import { tokens } from "../../../theme";
import { useFormContext } from 'react-hook-form';

export default function AdvancedOptionsStep() {
  const [expression, setExpression] = useState('');
  const [isGlobalExpanded, setGlobalExpanded] = useState(false);
  const [nearValue, setNearValue] = useState(100);
  const { setValue, watch } = useFormContext();
  const selectedIdentifiers = watch("selectedIdentifiers");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    const initialExpression = Object.values(selectedIdentifiers)
    .flat()
    .join(' AND ');
    setExpression(initialExpression);
    setValue('ruleExpression', initialExpression);
  }, [selectedIdentifiers, setValue]);

  const handleAddToExpression = (value) => {
    const newExpression = expression ? `${expression} AND ${value}` : value;
    setExpression(newExpression);
    setValue('ruleExpression', newExpression);
  };

  const handleOperatorClick = (operator) => {
    const newExpression = `${expression} ${operator} `;
    setExpression(newExpression);
    setValue('ruleExpression', newExpression);
  };

  const toggleGlobalIdentifiers = () => {
    setGlobalExpanded((prev) => !prev);
  };

  return (
    <Box className="flex flex-col gap-4 p-4 min-h-screen">
      <Box className="flex items-center mb-2">
        <Typography variant="subtitle1" className="font-bold text-gray-700">
          RULE EXPRESSION
        </Typography>
        <Tooltip title="This expression is initialized with your selected identifiers. You can modify it or add more operators.">
          <IconButton size="small" className="ml-2">
            <InfoIcon fontSize="small" className="text-blue-500" />
          </IconButton>
        </Tooltip>
      </Box>
      <TextField
        fullWidth
        multiline
        minRows={3}
        variant="outlined"
        value={expression}
        placeholder="Define your rule expression"
        onChange={(e) => {
          setExpression(e.target.value);
          setValue('ruleExpression', e.target.value);
        }}
        className="mb-6"
      />

      <Box className="flex flex-col md:flex-row gap-6">
        <Paper elevation={3} className="flex-1 p-4 bg-white rounded-lg shadow-md">
          <Typography variant="subtitle1" className="font-bold text-gray-700 mb-4">
            DATA IDENTIFIER
          </Typography>
          <div className="space-y-4">
            {Object.entries(selectedIdentifiers).map(([categoryId, identifiers]) => (
              <div key={categoryId} className="bg-gray-50 rounded-lg p-3">
                <Typography className="font-semibold text-gray-600 mb-2">
                  {getCategoryName(parseInt(categoryId))}
                </Typography>
                <div className="flex flex-wrap gap-2">
                  {identifiers.map((identifier) => (
                    <Chip
                      key={identifier}
                      label={identifier}
                      onClick={() => handleAddToExpression(identifier)}
                      className="bg-blue-100 text-blue-700 hover:bg-blue-200 cursor-pointer transition-colors duration-200"
                      deleteIcon={<AddIcon />}
                      onDelete={() => handleAddToExpression(identifier)}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </Paper>

        <Paper elevation={3} className="flex-1 p-4 bg-white rounded-lg shadow-md">
          <Typography variant="subtitle1" className="font-bold text-gray-700 mb-4">
            OPERATOR
          </Typography>
          <div className="grid grid-cols-2 gap-2">
            {['AND', 'OR', 'NOT', '(', ')'].map((operator) => (
              <Button
                key={operator}
                variant="outlined"
                onClick={() => handleOperatorClick(operator)}
                className="w-full py-2 border-blue-400 text-blue-600 hover:bg-blue-50 transition-colors duration-200"
              >
                {operator}
              </Button>
            ))}
            <Button
              variant="outlined"
              onClick={() => handleOperatorClick(`NEAR ${nearValue}`)}
              className="col-span-2 w-full py-2 border-blue-400 text-blue-600 hover:bg-blue-50 transition-colors duration-200 flex justify-between items-center"
            >
              <h1>NEAR</h1> &nbsp;&nbsp;
              <TextField
                size="small"
                variant="outlined"
                value={nearValue}
                onChange={(e) => setNearValue(parseInt(e.target.value) || 0)}
                className="w-20 ml-2"
                inputProps={{ type: 'number', min: 0, className: 'text-center' }}
              />
            </Button>
          </div>
        </Paper>
      </Box>

      <Box className="mt-6">
        <Divider />
        <Button
          endIcon={<ExpandMoreIcon />}
          onClick={toggleGlobalIdentifiers}
          className="w-full py-3 justify-between text-left normal-case"
        >
          <Typography variant="subtitle1" className="font-bold text-gray-700">
            Global Identifiers
          </Typography>
        </Button>
        <Collapse in={isGlobalExpanded}>
          <Box className="mt-4 p-4 bg-gray-50 rounded-lg">
            <Typography variant="body2" className="text-gray-600">
              No global identifiers available.
            </Typography>
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
}

const categories = [
  { id: 1, name: 'Personal Names' },
  { id: 2, name: 'Personal Numbers' },
  { id: 3, name: 'Financial Data' },
  { id: 4, name: 'Medical Data' },
  { id: 5, name: 'Postal Addresses' },
  { id: 6, name: 'Dates' },
  { id: 7, name: 'Biodata' },
  { id: 8, name: 'Computing' },
];

function getCategoryName(categoryId) {
  return categories.find(cat => cat.id === categoryId)?.name || 'Unknown Category';
}