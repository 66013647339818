import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, TextField, Typography } from '@mui/material';

const SetRule = () => {
  const { register, formState: { errors } } = useFormContext();

  return (
    <Box className="flex flex-col gap-4 p-2 min-h-screen">
      <Typography variant="h6">RULE NAME</Typography>
      <TextField
        {...register("profileName", { required: "Profile Name is required" })}
        placeholder="Set Rule Name"
        error={!!errors.profileName}
        helperText={errors.profileName?.message}
        variant="outlined"
        fullWidth
        required
      />
    </Box>
  );
};

export default SetRule;
