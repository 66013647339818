import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button, Typography, FormControlLabel, Switch, CircularProgress, Snackbar, Alert, Box } from '@mui/material';
import { styled } from '@mui/system';
import api from '../../api/api';
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";

const StyledSwitch = styled(Switch)(({ theme }) => {
  const colors = tokens(theme.palette.mode);
  return {
    '& .MuiSwitch-thumb': {
      backgroundColor: colors.primary[400],
    },
    '& .MuiSwitch-track': {
      backgroundColor: colors.grey[400],
    },
  };
});

export default function DomainTLSForm({ onAddTlsProfile, onEditTlsProfile, initialValues, refetch }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
    setError,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues || {
      domainName: '',
      tlsInspection: '',
    },
  });

  useEffect(() => {
    if (initialValues) {
      setValue('domainName', initialValues.name);
      setValue('tlsInspection', initialValues.tlsInspection);
    }
  }, [initialValues, setValue]);

  const domainRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i;

  const checkDomainNameUnique = async (domainName) => {
    try {
      const response = await api.get(`/tls-inspection/profiles?action=check-name&name=${encodeURIComponent(domainName)}`);
      return response.data.isUnique;
    } catch (error) {
      console.error("Error checking domain name uniqueness:", error);
      return false; // Treat errors as the name not being unique
    }
  };

  const onSubmit = async (data) => {
    console.log("Form submitted with data:", data); // Debugging

    setIsLoading(true);

    const trimmedDomainName = data.domainName.trim();
    try {
      // Check if the domain name has been modified
      if (!initialValues || trimmedDomainName !== initialValues.name) {
        // Check if the domain name is unique
        const isUnique = await checkDomainNameUnique(trimmedDomainName);
        if (!isUnique) {
          setError("domainName", {
            type: "manual",
            message: "A domain with this name already exists, please choose a different name.",
          });
          setIsLoading(false);
          return;
        }
      }

      const payload = {
        Attributes: {
          domainName: trimmedDomainName,
          tlsInspection: data.tlsInspection ? "enabled" : "disabled",
        },
      };

      console.log("Submitting data:", payload);
  
      if (initialValues) {
        console.log("Calling onEditTlsProfile with:", {
          id: initialValues.id,
          name: data.domainName,
          tlsInspection: data.tlsInspection,
        });
        // Update existing TLS profile
        await onEditTlsProfile({
          id: initialValues.id,
          name: data.domainName,
          tlsInspection: data.tlsInspection,
        });
      } else {
        console.log("Calling onAddTlsProfile with:", {
          domainName: trimmedDomainName,
          tlsInspection: data.tlsInspection,
        });
        // Create new TLS profile
        const response = await api.post('/tls-inspection/profiles?action=create', payload, { withCredentials: true });
        console.log("API response:", response);
        if (response.status === 201) {
          onAddTlsProfile({
            id: response.data.EntityId,
            name: response.data.Attributes.domainName,
            tlsInspection: response.data.Attributes.tlsInspection === "enabled",
          });
          reset(); // Clear the form
          setSnackbar({ open: true, message: 'TLS Inspection Profile created successfully!', severity: 'success' });
          refetch();
        }
      }
    } catch (error) {
      console.error('Error creating/updating TLS Inspection Profile:', error);
      setSnackbar({ open: true, message: 'Failed to create/update TLS Inspection Profile.', severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} className="max-w-md mx-auto space-y-6"
    sx={{backgroundColor: colors.primary[400], borderColor: colors.primary[500] }}
    >
      <Typography variant="h5" component="h2" className="text-center">
        Domain TLS Configuration
      </Typography>

      {/* Domain Name Input */}
      <Controller
        name="domainName"
        control={control}
        rules={{
          required: 'Domain name is required',
          pattern: {
            value: domainRegex,
            message: 'Please enter a valid domain name',
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Domain Name"
            variant="outlined"
            fullWidth
            error={!!errors.domainName}
            helperText={errors.domainName?.message}
          />
        )}
      />

      {/* TLS Inspection Toggle */}
      <Controller
        name="tlsInspection"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={<StyledSwitch {...field} checked={field.value} />}
            label="Enable TLS Inspection"
          />
        )}
      />

      {/* Submit Button */}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={!isValid || isLoading}
        startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
      >
        {isLoading ? <CircularProgress size={24} /> : initialValues ? 'Update TLS Profile' : 'Add TLS Profile'}
      </Button>

      {/* Snackbar Notification */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}