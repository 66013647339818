import React, { useState } from 'react';
import { useTheme } from '@mui/system';
import { tokens } from "../../theme"; // import your color tokens
import { Button, Select, MenuItem, Typography, Box, InputBase, Modal } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import DLPProfileForm from './DLPProfileForm';
import DLPProfileDrawer from './DLPProfileDrawer'

function DLP() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode); // get the appropriate theme colors

  // State to handle filter selections
  const [profileType, setProfileType] = useState('All Profile Types');
  const [financeType, setFinanceType] = useState('Finance');
  const [region, setRegion] = useState('All Regions');
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedDlpProfile, setSelectedDlpProfile] = useState(null); // Track selected profile


  // Sample data for DLP profiles
  const dlpProfiles = [
    { id: 1, name: 'Payment Card Industry Data Security Standard. PCI-DSS', type: 'predefined', lastEdit: '2024-09-15' },
    { id: 2, name: 'EU General Data Protection Regulation (GDPR)', type: 'predefined', lastEdit: '2024-09-15' },
    { id: 3, name: 'Gramm-Leach-Bliley Act (GLB Act or GLBA), 1999', type: 'predefined', lastEdit: '2024-09-15' },
    { id: 4, name: 'The Social Security Number Confidentiality Act, 2000', type: 'predefined', lastEdit: '2024-09-15' },
    { id: 5, name: 'US Financial Data', type: 'predefined', lastEdit: '2024-09-15' },
    { id: 6, name: 'US Personally Identifiable Information', type: 'predefined', lastEdit: '2024-09-15' },
  ];

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  // Define columns for DataGrid
  const columns = [
    { field: 'id', headerName: '#', width: 70 },
    {
      field: 'name',
      renderHeader: (params) => (
        <Typography variant="h6" component="div">
          Name
        </Typography>
      ),
      flex: 2,
      renderCell: (params) => (
        <Typography
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
            fontSize: '12px',
            marginTop: '15px',
          }}
          color={colors.blueAccent[400]}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'type',
      renderHeader: (params) => (
        <Typography variant="h6" component="div">
          Type
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'lastEdit',
      renderHeader: (params) => (
        <Typography variant="h6" component="div">
          Last Edit
        </Typography>
      ),
      flex: 1,
    },
  ];


  const handleRowClick = (params) => {
    if (params.field === "name") {
      setSelectedDlpProfile(params.row);
      setDrawerOpen(true);
    }
  };
  return (
    <div className="p-6">
      {/* Top Buttons */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Box display="flex" gap={2}>
          <Button
            variant="outlined"
            sx={{
              borderColor: '#1976D2',
              color: '#1976D2',
              borderRadius: '5px',
              padding: '6px 16px',
              textTransform: 'none',
              '&:hover': { borderColor: '#1565C0', color: '#1565C0' }
            }}
            onClick={handleOpen}
          >
            NEW PROFILE
          </Button>
          <Button
            variant="outlined"
            sx={{
              borderColor: '#1976D2',
              color: '#1976D2',
              borderRadius: '5px',
              padding: '6px 16px',
              textTransform: 'none',
              '&:hover': { borderColor: '#1565C0', color: '#1565C0' }
            }}
          >
            EDIT RULES
          </Button>
        </Box>
        <Button
          type="submit"
          color="secondary"
          variant="contained"
        >
          Apply Changes
        </Button>
      </Box>

      {/* Filters and Search */}
      <Box display="flex" justifyContent="space-between" gap={2} mb={4}>
        <Box display="flex" gap={2}>
          <Select
            value={profileType}
            onChange={(e) => setProfileType(e.target.value)}
            variant="outlined"
            sx={{ width: '200px', borderRadius: '5px', height: '40px' }}
          >
            <MenuItem value="All Profile Types">All Profile Types</MenuItem>
            <MenuItem value="Custom">Custom</MenuItem>
            <MenuItem value="Predefined">Predefined</MenuItem>
          </Select>

          <Select
            value={financeType}
            onChange={(e) => setFinanceType(e.target.value)}
            variant="outlined"
            sx={{ width: '200px', borderRadius: '5px', height: '40px' }}
          >
            <MenuItem value="Finance">Finance</MenuItem>
            <MenuItem value="Non-Finance">Non-Finance</MenuItem>
          </Select>

          <Select
            value={region}
            onChange={(e) => setRegion(e.target.value)}
            variant="outlined"
            sx={{ width: '200px', borderRadius: '5px', height: '40px' }}
          >
            <MenuItem value="All Regions">All Regions</MenuItem>
            <MenuItem value="US">US</MenuItem>
            <MenuItem value="EU">EU</MenuItem>
          </Select>
        </Box>

        {/* Search Field */}
        <Box display="flex" alignItems="center" className="p-1 rounded border" sx={{ borderRadius: '5px', borderColor: 'gray.300', width: '300px' }}>
          <SearchIcon />
          <InputBase
            placeholder="Search keywords"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            sx={{ ml: 1, flex: 1, height: '36px', padding: '6px' }}
          />
        </Box>
      </Box>
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={open} // controls whether the modal is open or not
        onClose={() => setOpen(false)} // closes the modal when clicking outside or pressing escape
      >
        <Box
  sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', sm: '700px' }, // Responsive width
    p: 4,
    maxHeight: '90vh', // Ensures it doesn't overflow vertically
  }}
>
  <DLPProfileForm onClose={handleClose} />
</Box>

      </Modal>
      {/* DataGrid Section */}
      <div className="mt-1" sx={{ height: 'calc(100vh - 100px)', width: '100%' }}>
        <DataGrid
          rows={dlpProfiles}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableRowSelectionOnClick
          disableColumnResize
          disableColumnFilter
          disableColumnMenu
          disableColumnSorting
          onCellClick={handleRowClick}
          style={{ height: `${window.innerHeight - 100}px` }}
          sx={{
            border: 1,
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#F0F0F0',
              borderBottom: '1px solid #E0E0E0',
              fontWeight: 'bold',
              fontSize: '14px',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
              fontSize: '14px',
              color: '#555555',
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
            },
            '& .MuiDataGrid-cell:hover': {
              color: '#1976D2',
            },
            '&, [class^=MuiDataGrid]': {
              borderLeft: 'none',
              borderRight: 'none',
              borderBottom: 'none'
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none', // Ensures there's no border when focus is within the cell
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none', // Removes the focus border from the header cells
            },
            '& .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none', // Removes the focus border within the header cells
            },
          }}
        />
      </div>
      <DLPProfileDrawer
        isOpen={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        selectedDlpProfile={selectedDlpProfile}
      />
    </div>
  );
}

export default DLP;