import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Checkbox,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Typography,
  Collapse,
  Chip,
  Tooltip,
} from '@mui/material';
import { Search, ExpandLess, ExpandMore, Close as CloseIcon } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';
import api from '../../../api/api';
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../../theme";

export const categories = [
  { id: 1, name: 'Personal Names', dataIdentifiers: ['First Name', 'Last Name', 'Full Name'] },
  { id: 2, name: 'Personal Numbers', dataIdentifiers: ['Social Security Number', 'Passport Number', 'National ID'] },
  { id: 3, name: 'Financial Data', dataIdentifiers: ['Credit Card Number', 'Bank Account Number', 'IBAN'] },
  { id: 4, name: 'Medical Data', dataIdentifiers: ['Patient Information', 'Medical Conditions', 'Medical Tests'] },
  { id: 5, name: 'Postal Addresses', dataIdentifiers: ['Street Address', 'Postal Code', 'City', 'State'] },
  { id: 6, name: 'Dates', dataIdentifiers: ['Date of Birth', 'Anniversary Date', 'Date of Joining'] },
  { id: 7, name: 'Biodata', dataIdentifiers: ['Height', 'Weight', 'Eye Color', 'Blood Type'] },
  { id: 8, name: 'Computing', dataIdentifiers: ['IP Address', 'MAC Address', 'Email Address'] },
];

const PredefinedStep = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [regexData, setRegexData] = useState([]);
  const [localSelectedIdentifiers, setLocalSelectedIdentifiers] = useState({});
  const { getValues, setValue, watch, trigger, formState: { errors } } = useFormContext();

  const formSelectedIdentifiers = watch('selectedIdentifiers');

  useEffect(() => {
    const fetchRegexData = async () => {
      try {
        const response = await api.get('/dlp/profiles?action=fetch-regex');
        const regexProfiles = response.data;
        const formattedRegexData = regexProfiles.flatMap((profile) =>
          profile.Attributes.dataIdentifiers.map((identifier) => ({
            name: identifier.dataIdentifierName,
            regexConditions: identifier.conditions,
          }))
        );
        setRegexData(formattedRegexData);
      } catch (error) {
        console.error('Error fetching regex data:', error);
      }
    };

    fetchRegexData();
  }, []);

  useEffect(() => {
    const formValues = getValues('selectedIdentifiers');

    // Handle both array and object formats
    const initialSelected = Array.isArray(formValues)
      ? convertArrayToObject(formValues)
      : formValues || {};

    // Immediately update the form with object format if we converted
    if (Array.isArray(formValues)) {
      setValue('selectedIdentifiers', initialSelected);
    }

    setLocalSelectedIdentifiers(initialSelected);
  }, [getValues, setValue]); // Add setValue to dependencies

  // Helper function to convert API array to category object
  const convertArrayToObject = (identifiersArray) => {
    return identifiersArray.reduce((acc, item) => {
      const category = categories.find(cat =>
        cat.dataIdentifiers.includes(item.identifierName)
      );
      if (category) {
        const categoryIdStr = category.id.toString(); // Convert to string
        acc[categoryIdStr] = acc[categoryIdStr] || [];
        acc[categoryIdStr].push(item.identifierName);
      }
      return acc;
    }, {});
  };

  useEffect(() => {
    if (Array.isArray(formSelectedIdentifiers)) {
      console.log('Form selected identifiers:', formSelectedIdentifiers);
      const transformedIdentifiers = formSelectedIdentifiers.reduce((acc, item) => {
        const category = categories.find(cat => cat.dataIdentifiers.includes(item.identifierName));
        if (category) {
          if (!acc[category.id]) {
            acc[category.id] = [];
          }
          acc[category.id].push(item.identifierName);
        }
        return acc;
      }, {});
      setLocalSelectedIdentifiers(transformedIdentifiers || {});
    }
  }, [formSelectedIdentifiers]);

  const handleCategoryToggle = (categoryId) => {
    setExpandedCategory((prev) => (prev === categoryId ? null : categoryId));
  };

  const handleDataIdentifierToggle = (categoryId, identifier) => {
    const categoryIdStr = categoryId.toString(); // Convert to string
    const updatedIdentifiers = {
      ...localSelectedIdentifiers,
      [categoryIdStr]: Array.isArray(localSelectedIdentifiers[categoryIdStr])
        ? localSelectedIdentifiers[categoryIdStr].includes(identifier)
          ? localSelectedIdentifiers[categoryIdStr].filter((id) => id !== identifier)
          : [...localSelectedIdentifiers[categoryIdStr], identifier]
        : [identifier],
    };

    // Ensure `regexData` is an array before using `.find`
    const regexConditions = Object.values(updatedIdentifiers).flat().reduce((acc, id) => {
      const regexForId = Array.isArray(regexData)
        ? regexData.find((data) => data.name === id)?.regexConditions || []
        : [];
      return [...acc, ...regexForId.map(condition => ({ identifier: id, condition }))];
    }, []);

    setLocalSelectedIdentifiers(updatedIdentifiers);
    setValue('selectedIdentifiers', updatedIdentifiers, { shouldValidate: true });
    setValue('regexConditions', regexConditions, { shouldValidate: true });
    trigger('selectedIdentifiers');
  };

  const handleRemoveIdentifier = (categoryId, identifier) => {
    // 1) Remove from localSelectedIdentifiers (existing code)
  const categoryIdStr = String(categoryId);
  const updatedIdentifiers = { ...localSelectedIdentifiers };

  if (updatedIdentifiers[categoryIdStr]) {
    updatedIdentifiers[categoryIdStr] = updatedIdentifiers[categoryIdStr].filter(
      (id) => id !== identifier
    );
    if (updatedIdentifiers[categoryIdStr].length === 0) {
      delete updatedIdentifiers[categoryIdStr];
    }
  }

  // 2) Also remove from regexConditions (existing code)
  const newRegexConditions = (getValues('regexConditions') || [])
    .filter((cond) => cond.identifier !== identifier);

  // 3) NEW: Remove from the selectedCustomIdentifiers array too (if present)
  const currentCustom = getValues('selectedCustomIdentifiers') || [];
  const updatedCustom = currentCustom.filter(
    (item) => item.identifierName !== identifier
  );

  // 4) Update all form values
  setLocalSelectedIdentifiers(updatedIdentifiers);
  setValue('selectedIdentifiers', updatedIdentifiers, { shouldValidate: true });
  setValue('regexConditions', newRegexConditions, { shouldValidate: true });
  setValue('selectedCustomIdentifiers', updatedCustom, { shouldValidate: true });
  
  trigger('selectedIdentifiers');
};

  const filteredCategories = useMemo(
    () =>
      categories.filter((category) =>
        searchTerm
          ? category.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          category.dataIdentifiers.some((id) =>
            id.toLowerCase().includes(searchTerm.toLowerCase())
          )
          : true
      ),
    [searchTerm]
  );

  console.log('Local selected identifiers before rendering:', localSelectedIdentifiers);

  return (
    <Box className="flex flex-col gap-4 p-4 min-h-screen">
      <Box className="flex justify-between items-center">
        <TextField
          placeholder="Search categories"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box className="flex gap-4">
        <Paper className="w-1/3 p-4" elevation={3} sx={{ backgroundColor: colors.primary[400] }}>
          <Typography variant="h6" className="mb-4">CATEGORIES</Typography>
          <List>
            {filteredCategories.map((category) => (
              <React.Fragment key={category.id}>
                <ListItem
                  button
                  onClick={() => handleCategoryToggle(category.id)}
                  className="rounded"
                >
                  <ListItemIcon>
                    {expandedCategory === category.id ? <ExpandLess /> : <ExpandMore />}
                  </ListItemIcon>
                  <ListItemText primary={category.name} />
                </ListItem>
                <Collapse in={expandedCategory === category.id} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {category.dataIdentifiers.map((identifier) => (
                      <ListItem key={identifier} className="pl-8">
                        <Checkbox
                          checked={
                            localSelectedIdentifiers[category.id]?.includes(identifier) || false
                          }
                          onChange={() => handleDataIdentifierToggle(category.id, identifier)}
                        />
                        <ListItemText primary={identifier} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
        </Paper>

        <Paper className="w-2/3 p-4" elevation={3} sx={{ backgroundColor: colors.primary[400] }}>
          <Typography variant="h6" className="mb-4">Selected Data Identifiers</Typography>
          {errors.selectedIdentifiers && (
            <Typography color="error" variant="body2">
              {errors.selectedIdentifiers.message}
            </Typography>
          )}
          {Object.keys(localSelectedIdentifiers).length === 0 ? (
            <Box className="text-center p-4 rounded">
              <Typography variant="body1">No identifiers selected.</Typography>
              <Typography variant="body2">Select identifiers from the categories on the left to view them here.</Typography>
            </Box>
          ) : (
            <Box className="space-y-4">
              {Object.entries(localSelectedIdentifiers).map(([categoryId, identifiers]) => {
                const category = categories.find(cat => cat.id === parseInt(categoryId));
                return identifiers.length > 0 && (
                  <Box key={categoryId} className="p-4 rounded shadow-sm">
                    <Typography variant="subtitle1" className="font-bold mb-2">{category?.name}</Typography>
                    <Box className="flex flex-wrap gap-2">
                      {identifiers.map((identifier) => (
                        <Tooltip title="Click to remove" arrow key={identifier}>
                          <Chip
                            label={identifier}
                            onDelete={() => handleRemoveIdentifier(categoryId, identifier)} // Pass categoryId as string
                            deleteIcon={<CloseIcon />}
                            className="bg-blue-100 text-blue-800"
                          />
                        </Tooltip>
                      ))}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default PredefinedStep;