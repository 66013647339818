import React, { useState, useEffect, useMemo, useCallback } from "react"
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  StepConnector,
  Typography,
  Box,
  IconButton,
  Container,
  Divider,
  Tooltip,
  CircularProgress
} from "@mui/material"
import { useForm, FormProvider } from "react-hook-form"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { useTheme } from "@mui/system"
import { tokens } from "../../theme"
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from "@mui/icons-material/Close"
import FileAttributeStep from "./steps/FileAttributesStep"
import SetProfileStep from "./steps/SetProfileStep"
import { ListChecksIcon as ListCheck, File } from 'lucide-react'
import api from "../../api/api"
import PropTypes from 'prop-types';


const CustomConnector = React.memo(() => (
  <StepConnector
    sx={{
      "& .MuiStepConnector-line": {
        borderColor: "#e0e0e0",
        borderTopWidth: 3,
      },
      "&.Mui-active .MuiStepConnector-line": {
        borderColor: "#3f51b5",
      },
      "&.Mui-completed .MuiStepConnector-line": {
        borderColor: "#4caf50",
      },
    }}
  />
))

const StepIcon = React.memo(({ icon, active, completed }) => {
  return completed ? (
    <CheckCircleIcon color="success" fontSize="large" />
  ) : (
    React.cloneElement(icon, {
      fontSize: "large",
      sx: {
        color: active ? "#3f51b5" : "#9e9e9e",
        transition: "transform 0.3s",
        transform: active ? "scale(1.1)" : "scale(1)",
      },
    })
  )
})

const stepIcons = [<ListCheck key="listCheck" />, <File key="file" />]

FileProfileForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onProfileCreated: PropTypes.func.isRequired,
  selectedFileProfile: PropTypes.object,
}

const validationSchema = yup.object().shape({
  profile_name: yup.string().required("Profile name is required."),
  file_names: yup.string().required("File names are required."),
  file_types: yup.array().of(yup.string()).min(1, "Select at least one file type that corresponds to the file names."),
  file_hash: yup.string(),
  object_id: yup.string(),
  file_size_operator: yup.string().required("Operator is required."),
  file_size_value: yup
    .number()
    .typeError("File size must be a number.")
    .positive("File size must be greater than 0.")
    .required("File size is required."),
  file_size_unit: yup.string().required("Unit is required."),
  protected_encrypted: yup.string(),
});


export default function FileProfileForm({ onClose, onProfileCreated, selectedFileProfile }) {
  const steps = ["FILE ATTRIBUTES", "SET PROFILE"]
  const [activeStep, setActiveStep] = useState(0)
  const [isSaving, setIsSaving] = useState(false)

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      profile_name: '',
      file_names: '',
      file_types: [],
      file_hash: '',
      object_id: '',
      file_size_operator: '',
      file_size_value: '',
      file_size_unit: '',
      protected_encrypted: '',
    },
  })

  const { reset, handleSubmit, trigger } = methods

  useEffect(() => {
    if (selectedFileProfile) {
      reset({
        profile_name: selectedFileProfile.profile_name || '',
        file_names: selectedFileProfile.file_names?.join('\n') || '',
        file_types: selectedFileProfile.file_types || [],
        file_hash: selectedFileProfile.file_hash || '',
        object_id: selectedFileProfile.object_id || '',
        file_size_operator: selectedFileProfile.file_size?.operator || '',
        file_size_value: selectedFileProfile.file_size?.value || '',
        file_size_unit: selectedFileProfile.file_size?.unit || '',
        protected_encrypted: selectedFileProfile.protected_encrypted || '',
      })
    }
  }, [selectedFileProfile, reset])

  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const isLastStep = steps.length === activeStep + 1;

  const handleNext = useCallback(async (e) => {
    if (e) e.preventDefault();
    let isStepValid;
  
    if (activeStep === 0) {
      isStepValid = await trigger(["file_names", "file_types", "file_size_operator", "file_size_value", "file_size_unit"]);
    } else if (activeStep === 1) {
      isStepValid = await trigger("profile_name");
    }
  
    if (isStepValid) {
      setActiveStep((prev) => prev + 1);
    }
  }, [trigger, activeStep]);
  

  const handleBack = useCallback(() => {
    setActiveStep((prev) => prev - 1)
  }, [])

  const onSubmit = useCallback(async (data) => {
    if (!isLastStep) {
      handleNext()
      return
    }

    console.log("form submitted at step", activeStep)

    setIsSaving(true)

    const transformedData = {
      Attributes: {
        profile_name: data.profile_name,
        file_names: data.file_names.split('\n').map((name) => name.trim()).filter(Boolean),
        file_types: data.file_types,
        file_hash: data.file_hash,
        object_id: data.object_id,
        file_size: {
          operator: data.file_size_operator,
          value: parseInt(data.file_size_value, 10),
          unit: data.file_size_unit,
        },
        protected_encrypted: data.protected_encrypted,
      },
    }
    console.log("Transformed Data:", transformedData);

    try {
      const endpoint = selectedFileProfile
        ? `/file/profiles?action=update&EntityId=${selectedFileProfile.id}`
        : '/file/profiles?action=create'

      const response = await api.post(endpoint, transformedData, { withCredentials: true })

      if ([200, 201].includes(response.status)) {
        console.log(`${selectedFileProfile ? 'Updated' : 'Created'} file profile successfully`, response.data)
        if (onProfileCreated) {
          onProfileCreated(response.data)
          onClose()
        }
      } else {
        console.error(`Failed to ${selectedFileProfile ? 'update' : 'create'} file profile:`, response.data)
      }
    } catch (error) {
      console.error(`Error ${selectedFileProfile ? 'updating' : 'creating'} file profile:`, error)
    } finally {
      setIsSaving(false)
    }
  }, [isLastStep, handleNext, selectedFileProfile, onProfileCreated, onClose, activeStep])

  const renderStepContent = useMemo(() => (step) => {
    switch (step) {
      case 0:
        return <FileAttributeStep />
      case 1:
        return <SetProfileStep />
      default:
        return null
    }
  }, [])

  return (
    <Box className="container py-10">
      <Container
        sx={{
          backgroundColor: colors.primary[400],
          maxWidth: "90vw",
          width: "90vw",
          maxHeight: "80vh",
          overflow: "hidden",
          marginLeft: "-250px",
          marginRight: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "40px",
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            sx={{
              fontWeight: "bold",
              color: colors.blueAccent[500],
              maxWidth: { xs: "90%", sm: "70%", md: "700px" },
              textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
            }}
          >
            {selectedFileProfile ? 'Edit File Profile' : 'Create New File Profile'}
          </Typography>
          <Tooltip title="Close the form" arrow>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<CustomConnector />}
          sx={{
            width: "100%",
            maxWidth: "90vw",
            marginBottom: 2,
            marginRight: 3,
            flexWrap: { xs: "wrap", sm: "nowrap" },
            "& .MuiStep-root": {
              flex: 1,
              minWidth: "100px",
            },
          }}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                StepIconComponent={() =>
                  <StepIcon
                    icon={stepIcons[index]}
                    active={activeStep === index}
                    completed={activeStep > index}
                  />
                }
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: "medium",
                    color: activeStep === index ? colors.blueAccent[400] : colors.grey[500],
                    transition: "color 0.3s ease",
                  }}
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ overflowY: 'auto', maxHeight: '60vh', pr: 1 }}>
          <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
          {renderStepContent(activeStep)}

              <Box
                sx={{
                  position: "sticky",
                  bottom: 3,
                  display: "flex",
                  justifyContent: "space-between",
                  py: 2,
                  backgroundColor: "#f5f5f5",
                }}
              >
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  variant="outlined"
                  color="secondary"
                  sx={{
                    borderColor: activeStep === 0 ? colors.grey[500] : colors.blueAccent[500],
                    color: activeStep === 0 ? colors.grey[500] : colors.blueAccent[500],
                    "&:hover": {
                      borderColor: colors.blueAccent[400],
                    },
                    transition: "all 0.3s",
                  }}
                >
                  Back
                </Button>

                <Button
                  type={isLastStep ? "submit" : "button"}
                  onClick={!isLastStep ? handleNext : undefined}
                  variant="contained"
                  disabled={isSaving}
                  sx={{
                    backgroundColor: colors.blueAccent[400],
                    color: colors.grey[100],
                    "&:hover": {
                      backgroundColor: colors.blueAccent[300],
                    },
                    transition: "all 0.3s",
                  }}
                >
                    {console.log("Button Type:", isLastStep ? "submit" : "button")}
                  {isSaving ? <CircularProgress size={24} /> : (isLastStep ? (selectedFileProfile ? "Update Profile" : "Save Profile") : "Next")}
                </Button>
              </Box>
            </form>
          </FormProvider>
        </Box>
        <Divider />
      </Container>
    </Box>
  )
}