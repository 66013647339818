import React, { useState, useEffect } from "react";
import { CircularProgress } from '@mui/material/';
import { Box, TextField, MenuItem, Button, Typography } from '@mui/material'; // Use Material UI components for form styling

const PolicyForm = ({ onSubmit, initialData }) => {
    const [formData, setFormData] = useState({
        policyName: "",
        action: "ALLOW",
        description: "N/A",
        configuration: {}, // Assuming you're storing some JSON configuration here, but you can use any data structure you want
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (initialData) {
            setFormData({
                policyName: initialData.PolicyName,
                action: initialData.Action,
                description: initialData.Description,
                configuration: initialData.Configuration,
            });
        }
    }, [initialData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true); // Set loading state when form is submitted
        try {
            await onSubmit({
                policyName: formData.policyName,
                action: formData.action,
                description: formData.description,
                configuration: formData.configuration,
            });
        } finally {
            setIsLoading(false); // Reset loading state once submission is done
        }
    };

    const actionOptions = [
        { value: "ALLOW", label: "ALLOW" },
        { value: "BLOCK", label: "BLOCK" },
        { value: "ISOLATE", label: "ISOLATE" },
        { value: "WHITELIST", label: "WHITELIST" },
        { value: "ALERT", label: "ALERT" },
        { value: "USER ALERT", label: "USER ALERT" },
    ];

    return (
        <Box component="form" onSubmit={handleFormSubmit} className="container mx-auto py-10">
            <Typography variant="h5" component="h1" gutterBottom>
                {initialData ? "Edit Policy" : "Add Your New Policy Here"}
            </Typography>

            <TextField
                fullWidth
                label="Policy Name"
                name="policyName"
                variant="outlined"
                value={formData.policyName}
                onChange={handleChange}
                required
                margin="normal"
                placeholder="e.g., Block all traffic"
            />

            <TextField
                fullWidth
                select
                label="Action"
                name="action"
                value={formData.action}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
            >
                {actionOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>

            

            <TextField
                fullWidth
                label="Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                multiline
                rows={3}
                variant="outlined"
                margin="normal"
                placeholder="Enter a description"
            />

            {/* Submit button */}
            <Box display="flex" justifyContent="center" marginTop={4}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={isLoading}  // Disable button while loading
                    sx={{ width: '150px', position: 'relative' }}
                >
                    {isLoading ? (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: 'white',
                                position: 'absolute',
                            }}
                        />
                    ) : "Save"}
                </Button>
            </Box>
        </Box>
    );
};

export default PolicyForm;
