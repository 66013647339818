import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Collapse,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/system';
import { tokens } from "../../../theme"; // Adjust the path as necessary

const dataIdentifiers = [
  { id: 'P0', name: 'Blood Tests', type: 'Common' },
  { id: 'P1', name: 'Lab Tests', type: 'Common' },
];

export default function AdvancedOptionsStep() {
  const [expression, setExpression] = useState('');
  const [isGlobalExpanded, setGlobalExpanded] = useState(false);
  const [nearValue, setNearValue] = useState(100);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleAddToExpression = (value) => {
    setExpression((prev) => `${prev} ${value}`);
  };

  const toggleGlobalIdentifiers = () => {
    setGlobalExpanded((prev) => !prev);
  };

  return (
    <Box className="flex flex-col gap-4 p-4 min-h-screen">
      <Typography variant="subtitle1" gutterBottom>
        RULE EXPRESSION
      </Typography>
      <TextField
        fullWidth
        multiline
        minRows={2}
        variant="outlined"
        value={expression}
        placeholder="Define your rule expression"
        onChange={(e) => setExpression(e.target.value)}
        sx={{ mb: 3 }}
      />

      <Box sx={{ display: 'flex', gap: 3 }}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1" gutterBottom>
            DATA IDENTIFIER
          </Typography>
          <List sx={{ border: '1px solid #ddd', borderRadius: 1, p: 0 }}>
            {dataIdentifiers.map((identifier) => (
              <ListItem
                button
                key={identifier.id}
                onClick={() => handleAddToExpression(identifier.id)}
                sx={{ borderBottom: '1px solid #eee' }}
              >
                <ListItemText primary={`(${identifier.id}) - ${identifier.name}`} secondary={`[${identifier.type}]`} />
              </ListItem>
            ))}
          </List>
        </Box>

        {/* Operators with Custom Border Color */}
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1" gutterBottom>
            OPERATOR
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {['AND', 'OR', 'NOT', '(', ')'].map((operator) => (
              <Button
                key={operator}
                variant="outlined"
                onClick={() => handleAddToExpression(operator)}
                sx={{
                  flex: '1 1 40%',
                  borderColor: colors.blueAccent[400], // Set border color to blueAccent[400]
                  color: colors.blueAccent[400], // Optionally set text color to match
                  '&:hover': {
                    borderColor: colors.blueAccent[300], // Adjust hover color
                  },
                }}
              >
                {operator}
              </Button>
            ))}
            <Button
              variant="outlined"
              onClick={() => handleAddToExpression(`NEAR ${nearValue}`)}
              sx={{
                flex: '1 1 40%',
                display: 'flex',
                justifyContent: 'space-between',
                borderColor: colors.blueAccent[400],
                color: colors.blueAccent[400],
                '&:hover': {
                  borderColor: colors.blueAccent[300],
                },
              }}
            >
              NEAR
              <TextField
                size="small"
                variant="outlined"
                value={nearValue}
                onChange={(e) => setNearValue(e.target.value)}
                sx={{ width: '70px', ml: 1 }}
                inputProps={{ type: 'number', min: 1 }}
              />
            </Button>
          </Box>
        </Box>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Divider />
        <Button
          endIcon={<ExpandMoreIcon />}
          onClick={toggleGlobalIdentifiers}
          sx={{ textTransform: 'none', justifyContent: 'space-between', width: '100%' }}
        >
          <Typography variant="subtitle1">Global Identifiers</Typography>
        </Button>
        <Collapse in={isGlobalExpanded}>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" color="textSecondary">
              {/* Global identifiers content */}
              No global identifiers available.
            </Typography>
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
}
