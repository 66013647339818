import React from 'react';
import {
  Drawer,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  Box,
  Chip,
} from '@mui/material';
import {
  ChevronRight,
  Close,
  Security,
  AccessTime,
  Category,
  Info,
  Email,
  CloudUpload,
  Language,
} from '@mui/icons-material';
import { useTheme } from '@mui/system';
import { tokens } from '../../theme';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

function DLPProfileDrawer({ isOpen, onClose, selectedDlpProfile }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const {
    name = 'Default Profile',
    type = 'Custom',
    description = 'This profile helps prevent sensitive data from leaving the organization.',
    riskLevel = 'High',
    lastEdited = 'N/A',
    monitoredChannels = ['Email', 'Cloud Storage', 'Web'],
  } = selectedDlpProfile || {};

  const formattedLastEdited = lastEdited !== 'N/A' ? format(new Date(lastEdited), 'MMMM d, yyyy') : lastEdited;

  const getChannelIcon = (channel) => {
    switch (channel) {
      case 'Email':
        return <Email fontSize="small" />;
      case 'Cloud Storage':
        return <CloudUpload fontSize="small" />;
      case 'Web':
        return <Language fontSize="small" />;
      default:
        return null;
    }
  };

  const handleConfigureClick = () => {
    // Navigate to the configuration page for the selected profile
    navigate('/dashboard/dlp/profile/configure', { state: { profile: selectedDlpProfile } });
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <Box className="w-80 p-6">
        <Box className="flex justify-between items-center mb-4">
          <Typography variant="h6" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', color: colors.blueAccent[500] }}>
            <Security sx={{ marginRight: 1 }} />
            {name}
          </Typography>
          <IconButton onClick={onClose} size="small" sx={{ color: colors.grey[100] }}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
        <Box className="text-center">
          <List disablePadding>
            <ListItem>
              <ListItemText primary="Profile Type" secondary={type} primaryTypographyProps={{ fontWeight: 'bold' }} secondaryTypographyProps={{ color: colors.grey[200] }} />
              <Category sx={{ color: colors.grey[500] }} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Last Edited" secondary={formattedLastEdited} primaryTypographyProps={{ fontWeight: 'bold' }} secondaryTypographyProps={{ color: colors.grey[200] }} />
              <AccessTime sx={{ color: colors.grey[500] }} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Description" secondary={description} primaryTypographyProps={{ fontWeight: 'bold' }} secondaryTypographyProps={{ color: colors.grey[200] }} />
              <Info sx={{ color: colors.grey[500] }} />
            </ListItem>
          </List>
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1, color: colors.primary[100] }}>Risk Level</Typography>
          <Chip label={riskLevel} color={riskLevel === 'High' ? 'error' : riskLevel === 'Medium' ? 'warning' : 'success'} size="small" sx={{ mb: 2 }} />
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1, color: colors.primary[100] }}>Monitored Channels</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
            {monitoredChannels.map((channel) => (
              <Chip key={channel} label={channel} icon={getChannelIcon(channel)} variant="outlined" size="small" />
            ))}
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Button 
          variant="contained" 
          endIcon={<ChevronRight />} 
          fullWidth 
          onClick={handleConfigureClick}
          sx={{ backgroundColor: colors.blueAccent[600], '&:hover': { backgroundColor: colors.blueAccent[400] } }}>Configure</Button>
        </Box>
      </Box>
    </Drawer>
  );
}

export default DLPProfileDrawer;