import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Search, ChevronRight } from '@mui/icons-material';
import { useTheme } from '@mui/system';
import { tokens } from "../../../theme"; 

// Mock data for categories
const categories = [
  { id: 1, name: 'Personal Names' },
  { id: 2, name: 'Personal Numbers' },
  { id: 3, name: 'Financial Data' },
  { id: 4, name: 'Medical Data' },
  { id: 5, name: 'Postal Addresses' },
  { id: 6, name: 'Dates' },
  { id: 7, name: 'Biodata' },
  { id: 8, name: 'Computing' },
];

const PredefinedStep = () => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const theme = useTheme();
  const colors = tokens(theme.palette.mode); // get the appropriate theme colors

  const handleCategoryToggle = (categoryId) => {
    setSelectedCategories((prev) =>
      prev.includes(categoryId)
        ? prev.filter((id) => id !== categoryId)
        : [...prev, categoryId]
    );
  };

  return (
    <Box className="flex flex-col gap-4 p-4 min-h-screen">
      {/* Search Bar */}
      <Box className="flex justify-between items-center">
        <TextField
          placeholder="Search"
          variant="outlined"
          size="small"
          className="w-1/3"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <FormControlLabel
          control={<Checkbox />}
          label={`Show Selected Only (${selectedCategories.length})`}
        />
      </Box>

      <Box className="flex gap-4">
        {/* Categories Section (Left Panel) */}
        <Paper className="w-1/4 p-4">
          <Typography variant="h6" className="mb-4">CATEGORIES</Typography>
          <List>
            {categories.map((category) => (
              <ListItem
                key={category.id}
                button
                onClick={() => handleCategoryToggle(category.id)}
                selected={selectedCategories.includes(category.id)}
                className="rounded"
              >
                <ListItemIcon>
                  <ChevronRight />
                </ListItemIcon>
                <ListItemText primary={category.name} />
              </ListItem>
            ))}
          </List>
        </Paper>

        {/* Data Identifiers (Central Panel) */}
        <Paper className="w-2/4 p-4 flex items-center justify-center">
          {selectedCategories.length === 0 ? (
            <Box className="text-center">
              <Typography variant="body1">No category selected.</Typography>
              <Typography variant="body2">Select a category from the left.</Typography>
            </Box>
          ) : (
            <Typography>Selected categories: {selectedCategories.join(', ')}</Typography>
          )}
        </Paper>

        {/* Overview Section (Right Panel) */}
        <Paper className="w-1/4 p-4">
          <Typography variant="h6" className="mb-4">OVERVIEW</Typography>
          <Typography variant="subtitle1" className="font-bold">Severity Threshold</Typography>
          <List>
            <ListItem>
              <ListItemText primary="Record" secondary="Unique Count Off" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Trigger action at Low" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Low: 5" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Medium: 25" />
            </ListItem>
            <ListItem>
              <ListItemText primary="High: 100" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Critical: 1000" />
            </ListItem>
          </List>
        </Paper>
      </Box>
    </Box>
  );
};

export default PredefinedStep;
