export function isValidRegex(pattern) {
    try {
      new RegExp(pattern);
      return true;
    } catch (e) {
      return false;
    }
  }
  
  export function testRegex(pattern, testString) {
    try {
      const regex = new RegExp(pattern);
      return regex.test(testString);
    } catch (e) {
      return false;
    }
  }
  
  export function isValidKeyword(keyword) {
    // Add your keyword validation logic here
    // For example, ensure it's not empty and contains only alphanumeric characters and underscores
    return /^[a-zA-Z0-9_]+$/.test(keyword);
  }
  
  export function testKeyword(keyword, testString) {
    return testString.includes(keyword);
  }
  
  