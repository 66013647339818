import React, { useState, useEffect } from "react";
import { Box, TextField, MenuItem, Button, Typography, CircularProgress } from '@mui/material'; // Use Material UI components for form styling
import { useForm, Controller, FormProvider } from "react-hook-form";
import api from '../../api/api';

const PolicyForm = ({ onSubmit, selectedPolicy }) => {
    const methods = useForm({
        defaultValues: {
            policyName: "",
            action: "ALLOW",
            description: "N/A",
            condition: {},
        },
    });

    const { reset, handleSubmit, control, formState: { errors, isValid } } = methods;
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (selectedPolicy) {
            reset({
                policyName: selectedPolicy.PolicyName,
                action: selectedPolicy.action,
                description: selectedPolicy.description,
                condition: selectedPolicy.Conditions,
            });
        }
    }, [selectedPolicy, reset]);

    const handleFormSubmit = async (data) => {
        setIsLoading(true); // Set loading state when form is submitted

        // Exclude conditions from the payload when updating an existing policy
        const transformedData = {
            Attributes: {
                policyName: data.policyName,
                action: data.action,
                description: data.description,
                ...(selectedPolicy ? {} : { conditions: {} }), // Include emtoy conditions only for new policies
            },
        };
        console.log("Transformed Data:", transformedData);

        try {
            const endpoint = selectedPolicy
                ? '/api/policy?action=update&EntityId=' + selectedPolicy.id
                : '/api/policy?action=create';

            const response = await api.post(endpoint, transformedData, { withCredentials: true });

            if (response.status === 201 || response.status === 200) {
                console.log('Policy created/updated:', response.data);
                onSubmit(); // Call the onSubmit callback to refresh the list or perform other actions
            } else {
                console.log('Error creating/updating policy:', response.data);
            }
        } catch (error) {
            console.error('Error creating/updating policy:', error);
        } finally {
            setIsLoading(false); // Reset loading state once submission is done
        }
    };

    const actionOptions = [
        { value: "ALLOW", label: "ALLOW" },
        { value: "BLOCK", label: "BLOCK" },
        { value: "ISOLATE", label: "ISOLATE" },
        { value: "WHITELIST", label: "WHITELIST" },
        { value: "ALERT", label: "ALERT" },
        { value: "USER ALERT", label: "USER ALERT" },
    ];

    return (
        <FormProvider {...methods}>
            <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
                <Typography variant="h5" component="h1" gutterBottom>
                    {selectedPolicy ? "Edit Policy" : "Add Your New Policy Here"}
                </Typography>

                <Controller
                    name="policyName"
                    control={control}
                    rules={{ required: "Policy name is required" }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            label="Policy Name"
                            variant="outlined"
                            margin="normal"
                            placeholder="e.g., Block all traffic"
                            error={!!errors.policyName}
                            helperText={errors.policyName?.message}
                        />
                    )}
                />

                <Controller
                    name="action"
                    control={control}
                    rules={{ required: "Action is required" }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            select
                            label="Action"
                            variant="outlined"
                            margin="normal"
                            error={!!errors.action}
                            helperText={errors.action?.message}
                        >
                            {actionOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                />

                <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            label="Description"
                            multiline
                            rows={3}
                            variant="outlined"
                            margin="normal"
                            placeholder="Enter a description"
                        />
                    )}
                />

                {/* Submit button */}
                <Box display="flex" justifyContent="center" marginTop={4}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={isLoading || !isValid}  // Disable button while loading or if form is not valid
                        sx={{ width: '150px', position: 'relative' }}
                    >
                        {isLoading ? (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: 'white',
                                    position: 'absolute',
                                }}
                            />
                        ) : "Save"}
                    </Button>
                </Box>
            </Box>
        </FormProvider>
    );
};

export default PolicyForm;