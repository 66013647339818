import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Paper,
} from '@mui/material';
import { useTheme } from '@mui/system';
import { tokens } from "../../../theme";

const scanOptions = [
  { value: 'email', label: 'Email' },
  { value: 'smtp', label: 'SMTP' },
  { value: 'http', label: 'HTTP' },
  { value: 'ftp', label: 'FTP' },
  { value: 'database', label: 'Database' },
];

const ContentStep = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { setValue, control, formState: { errors } } = useFormContext();
  const selectedOptions = useWatch({ control, name: 'scanOptions' }) || {};

  const handleOptionChange = (event) => {
    const updatedOptions = {
      ...selectedOptions,
      [event.target.name]: event.target.checked,
    };
    setValue('scanOptions', updatedOptions, { shouldValidate: true });
  };

  return (
    <Box className="flex flex-col gap-4 p-4 min-h-screen">
      <Typography variant="h6" className="mb-4 font-bold">
        Select Scan Options
      </Typography>
      <Paper elevation={3} className="p-4" sx={{ backgroundColor: colors.primary[400] }}>
        <FormGroup>
          {scanOptions.map((option) => (
            <FormControlLabel
              key={option.value}
              control={
                <Checkbox
                  checked={selectedOptions[option.value] || false}
                  onChange={handleOptionChange}
                  name={option.value}
                  color="primary"
                  className="hover:bg-blue-100 rounded transition-colors duration-150"
                />
              }
              label={option.label}
              className="mb-2 font-medium"
            />
          ))}
        </FormGroup>
        {errors.scanOptions?.message && ( // Ensure safe access
          <Typography color="error" variant="body2">
            {errors.scanOptions.message}
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default ContentStep;
