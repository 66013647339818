"use client"

import { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
  IconButton,
  Typography,
  Avatar,
  Tooltip,
  Menu,
  MenuItem,
  Badge,
  useTheme,
  alpha,
} from "@mui/material"

// MUI Icons
import MenuIcon from "@mui/icons-material/Menu"
import HomeIcon from "@mui/icons-material/Home"
import PeopleAltIcon from "@mui/icons-material/PeopleAlt"
import PolicyIcon from "@mui/icons-material/Policy"
import ShieldIcon from "@mui/icons-material/Shield"
import SecurityIcon from "@mui/icons-material/Security"
import HttpsIcon from "@mui/icons-material/Https"
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile"
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck"
import DomainIcon from "@mui/icons-material/Domain"
import DevicesIcon from "@mui/icons-material/Devices"
import StorageIcon from "@mui/icons-material/Storage"
import ShowChartIcon from "@mui/icons-material/ShowChart"
import SettingsIcon from "@mui/icons-material/Settings"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import LogoutIcon from "@mui/icons-material/Logout"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import { tokens } from "../../theme"

const drawerWidth = 260
const collapsedDrawerWidth = 68

const Sidebar = ({ onCollapse }) => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const location = useLocation()
  const [selected, setSelected] = useState("Dashboard")
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [policiesOpen, setPoliciesOpen] = useState(true)
  const [profilesOpen, setProfilesOpen] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const isDark = theme.palette.mode === "dark"

  // Handle user menu
  const handleUserMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleUserMenuClose = () => {
    setAnchorEl(null)
  }

  // Toggle sidebar collapse
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
    if (onCollapse) {
      onCollapse(!isCollapsed)
    }
  }

  // Toggle section collapse
  const togglePolicies = () => {
    setPoliciesOpen(!policiesOpen)
  }

  const toggleProfiles = () => {
    setProfilesOpen(!profilesOpen)
  }

  // Set selected item based on current route
  useEffect(() => {
    const currentPath = location.pathname
    const savedSelected = localStorage.getItem("selectedPage")

    // Determine the selected page based on partial matches of the current path
    const pageMap = [
      { path: /^\/dashboard(\/)?$/, name: "Dashboard" },
      { path: /^\/dashboard\/users/, name: "Users" },
      { path: /^\/dashboard\/policy/, name: "Real-Time Protection" },
      { path: /^\/dashboard\/tls-inspection/, name: "TLS Inspection" },
      { path: /^\/dashboard\/api-security/, name: "API Security" },
      { path: /^\/dashboard\/dlp/, name: "DLP" },
      { path: /^\/dashboard\/file-protection/, name: "File" },
      { path: /^\/dashboard\/network/, name: "Network" },
      { path: /^\/dashboard\/domain/, name: "Domain" },
      { path: /^\/dashboard\/devices/, name: "Devices" },
      { path: /^\/dashboard\/data-exchange/, name: "Data Exchange" },
      { path: /^\/dashboard\/logs/, name: "Logs" },
      { path: /^\/dashboard\/settings/, name: "Settings" },
    ]

    // Find the first matching route in the page map
    const matchedPage = pageMap.find((page) => page.path.test(currentPath))

    // Set the selected state based on the matched page or saved selection
    if (matchedPage) {
      setSelected(matchedPage.name)
      localStorage.setItem("selectedPage", matchedPage.name)
    } else if (savedSelected) {
      setSelected(savedSelected)
    } else {
      setSelected("Dashboard")
    }
  }, [location])

  // Handle item click
  const handleItemClick = (title) => {
    setSelected(title)
    localStorage.setItem("selectedPage", title)
  }

  // Determine if an item is active
  const isActive = (title) => selected === title

  // Reusable ListItemButton component
  const SidebarItem = ({ title, icon: Icon, to, badge }) => (
    <ListItem disablePadding>
      <Tooltip title={isCollapsed ? title : ""} placement="right">
        <ListItemButton
          component={Link}
          to={to}
          onClick={() => handleItemClick(title)}
          sx={{
            py: 1.5,
            backgroundColor: isActive(title) ? alpha(colors.blueAccent[500], 0.15) : "transparent",
            borderLeft: isActive(title) ? `4px solid ${colors.blueAccent[500]}` : "4px solid transparent",
            "&:hover": {
              backgroundColor: alpha(colors.blueAccent[500], 0.08),
            },
          }}
        >
          <ListItemIcon sx={{ color: isActive(title) ? colors.blueAccent[500] : colors.grey[300], minWidth: 40 }}>
            <Icon />
          </ListItemIcon>
          {!isCollapsed && (
            <ListItemText
              primary={
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <span>{title}</span>
                  {badge && (
                    <Badge
                      sx={{
                        fontSize: "0.7rem",
                        py: 0.2,
                        px: 0.8,
                        border: `1px solid ${colors.blueAccent[500]}`,
                        color: colors.blueAccent[400],
                        borderRadius: "4px",
                        backgroundColor: alpha(colors.blueAccent[500], 0.1),
                      }}
                    >
                      {badge}
                    </Badge>
                  )}
                </Box>
              }
              sx={{
                "& .MuiListItemText-primary": {
                  color: isActive(title) ? colors.blueAccent[400] : colors.grey[100],
                  fontWeight: isActive(title) ? 600 : 400,
                },
              }}
            />
          )}
        </ListItemButton>
      </Tooltip>
    </ListItem>
  )

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isCollapsed ? collapsedDrawerWidth : drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isCollapsed ? collapsedDrawerWidth : drawerWidth,
          boxSizing: "border-box",
          transition: "width 0.3s ease-in-out",
          overflowX: "hidden",
          backgroundColor: colors.primary[400],
          borderRight: `1px solid ${colors.grey[isDark ? 800 : 300]}`,
        },
      }}
    >
      {/* Logo and Toggle Button */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
          borderBottom: `1px solid ${colors.grey[isDark ? 800 : 300]}`,
          height: "64px",
        }}
      >
        {!isCollapsed ? (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <ShieldIcon sx={{ color: colors.blueAccent[400] }} />
            <Typography variant="h5" fontWeight="bold" sx={{ color: colors.grey[100] }}>
              DenovoSecurity
            </Typography>
          </Box>
        ) : (
          <ShieldIcon sx={{ color: colors.blueAccent[400], mx: "auto" }} />
        )}
        {!isCollapsed && (
          <IconButton
            onClick={toggleCollapse}
            size="small"
            sx={{
              color: colors.grey[300],
              "&:hover": {
                backgroundColor: alpha(colors.grey[700], 0.2),
              },
            }}
          >
            <MenuIcon />
          </IconButton>
        )}
        {isCollapsed && (
          <IconButton
            onClick={toggleCollapse}
            size="small"
            sx={{
              position: "absolute",
              top: 20,
              right: 4,
              color: colors.grey[300],
              opacity: 0,
              "&:hover": {
                opacity: 1,
                backgroundColor: alpha(colors.grey[700], 0.2),
              },
            }}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        )}
      </Box>

      {/* Main Navigation */}
      <List sx={{ py: 0, overflowY: "auto", flexGrow: 1 }}>
        {/* Dashboard and Users */}
        <SidebarItem title="Dashboard" icon={HomeIcon} to="/dashboard" />
        <SidebarItem title="Users" icon={PeopleAltIcon} to="/dashboard/users" />

        {/* Policies Section */}
        <Box sx={{ mt: 2 }}>
          {!isCollapsed && (
            <ListItem
              sx={{ py: 0 }}
              secondaryAction={
                <IconButton edge="end" onClick={togglePolicies} size="small" sx={{ color: colors.grey[400] }}>
                  {policiesOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              }
            >
              <Typography
                sx={{
                  fontSize: "0.75rem",
                  fontWeight: 600,
                  color: colors.grey[500],
                  py: 1.5,
                  px: 2,
                }}
              >
                POLICIES
              </Typography>
            </ListItem>
          )}
          {isCollapsed && <Divider sx={{ my: 1, mx: 2, backgroundColor: colors.grey[isDark ? 800 : 300] }} />}

          <Collapse in={policiesOpen || isCollapsed} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <SidebarItem title="Real-Time Protection" icon={PolicyIcon} to="/dashboard/policy" />
              <SidebarItem title="TLS Inspection" icon={ShieldIcon} to="/dashboard/tls-inspection" />
              <SidebarItem title="API Security" icon={SecurityIcon} to="/dashboard/api-security" badge="New" />
            </List>
          </Collapse>
        </Box>

        {/* Profiles Section */}
        <Box sx={{ mt: 1 }}>
          {!isCollapsed && (
            <ListItem
              sx={{ py: 0 }}
              secondaryAction={
                <IconButton edge="end" onClick={toggleProfiles} size="small" sx={{ color: colors.grey[400] }}>
                  {profilesOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              }
            >
              <Typography
                sx={{
                  fontSize: "0.75rem",
                  fontWeight: 600,
                  color: colors.grey[500],
                  py: 1.5,
                  px: 2,
                }}
              >
                PROFILES
              </Typography>
            </ListItem>
          )}
          {isCollapsed && <Divider sx={{ my: 1, mx: 2, backgroundColor: colors.grey[isDark ? 800 : 300] }} />}

          <Collapse in={profilesOpen || isCollapsed} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <SidebarItem title="DLP" icon={HttpsIcon} to="/dashboard/dlp" />
              <SidebarItem title="File" icon={InsertDriveFileIcon} to="/dashboard/file-protection" />
              <SidebarItem title="Network" icon={NetworkCheckIcon} to="/dashboard/network" />
              <SidebarItem title="Domain" icon={DomainIcon} to="/dashboard/domain" />
              <SidebarItem title="Devices" icon={DevicesIcon} to="/dashboard/devices" />
              <SidebarItem title="Data Exchange" icon={StorageIcon} to="/dashboard/data-exchange" />
            </List>
          </Collapse>
        </Box>

        {/* Additional Items */}
        <Box sx={{ mt: 1 }}>
          {isCollapsed && <Divider sx={{ my: 1, mx: 2, backgroundColor: colors.grey[isDark ? 800 : 300] }} />}
          <SidebarItem title="Logs" icon={ShowChartIcon} to="/dashboard/logs" />
          <SidebarItem title="Settings" icon={SettingsIcon} to="/dashboard/settings" />
        </Box>
      </List>

      {/* User Profile Section */}
      <Divider sx={{ backgroundColor: colors.grey[isDark ? 800 : 300] }} />
      <Box sx={{ p: 2 }}>

      </Box>
    </Drawer>
  )
}

export default Sidebar

