import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  Chip,
  Box,
  Divider,
} from '@mui/material';
import {
  FileText,
  FileCode,
  Calendar,
  Hash,
  Shield,
} from 'lucide-react';

const FileProfileDrawer = ({ isOpen, onClose, selectedFileProfile }) => {
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 400,
          padding: 2,
          overflow: 'auto',
        },
      }}
    >
      <Box sx={{ padding: 2 }}>
        <Typography
          variant="h5"
          sx={{ fontWeight: 'bold', mb: 2, textAlign: 'center' }}
        >
          Profile Details
        </Typography>
        <Divider sx={{ mb: 2 }} />

        <List>
          {[
            {
              label: 'Profile Name',
              value: selectedFileProfile?.profile_name || 'N/A',
              icon: <FileText size={20} />,
            },
            {
              label: 'File Hash',
              value: selectedFileProfile?.file_hash || 'N/A',
              icon: <Hash size={20} />,
            },
            {
              label: 'Protected/Encrypted',
              value:
                selectedFileProfile?.protected_encrypted === null
                  ? 'N/A'
                  : selectedFileProfile?.protected_encrypted
                  ? 'Yes'
                  : 'No',
              icon: <Shield size={20} />,
            },
            // {
            //   label: 'Created At',
            //   value: new Date(
            //     selectedFileProfile?.
            //   ).toLocaleString() || 'N/A',
            //   icon: <Calendar size={20} />,
            // },
            {
              label: 'Updated At',
              value: new Date(
                selectedFileProfile?.lastEdited
              ).toLocaleString() || 'N/A',
              icon: <Calendar size={20} />,
            },
          ].map((item, index) => (
            <React.Fragment key={index}>
              <ListItem sx={{ alignItems: 'flex-start' }}>
                {item.icon}
                <ListItemText
                  primary={item.label}
                  secondary={item.value}
                  sx={{ ml: 2 }}
                  primaryTypographyProps={{
                    sx: { fontWeight: 'medium', color: 'text.secondary' },
                  }}
                  secondaryTypographyProps={{
                    sx: { fontSize: '1.1rem', fontWeight: 'bold' },
                  }}
                />
              </ListItem>
              {index < 4 && <Divider sx={{ my: 1 }} />}
            </React.Fragment>
          ))}

          <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 'medium', color: 'text.secondary', mb: 1 }}
            >
              <FileText size={20} /> File Names or Extensions
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
              }}
            >
              {selectedFileProfile?.file_names?.map((name, index) => (
                <Chip
                  key={index}
                  label={name}
                  size="small"
                  icon={<FileText />}
                  sx={{
                    bgcolor: 'lightblue',
                    color: 'blue',
                    fontWeight: 'medium',
                  }}
                />
              ))}
            </Box>
          </ListItem>
          <Divider sx={{ my: 1 }} />

          <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 'medium', color: 'text.secondary', mb: 1 }}
            >
              <FileCode size={20} /> File Types
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
              }}
            >
              {selectedFileProfile?.file_types?.map((type, index) => (
                <Chip
                  key={index}
                  label={type}
                  size="small"
                  icon={<FileCode />}
                  sx={{
                    bgcolor: 'lightgreen',
                    color: 'green',
                    fontWeight: 'medium',
                  }}
                />
              ))}
            </Box>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default FileProfileDrawer;
