import React, { createContext, useState } from "react";

const ChangesContext = createContext();

export const ChangesProvider = ({ children }) => {
  // Load initial value from local storage
  const [hasChanges, setHasChangesState] = useState(() => {
    return JSON.parse(localStorage.getItem("hasChanges")) || false;
  });

  // Function to update state and persist in local storage
  const setHasChanges = (value) => {
    setHasChangesState(value);
    localStorage.setItem("hasChanges", JSON.stringify(value));
  };

  return (
    <ChangesContext.Provider value={{ hasChanges, setHasChanges }}>
      {children}
    </ChangesContext.Provider>
  );
};

export default ChangesContext;
