import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, IconButton, useTheme, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { tokens } from "../../theme";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import PolicyIcon from '@mui/icons-material/Policy';
import Shield from "@mui/icons-material/Shield";
import SecurityIcon from '@mui/icons-material/Security';
import HomeIcon from '@mui/icons-material/Home';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DevicesIcon from '@mui/icons-material/Devices';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import HttpsIcon from '@mui/icons-material/Https';
import Tooltip from '@mui/material/Tooltip';
import SettingsIcon from '@mui/icons-material/Settings';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import NetworkIcon from '@mui/icons-material/NetworkCheck';
import DomainIcon from '@mui/icons-material/Domain';
import Divider from '@mui/material/Divider';

const Item = ({ title, to, icon, selected, setSelected, Iscollapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Tooltip title={Iscollapsed ? title : ""} placement="right">
      <MenuItem
        active={selected === title}
        style={{ color: colors.grey[100] }}
        onClick={() => setSelected(title)}
        icon={icon}
      >
        <Typography>{title}</Typography>
        <Link to={to} />
      </MenuItem>
    </Tooltip>
  )
}

const Sidebar = ({ onCollapse }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation(); // Get current location
  const [Iscollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  useEffect(() => {
    const currentPath = location.pathname;

    // Determine the selected page based on partial matches of the current path
    const pageMap = [
      { path: /^\/dashboard(\/)?$/, name: "Dashboard" },
      { path: /^\/dashboard\/users/, name: "Users" },
      { path: /^\/dashboard\/policy/, name: "Policy" },
      { path: /^\/dashboard\/tls-inspection/, name: "TLS Inspection" },
      { path: /^\/dashboard\/dlp/, name: "DLP" },
      { path: /^\/dashboard\/file-protection/, name: "File" },
      { path: /^\/dashboard\/devices/, name: "Devices" },
      { path: /^\/dashboard\/data-exchange/, name: "Data Exchange" },
      { path: /^\/dashboard\/logs/, name: "Logs" },
      { path: /^\/dashboard\/settings/, name: "Settings" },
      { path: /^\/dashboard\/network/, name: "Network" },
      { path: /^\/dashboard\/domain/, name: "Domain" },
      { path: /^\/dashboard\/api-security/, name: "API Security" },
    ];

    // Find the first matching route in the page map
    const matchedPage = pageMap.find(page => page.path.test(currentPath));

    // Set the selected state based on the matched page
    if (matchedPage) {
      setSelected(matchedPage.name);
      localStorage.setItem('selectedPage', matchedPage.name);
    } else {
      setSelected("Dashboard"); // Default to Dashboard if no match
    }
  }, [location]);

  const handleItemClick = (title) => {
    setSelected(title);
    // Save the selected page to local storage
    localStorage.setItem("selectedPage", title);
  };

  const logo = theme.palette.mode === "light" ? "../../../images/dashboard-white-mode.png" : "../../../images/dashboard-dark-mode.jpg";

  const handleCollapse = () => {
    setIsCollapsed(!Iscollapsed);
    onCollapse(!Iscollapsed); // Notify parent of collapse state
  };

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
          transition: "width 0.3s ease, padding 0.3s ease",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important"
        },
        "& .pro-inner-item": {
          padding: "3px 35px 5px 15px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          backgroundColor: "rgba(104, 112, 250, 0.2)",
          borderLeft: "4px solid #6870fa",
          transition: "background-color 0.3s ease",
        },
        "& .pro-sidebar": {
          height: "100vh",
          position: "fixed",
          overflowY: "auto", // Enable vertical scrolling
        },
        "& .pro-sidebar-content": {
          height: "calc(100% - 70px)", // Adjust this value based on your header height
          overflowY: "auto", // Enable vertical scrolling
        },
      }}
    >
      <ProSidebar collapsed={Iscollapsed} style={{ height: "100vh" }}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={handleCollapse}
            icon={Iscollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              marginBottom: "30px",
              color: colors.grey[90],
            }}
          >
            {!Iscollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <img src={logo} alt="Denovosecurity Logo" width="100px" height="100px" />  {/* Use imported image */}
                <IconButton onClick={handleCollapse}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {/* Menu Items */}
          <Box paddingLeft={Iscollapsed ? undefined : "3%"}>
            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<HomeIcon />}
              selected={selected}
              setSelected={handleItemClick}
              Iscollapsed={Iscollapsed}
            />
            <Item
              title="Users"
              to="/dashboard/users"
              icon={<PeopleAltIcon />}
              selected={selected}
              setSelected={handleItemClick}
              Iscollapsed={Iscollapsed}
            />
            {/* Add a divider with a text overlined */}
            <div className="relative my-4">
              <Divider className="border-t border-gray-700" />
              <Typography className="absolute top-1/2 left-4 transform -translate-y-1/2 px-2 text-xs"
                sx={{ background: `${colors.primary[400]} !important` }}
              >
                POLICIES
              </Typography>
            </div>
            <Item
              title="Real-Time Protection"
              to="/dashboard/policy"
              icon={<PolicyIcon />}
              selected={selected}
              setSelected={handleItemClick}
              Iscollapsed={Iscollapsed}
            />
            <Item
              title="TLS Inspection"
              to="/dashboard/tls-inspection"
              icon={<Shield />}
              selected={selected}
              setSelected={handleItemClick}
              Iscollapsed={Iscollapsed}
            />
            <Item
              title="API Security"
              to="/dashboard/api-security"
              icon={<SecurityIcon />}
              selected={selected}
              setSelected={handleItemClick}
              Iscollapsed={Iscollapsed}
            />
            {/* Add a divider with a text overlined */}
            <div className="relative my-4">
              <Divider className="border-t border-gray-700" />
              <Typography className="absolute top-1/2 left-4 transform -translate-y-1/2 px-2 text-xs"
                sx={{ background: `${colors.primary[400]} !important` }}
              >
                PROFILES
              </Typography>
            </div>
            <Item
              title="DLP"
              to="/dashboard/dlp"
              icon={<HttpsIcon />}
              selected={selected}
              setSelected={handleItemClick}
              Iscollapsed={Iscollapsed}
            />
            <Item
              title="File"
              to="/dashboard/file-protection"
              icon={<InsertDriveFile />}
              selected={selected}
              setSelected={handleItemClick}
              Iscollapsed={Iscollapsed}
            />
            <Item
              title="Network"
              to="/dashboard/network"
              icon={<NetworkIcon />}
              selected={selected}
              setSelected={handleItemClick}
              Iscollapsed={Iscollapsed}
            />
            <Item
              title="Domain"
              to="/dashboard/domain"
              icon={<DomainIcon />}
              selected={selected}
              setSelected={handleItemClick}
              Iscollapsed={Iscollapsed}
            />
            <Item
              title="Devices"
              to="/dashboard/devices"
              icon={<DevicesIcon />}
              selected={selected}
              setSelected={handleItemClick}
              Iscollapsed={Iscollapsed}
            />
            <Item
              title="Data Exchange"
              to="/dashboard/data-exchange"
              icon={<DataUsageIcon />}
              selected={selected}
              setSelected={handleItemClick}
              Iscollapsed={Iscollapsed}
            />
            <Item
              title="Logs"
              to="/dashboard/logs"
              icon={<ShowChartIcon />}
              selected={selected}
              setSelected={handleItemClick}
              Iscollapsed={Iscollapsed}
            />
            <Item
              title="Settings"
              to="/dashboard/settings"
              icon={<SettingsIcon />}
              selected={selected}
              setSelected={handleItemClick}
              Iscollapsed={Iscollapsed}
            />
          </Box>
          {/* Add the divider */}
          <Divider />
        </Menu>
      </ProSidebar>
    </Box>
  );
}

export default Sidebar;