import React, { useState } from 'react';
import { Box } from '@mui/material';
import Sidebar from './Sidebar';
import Topbar from './Topbar';

const Layout = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapse = (collapsed) => {
    setIsCollapsed(collapsed);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        width: '100vw',
      }}
    >
      <Box
        sx={{
          position: 'fixed',
          height: '100vh',
          zIndex: 1,
        }}
      >
        <Sidebar onCollapse={handleCollapse} />
      </Box>
      <Box
        sx={{
          marginLeft: isCollapsed ? '80px' : '240px', // Adjust based on collapse state
          width: isCollapsed ? 'calc(100% - 80px)' : 'calc(100% - 240px)',
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        <Box
          sx={{
            position: 'fixed',
            width: isCollapsed ? 'calc(100% - 80px)' : 'calc(100% - 240px)',
            zIndex: 1,
          }}
        >
          <Topbar isCollapsed={isCollapsed} />  {/* Pass isCollapsed to Topbar */}
        </Box>
        <Box
          sx={{
            marginTop: '64px',
            padding: '16px',
            overflowY: 'auto',
            height: 'calc(100vh - 64px)',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
