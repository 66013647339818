// Inside DrawerLogs.js
import React from "react";
import { Drawer, Box, Typography, Divider } from "@mui/material";

const DrawerLogs = ({ open, onClose, logDetails }) => {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box p={3} width={350}>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Log Details
        </Typography>
        <Divider />
        {logDetails ? (
          <Box mt={2}>
            <Typography variant="body1">
              <strong>Time:</strong> {logDetails.time}
            </Typography>
            <Typography variant="body1">
              <strong>Name:</strong> {logDetails.name}
            </Typography>
            <Typography variant="body1">
              <strong>Alert Type:</strong> {logDetails.alertType}
            </Typography>
            <Typography variant="body1">
              <strong>Action:</strong> {logDetails.action}
            </Typography>
            <Typography variant="body1">
              <strong>Activity:</strong> {logDetails.activity}
            </Typography>
            <Typography variant="body1">
              <strong>User:</strong> {logDetails.user}
            </Typography>
            <Typography variant="body1">
              <strong>Application:</strong> {logDetails.application}
            </Typography>
          </Box>
        ) : (
          <Typography variant="body2" color="textSecondary">
            Select a log entry to view its details.
          </Typography>
        )}
      </Box>
    </Drawer>
  );
};

export default DrawerLogs;
