import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Typography,
  Switch,
  FormControlLabel,
  TextField,
  InputAdornment,
  CircularProgress,
  IconButton,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  Menu,
  MenuItem,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Search, Close, Add } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { Ellipsis, Trash2, Settings } from "lucide-react";
import { tokens } from "../../theme";
import CloseIcon from "@mui/icons-material/Close";
import DomainTLSForm from "./DomainTLSForm.jsx";
import Header from "../../components/Header";
import api from "../../api/api";

export default function TlsInspection() {
  const [domains, setDomains] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true); // Track loading state
  const [deleteConfirmation, setDeleteConfirmation] = useState(false); // Track delete dialog state
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Fetch domains from the backend
  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const response = await api.get("/tls-inspection/profiles?action=read", {
          withCredentials: true,
        });
        const fetchedDomains = response.data.map((profile) => ({
          id: profile.EntityId,
          name: profile.Attributes.domainName,
          tlsInspection: profile.Attributes.tlsInspection === "enabled",
        }));
        setDomains(fetchedDomains);
      } catch (error) {
        console.error("Error fetching domains:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDomains();
  }, []);

  const handleSearch = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmation(false);
  };

  const handleOpenDeleteConfirmation = () => {
    setDeleteConfirmation(true);
    handleMenuClose();
  };

  const handleMenuOpen = (event, domain) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedDomain(domain);
    setSelectedRow(domain);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleSnackbar = (message, severity) => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const filteredDomains = domains.filter((domain) =>
    domain.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setSelectedRow(null); // Reset selectedRow when opening for a new TLS Inspection
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRow(null);
  };

  const handleToggleTls = async (id) => {
    const updatedDomains = domains.map((domain) =>
      domain.id === id
        ? { ...domain, tlsInspection: !domain.tlsInspection }
        : domain
    );
    setDomains(updatedDomains);

    const toggledDomain = updatedDomains.find((domain) => domain.id === id);

    try {
      await api.put(
        `/tls-inspection/profiles?action=update&EntityId=${id}`,
        {
          Attributes: {
            domainName: toggledDomain.name,
            tlsInspection: toggledDomain.tlsInspection ? "enabled" : "disabled",
          },
        },
        { withCredentials: true }
      );
      handleSnackbar(`TLS inspection updated for ${toggledDomain.name}`, "success");
    } catch (error) {
      console.error("Error updating TLS inspection:", error);
      handleSnackbar("Failed to update TLS inspection", "error");
    }
  };

  const handleDeleteProfile = async () => {
    try {
      await api.delete(
        `/tls-inspection/profiles?action=delete&EntityId=${selectedRow.id}`,
        { withCredentials: true }
      );

      setDomains((prevDomains) =>
        prevDomains.filter((domain) => domain.id !== selectedRow.id)
      );

      handleSnackbar(
        `TLS profile for ${selectedRow.name} deleted successfully`,
        "success"
      );
      setDeleteConfirmation(false);
    } catch (error) {
      console.error("Failed to delete TLS profile:", error);
      handleSnackbar("Failed to delete TLS profile", "error");
    }
  };

  const handleEditProfile = () => {
    setIsModalOpen(true);
    handleMenuClose();
  };

  const handleAddTlsProfile = (newProfile) => {
    setDomains((prevDomains) => [...prevDomains, newProfile]);
    setIsModalOpen(false);
    handleSnackbar(`TLS profile for ${newProfile.name} created successfully`, "success");
  };

  const handleEditTlsProfile = async (updatedProfile) => {
    try {
      await api.put(
        `/tls-inspection/profiles?action=update&EntityId=${updatedProfile.id}`,
        {
          Attributes: {
            domainName: updatedProfile.name,
            tlsInspection: updatedProfile.tlsInspection ? "enabled" : "disabled",
          },
        },
        { withCredentials: true }
      );
  
      setDomains((prevDomains) =>
        prevDomains.map((domain) =>
          domain.id === updatedProfile.id ? { ...domain, ...updatedProfile } : domain
        )
      );
      setIsModalOpen(false);
      handleSnackbar('TLS profile updated successfully', 'success');
    } catch (error) {
      console.error('Failed to update TLS profile:', error);
      handleSnackbar('Failed to update TLS profile', 'error');
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Domain Name",
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{
            textDecoration: "underline",
            cursor: "pointer",
            marginTop: "15px",
            fontSize: "12px",
            marginLeft: "10px",
          }}
          color={colors.greenAccent[400]}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "tlsInspection",
      headerName: "TLS Inspection",
      flex: 1,
      renderCell: (params) => (
        <FormControlLabel
          control={
            <Switch
              checked={params.value}
              onChange={() => handleToggleTls(params.row.id)}
              color="primary"
            />
          }
          label={params.value ? "Enabled" : "Disabled"}
        />
      ),
    },
    {
      flex: 1,
      renderCell: (params) => (
        <Box className="flex justify-end">
          <IconButton
            aria-label="open menu"
            onClick={(event) => handleMenuOpen(event, params.row)}
            size="small"
          >
            <Ellipsis fontSize="small" />
          </IconButton>
        </Box>
      ),
    },
  ];

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column", p: 3 }}>
      <Header title="TLS Inspection" subtitle="Manage TLS inspection for domains" />

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <TextField
          label="Search Domain"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          startIcon={<Add />}
          onClick={handleOpenModal}
        >
          Add TLS Inspection
        </Button>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <DataGrid
          rows={filteredDomains}
          columns={columns}
          pageSize={8}
          rowsPerPageOptions={[5, 10, 20]}
          disableRowSelectionOnClick
          disableColumnResize
          disableColumnFilter
          disableColumnMenu
          disableColumnSorting
          sx={{
            "&, [class^=MuiDataGrid]": {
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "none",
            },
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus-within": {
              outline: "none",
            },
          }}
        />
      </Box>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography variant="subtitle2" sx={{ px: 2, py: 1 }}>
          Actions
        </Typography>
        <Divider />
        <MenuItem onClick={handleEditProfile}>
          <Settings className="mr-2" size={16} />
          <span>Edit</span>
        </MenuItem>
        <MenuItem onClick={handleOpenDeleteConfirmation}>
          <Trash2 className="mr-2" size={16} />
          <span>Delete</span>
        </MenuItem>
      </Menu>

      <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle className="text-2xl font-bold mb-6">
          {selectedRow ? "Edit TLS Inspection" : "Add TLS Inspection"}
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DomainTLSForm
            onAddTlsProfile={handleAddTlsProfile}
            onEditTlsProfile={handleEditTlsProfile}
            initialValues={selectedRow}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={deleteConfirmation} onClose={handleCloseDeleteConfirmation} maxWidth="xs" fullWidth>
        <DialogTitle>
          <strong className="text-lg font-bold">Are you sure?
            <div className="float-right cursor-pointer">
              <CloseIcon onClick={handleCloseDeleteConfirmation} />
            </div>
          </strong>
        </DialogTitle>
        <DialogContent className="p-4">
          <Typography variant="body2" className="mb-4">
            This action <span className="font-semibold">CANNOT</span> be undone.
            This will permanently delete the <span className="font-semibold underline">{selectedRow?.name}</span> profile and remove all associated data.
          </Typography>
        </DialogContent>
        <Box className="p-4">
          <Button
            onClick={handleDeleteProfile}
            color="error"
            variant="contained"
            fullWidth
          >
            Confirm Delete
          </Button>
        </Box>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}