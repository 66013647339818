import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Checkbox,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Typography,
  Collapse,
  Chip,
  Tooltip,
} from '@mui/material';
import { Search, ExpandLess, ExpandMore, Close as CloseIcon } from '@mui/icons-material';
// import { useTheme } from '@mui/system';
// import { tokens } from "../../../theme";
import { useFormContext } from 'react-hook-form';

const categories = [
  { id: 1, name: 'Personal Names', dataIdentifiers: ['First Name', 'Last Name', 'Full Name'] },
  { id: 2, name: 'Personal Numbers', dataIdentifiers: ['Social Security Number', 'Passport Number', 'National ID'] },
  { id: 3, name: 'Financial Data', dataIdentifiers: ['Credit Card Number', 'Bank Account Number', 'IBAN'] },
  { id: 4, name: 'Medical Data', dataIdentifiers: ['Patient Information', 'Medical Conditions', 'Medical Tests'] },
  { id: 5, name: 'Postal Addresses', dataIdentifiers: ['Street Address', 'Postal Code', 'City', 'State'] },
  { id: 6, name: 'Dates', dataIdentifiers: ['Date of Birth', 'Anniversary Date', 'Date of Joining'] },
  { id: 7, name: 'Biodata', dataIdentifiers: ['Height', 'Weight', 'Eye Color', 'Blood Type'] },
  { id: 8, name: 'Computing', dataIdentifiers: ['IP Address', 'MAC Address', 'Email Address'] },
];

const PredefinedStep = () => {
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [localSelectedIdentifiers, setLocalSelectedIdentifiers] = useState({});
  const { getValues, setValue, watch, trigger, formState: { errors } } = useFormContext();
  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);

  const formSelectedIdentifiers = watch('selectedIdentifiers');

  useEffect(() => {
    const initialSelectedIdentifiers = getValues('selectedIdentifiers') || {};
    setLocalSelectedIdentifiers(initialSelectedIdentifiers);
  }, [getValues]);

  useEffect(() => {
    if (formSelectedIdentifiers) {
      setLocalSelectedIdentifiers(formSelectedIdentifiers);
    }
  }, [formSelectedIdentifiers]);

  const handleCategoryToggle = (categoryId) => {
    setExpandedCategory((prev) => (prev === categoryId ? null : categoryId));
  };

  const handleDataIdentifierToggle = (categoryId, identifier) => {
    const updatedIdentifiers = {
      ...localSelectedIdentifiers,
      [categoryId]: localSelectedIdentifiers[categoryId]?.includes(identifier)
        ? localSelectedIdentifiers[categoryId].filter((id) => id !== identifier)
        : [...(localSelectedIdentifiers[categoryId] || []), identifier],
    };
    setLocalSelectedIdentifiers(updatedIdentifiers);
    setValue("selectedIdentifiers", updatedIdentifiers, { shouldValidate: true });
    trigger("selectedIdentifiers"); // Trigger validation
  };

  const handleRemoveIdentifier = (categoryId, identifier) => {
    const updatedIdentifiers = { ...localSelectedIdentifiers };
    if (updatedIdentifiers[categoryId]) {
      updatedIdentifiers[categoryId] = updatedIdentifiers[categoryId].filter(id => id !== identifier);
      if (updatedIdentifiers[categoryId].length === 0) {
        delete updatedIdentifiers[categoryId];
      }
      setLocalSelectedIdentifiers(updatedIdentifiers);
      setValue('selectedIdentifiers', updatedIdentifiers, {shouldValidate: true});
      trigger("selectedIdentifiers"); // Trigger validation
    }
  };

  const filteredCategories = useMemo(
    () =>
      categories.filter((category) =>
        searchTerm
          ? category.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            category.dataIdentifiers.some((id) =>
              id.toLowerCase().includes(searchTerm.toLowerCase())
            )
          : true
      ),
    [searchTerm]
  );

  return (
    <Box className="flex flex-col gap-4 p-4 min-h-screen">
      <Box className="flex justify-between items-center">
        <TextField
          placeholder="Search categories"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box className="flex gap-4">
        <Paper className="w-1/3 p-4" elevation={3}>
          <Typography variant="h6" className="mb-4">CATEGORIES</Typography>
          <List>
            {filteredCategories.map((category) => (
              <React.Fragment key={category.id}>
                <ListItem
                  button
                  onClick={() => handleCategoryToggle(category.id)}
                  className="rounded"
                >
                  <ListItemIcon>
                    {expandedCategory === category.id ? <ExpandLess /> : <ExpandMore />}
                  </ListItemIcon>
                  <ListItemText primary={category.name} />
                </ListItem>
                <Collapse in={expandedCategory === category.id} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {category.dataIdentifiers.map((identifier) => (
                      <ListItem key={identifier} className="pl-8">
                        <Checkbox 
                          checked={localSelectedIdentifiers[category.id]?.includes(identifier) || false}
                          onChange={() => handleDataIdentifierToggle(category.id, identifier)}
                        />
                        <ListItemText primary={identifier} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
        </Paper>

        <Paper className="w-2/3 p-4" elevation={3}>
  <Typography variant="h6" className="mb-4">Selected Data Identifiers</Typography>
  {errors.selectedIdentifiers && (
    <Typography color="error" variant="body2">
      {errors.selectedIdentifiers.message}
    </Typography>
  )}
  {Object.keys(localSelectedIdentifiers).length === 0 ? (
    <Box className="text-center p-4 bg-gray-100 rounded">
      <Typography variant="body1">No identifiers selected.</Typography>
      <Typography variant="body2">Select identifiers from the categories on the left to view them here.</Typography>
    </Box>
  ) : (
    <Box className="space-y-4">
      {Object.entries(localSelectedIdentifiers).map(([categoryId, identifiers]) => {
        const category = categories.find(cat => cat.id === parseInt(categoryId));
        return identifiers.length > 0 && (
          <Box key={categoryId} className="bg-gray-50 p-4 rounded shadow-sm">
            <Typography variant="subtitle1" className="font-bold mb-2">{category?.name}</Typography>
            <Box className="flex flex-wrap gap-2">
              {identifiers.map((identifier) => (
                <Tooltip title="Click to remove" arrow key={identifier}>
                  <Chip
                    label={identifier}
                    onDelete={() => handleRemoveIdentifier(parseInt(categoryId), identifier)}
                    deleteIcon={<CloseIcon />}
                    className="bg-blue-100 text-blue-800"
                  />
                </Tooltip>
              ))}
            </Box>
          </Box>
        );
      })}
    </Box>
  )}
</Paper>
        </Box>
      </Box>
    );
  };

export default PredefinedStep;