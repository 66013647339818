/* eslint-disable */
"use client"

import { useState, useEffect } from "react"
import { Box, Typography, Card, CardContent, Stack, Tabs, Tab, styled, Collapse, IconButton, CircularProgress } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts"
import { tokens } from "../../theme"
import api from "../../api/api"
import { useTheme } from "@emotion/react"
import { formatDate } from "../../utils/formatDate"; // Import the formatDate function


// Data for the pie chart
const pieData = [
  { name: "Malicious", value: 15, color: "#ef4444" },
  { name: "Suspicious", value: 25, color: "#f97316" },
  { name: "Block", value: 30, color: "#ef4444" },
  { name: "Allow", value: 10, color: "#10b981" },
  { name: "whitelist", value: 5, color: "#7c3aed" },
  { name: "alert", value: 5, color: "#3b82f6" },
  { name: "user alert", value: 5, color: "#8884D8" },
]

// Styled components for custom tabs
const StyledTabs = styled(Tabs)({
  minHeight: "unset",
  "& .MuiTabs-indicator": {
    backgroundColor: "#10b981", // emerald-400
    height: "2px",
  },
  "& .MuiTabs-flexContainer": {
    gap: "1rem",
  },
})

const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.grey[700],
  minHeight: "unset",
  padding: "0.5rem 0",
  textTransform: "none",
  fontSize: "0.875rem",
  fontWeight: 500,
  "&.Mui-selected": {
    color: theme.palette.grey[700],
  },
  "& .count": {
    marginLeft: "0.25rem",
    padding: "0.125rem 0.375rem",
    borderRadius: "0.375rem",
    fontSize: "0.75rem",
    backgroundColor: "rgba(148, 163, 184, 0.1)", // gray-400 with opacity
  },
}))

// Investigation Data
const incidentsData = [
  {
    id: 1,
    type: "Suspicious",
    title: "Execution: ECS/maliciousFile",
    description: "Security Risk Detected in ECS Cluster dzone-1qx...",
    time: "Sep 25, 2024 11:33AM PDT",
    timeAgo: "2 days ago",
  },
  {
    id: 2,
    type: "Malicious",
    title: "UnauthorizedAccess: S3/M...",
    description: "Unauthorized API Access from Threat-Listed IP...",
    time: "Sep 25, 2024 11:33AM PDT",
    timeAgo: "2 days ago",
  },
  {
    id: 3,
    type: "Malicious",
    title: "UnauthorizedAccess: S3/M...",
    description: "Unauthorized API Access from Threat-Listed IP...",
    time: "Sep 25, 2024 11:33AM PDT",
    timeAgo: "2 days ago",
  },
  {
    id: 4,
    type: "Malicious",
    title: "UnauthorizedAccess: S3/M...",
    description: "Unauthorized API Access from Threat-Listed IP...",
    time: "Sep 25, 2024 11:33AM PDT",
    timeAgo: "2 days ago",
  },
  {
    id: 5,
    type: "Malicious",
    title: "UnauthorizedAccess: S3/M...",
    description: "Unauthorized API Access from Threat-Listed IP...",
    time: "Sep 25, 2024 11:33AM PDT",
    timeAgo: "2 days ago",
  },

  // ... other incidents
]

// ...existing code...

const Dashboard = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const [tabValue, setTabValue] = useState(0)
  const [expandedIncident, setExpandedIncident] = useState(null) // Track expanded incident
  const [logs, setLogs] = useState([]) // State to store logs data
  const [pieData, setPieData] = useState([]) // State to store pie chart data
  const [loading, setLoading] = useState(true) // State to track loading status

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await api.get("/api/logs/?action=read", { withCredentials: true })
        const logsData = response.data.map((log) => ({
          policyName: log.Attributes.policy_name,
          id: log.EntityId,
          action: log.Attributes.action,
          ts: formatDate(log.Attributes.ts),
        }))
        setLogs(logsData)
        updatePieData(logsData)
      } catch (error) {
        console.error("Error fetching logs:", error)
      } finally {
        setLoading(false) // Set loading to false after data is fetched
      }
    }

    fetchLogs()
  }, [])

  const updatePieData = (logsData) => {
    const actionCounts = logsData.reduce((acc, log) => {
      acc[log.action] = (acc[log.action] || 0) + 1
      return acc
    }, {})

    const newPieData = Object.keys(actionCounts).map((action) => ({
      name: action,
      value: actionCounts[action],
      color: getColorForAction(action),
    }))

    setPieData(newPieData)
  }

  const getColorForAction = (action) => {
    switch (action) {
      case "BLOCK":
        return "#ef4444"
      case "ALLOW":
        return "#10b981"
      case "WHITELIST":
        return "#7c3aed"
      case "ALERT":
        return "#3b82f6"
      case "USER ALERT":
        return "#8884D8"
      default:
        return "#7d78e4"
    }
  };


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
    setExpandedIncident(null) // Reset expanded state when switching tabs
  }

  const handleExpandClick = (id) => {
    setExpandedIncident(expandedIncident === id ? null : id) // Toggle incident expansion
  }

  const filterIncidents = (incidents, tab) => {
    switch (tab) {
      case 1:
        return incidents.filter((i) => i.type === "Malicious")
      case 2:
        return incidents.filter((i) => i.type === "Suspicious")
      default:
        return incidents
    }
  }

  const filteredIncidents = filterIncidents(logs, tabValue)

  const lastFiveLogs = logs.slice(-5) // Get the last 5 logs

  return (
    <Box m="20px" className="min-h-screen text-white">
      {/* Top Stats */}
      <Box className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <Card
          sx={{
            backgroundColor: colors.primary[400],
            color: colors.grey[100],
            border: "1px solid rgba(204, 204, 204, 0.5)",
            borderRadius: "4px",
            boxShadow: "none",
          }}
        >
          <CardContent sx={{ padding: "24px" }}>
            <Typography variant="body2" sx={{ color: "#999", marginBottom: "8px", fontSize: "14px" }}>
            Top Blocked Domains
</Typography>
            <Typography variant="h2" sx={{ fontWeight: 600, fontSize: "48px" }}>
              -
            </Typography>
          </CardContent>
        </Card>

        <Card
          sx={{
            backgroundColor: colors.primary[400],
            color: colors.grey[100],
            border: "1px solid rgba(204, 204, 204, 0.5)",
            borderRadius: "4px",
            boxShadow: "none",
          }}
        >
          <CardContent sx={{ padding: "24px" }}>
            <Typography variant="body2" sx={{ color: "#999", marginBottom: "8px", fontSize: "14px" }}>
            Average Policy Decision Time
</Typography>
            <Typography variant="h2" sx={{ fontWeight: 600, fontSize: "48px" }}>
              -
              <span style={{ fontSize: "16px", marginLeft: "4px", fontWeight: 400 }}>min</span>
            </Typography>
          </CardContent>
        </Card>

        <Card
          sx={{
            backgroundColor: colors.primary[400],
            color: colors.grey[100],
            border: "1px solid rgba(204, 204, 204, 0.5)",
            borderRadius: "4px",
            boxShadow: "none",
          }}
        >
          <CardContent sx={{ padding: "24px" }}>
            <Typography variant="body2" sx={{ color: "#999", marginBottom: "8px", fontSize: "14px" }}>
            Top Triggered Policies
            </Typography>
            <Typography variant="h2" sx={{ fontWeight: 600, fontSize: "48px" }}>
              -
              <span style={{ fontSize: "16px", marginLeft: "4px", fontWeight: 400 }}>hrs</span>
            </Typography>
          </CardContent>
        </Card>
      </Box>

      {/* Main Content */}
      <Box className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Left Section with Chart */}
        <Card
          className="lg:col-span-2"
          sx={{
            backgroundColor: colors.primary[400],
            color: colors.grey[100],
            border: "1px solid rgba(204, 204, 204, 0.5)",
          }}
        >
          <CardContent>
            <Box className="flex items-center justify-between mb-6">
              <Typography variant="h5">Security Overview</Typography>
              <Stack direction="row" spacing={2} className="flex-wrap gap-2">
                {pieData.map((entry) => (
                  <Box key={entry.name} className="flex items-center gap-2">
                    <Box className="w-3 h-3 rounded-full" style={{ backgroundColor: entry.color }} />
                    <Typography variant="caption" className="text-gray-400">
                      {entry.name}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Box>

            <Box className="h-[300px] relative flex justify-start items-center">
              <ResponsiveContainer width="50%" height="100%">
                <PieChart>
                  <Pie
                    data={pieData}
                    cx="50%"
                    cy="50%"
                    innerRadius="60%"
                    outerRadius="80%"
                    paddingAngle={2}
                    dataKey="value"
                  >
                    {pieData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              <Box className="absolute left-1/4 transform -translate-x-1/2 text-center">
                <Typography variant="h2" className="font-bold">
                  {pieData.reduce((acc, entry) => acc + entry.value, 0)}
                </Typography>
                <Typography variant="body2" className="text-gray-400">
                  Total Actions
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>

        {/* Right Section with Incidents */}
        <Card
          className="bg-gray-800 text-white"
          sx={{
            height: 450,
            border: "1px solid rgba(204, 204, 204, 0.5)",
            "&::-webkit-scrollbar": {
              width: "8px",
              background: "transparent",
              display: "none",
            },
            "&:hover::-webkit-scrollbar": {
              display: "block",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "rgba(255, 255, 255, 0.2)",
            },
            scrollbarWidth: "thin",
            scrollbarColor: "rgba(255, 255, 255, 0.1) transparent",
            "&:hover": {
              scrollbarColor: "rgba(255, 255, 255, 0.2) transparent",
            },
          }}
        >
          <Card
            className="bg-gray-800 text-white"
            sx={{ height: 450, overflowY: "auto", border: "1px solid rgba(204, 204, 204, 0.5)", backgroundColor: colors.primary[400], color: colors.grey[100] }}
          >
            <CardContent className="p-0">
              <Box
                className="p-4 border-b"
                sx={{
                  backgroundColor: colors.primary[400],
                  color: colors.grey[100],
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                }}
              >
                <Typography variant="h6" className="mb-4">
                  Recent activities
                </Typography>
                <StyledTabs value={tabValue} onChange={handleTabChange} className="min-h-0">
                  {/* <StyledTab
                    label={
                      <span>
                        All
                        <span className="count" style={{ backgroundColor: colors.greenAccent[400], color: colors.grey[100] }}>
                          20
                        </span>
                      </span>
                    }
                  />
                  <StyledTab
                    label={
                      <span>
                        Malicious
                        <span className="count" style={{ backgroundColor: colors.redAccent[400], color: colors.grey[100] }}>
                          01
                        </span>
                      </span>
                    }
                  />
                  <StyledTab
                    label={
                      <span>
                        Suspicious
                        <span className="count" style={{ backgroundColor: colors.redAccent[400], color: colors.grey[100] }}>
                          16
                        </span>
                      </span>
                    }
                  /> */}
                </StyledTabs>
              </Box>

              {/* Incident List (Collapsible) */}
              <Stack className="p-4" spacing={2}>
                {loading ? (
                  <Typography variant="body2" className="text-gray-400">
                    <CircularProgress size={24} className="mr-2" />
                    Loading...
                  </Typography>
                ) : lastFiveLogs.length > 0 ? (
                  lastFiveLogs.map((log) => (
                    <Box
                      key={log.id}
                      className="hover:bg-gray-700 transition-colors rounded-lg cursor-pointer"
                      sx={{
                        backgroundColor: colors.primary[400],
                        color: colors.grey[100],
                        border: "1px solid rgba(204, 204, 204, 0.5)",
                      }}
                    >
                      <Box
                        className="p-4 flex justify-between items-center"
                        onClick={() => handleExpandClick(log.id)}
                      >
                        <Box className="flex items-start gap-3">
                          <Box
                            className={`px-3 py-1 rounded-full text-sm font-medium ${
                              log.action === "Suspicious" ? "bg-orange-500/10 text-white" : "bg-red-500/10 text-white"
                            }`}
                            sx={{
                              backgroundColor: getColorForAction(log.action),
                              color: "white",
                              fontWeight: "normal",
                              fontSize: "0.75rem",
                            }}
                          >
                            {log.action}
                          </Box>
                          <Box
                            className="text-sm font-medium px-3 py-1 rounded"
                            sx={{
                              backgroundColor: "rgba(30, 41, 59, 0.8)",
                              color: "#93c5fd",
                              fontSize: "0.75rem",
                            }}
                          >
                            {log.ts}
                          </Box>
                        </Box>
                        <IconButton size="small" className="text-gray-400">
                          <ExpandMoreIcon
                            className={`transition-transform duration-200 ${expandedIncident === log.id ? "rotate-180" : ""}`}
                          />
                        </IconButton>
                      </Box>

                      {/* Collapsible Details */}
                      <Collapse in={expandedIncident === log.id} timeout="auto" unmountOnExit>
                        <Box className="p-4 pt-0">
                          <Typography className="text-sm text-gray-400">{log.description}</Typography>
                          <Typography className="text-lg text-gray-500 mt-1">
                            {log.policyName}
                          </Typography>
                        </Box>
                      </Collapse>
                    </Box>
                  ))
                ) : (
                  <Typography variant="body2" className="text-gray-400">
                    No investigations found.
                  </Typography>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Card>
      </Box>
    </Box>
  )
}

export default Dashboard