import React, { useState, useEffect, useCallback, useMemo, cloneElement, useRef } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  StepConnector,
  Typography,
  Box,
  IconButton,
  Container,
  Divider,
  CircularProgress,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTheme } from '@mui/system';
import { tokens } from "../../theme";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CloseIcon from "@mui/icons-material/Close";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import SpeedIcon from "@mui/icons-material/Speed";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import PredefinedStep from "./steps/PredefinedStep";
import CustomStep from "./steps/CustomStep";
import AdvancedOptionsStep from "./steps/AdvancedOptionsStep";
import ContentStep from "./steps/ContentStep";
import SeverityThreshold from "./steps/SeverityThreshold";
import SetRule from "./steps/SetRule";
import api from "../../api/api";
import { categories } from "./steps/PredefinedStep";

// Custom connector style with color and animation
const CustomConnector = () => (
  <StepConnector
    sx={{
      "& .MuiStepConnector-line": {
        borderColor: "#e0e0e0", // neutral gray
        borderTopWidth: 3,
      },
      "&.Mui-active .MuiStepConnector-line": {
        borderColor: "#3f51b5", // active blue
      },
      "&.Mui-completed .MuiStepConnector-line": {
        borderColor: "#4caf50", // completed green
      },
    }}
  />
);

// Custom Step Icons
const StepIcon = ({ icon, active, completed }) => {
  return completed ? (
    <CheckCircleIcon color="success" fontSize="large" />
  ) : (
    cloneElement(icon, {
      fontSize: "large",
      sx: {
        color: active ? "#3f51b5" : "#9e9e9e",
        transition: "transform 0.3s",
        transform: active ? "scale(1.1)" : "scale(1)",
      },
    })
  );
};

const stepIcons = [
  <AccountCircleOutlinedIcon />,
  <TuneOutlinedIcon />,
  <SettingsOutlinedIcon />,
  <ContentPasteIcon />,
  <SpeedIcon />,
  <PlaylistAddCheckIcon />,
];

// Validation schema for form
const validationSchema = yup.object().shape({
  profileName: yup.string().required("Profile name is required"),
  selectedIdentifiers: yup.object().test(
    'has-identifiers',
    'Select at least one predefined or custom identifier',
    function(value) {
      const { selectedCustomIdentifiers } = this.parent;
      const hasPredefined = Object.values(value).some(arr => arr.length > 0);
      const hasCustom = selectedCustomIdentifiers.length > 0;
      return hasPredefined || hasCustom;
    }
  ),
  selectedCustomIdentifiers: yup.array(),
  ruleExpression: yup.string().required("Rule expression is required"),
  // ruleExpression: yup
  //   .string()
  //   .required("Rule expression is required")
  //   .test(
  //     "valid-identifiers",
  //     "There are identifiers in the rule expression that are not selected",
  //     function (value) {
  //       const { selectedIdentifiers, selectedCustomIdentifiers } = this.parent;

  //       // 1) Build a Set of all valid identifiers (predefined + custom)
  //       const validIdentifiers = new Set();

  //       // Predefined: object { categoryId: [identifier1, identifier2, ...] }
  //       Object.values(selectedIdentifiers || {}).forEach((identArray) => {
  //         if (Array.isArray(identArray)) {
  //           identArray.forEach((ident) => validIdentifiers.add(ident));
  //         }
  //       });

  //       // Custom: array [{ identifierName: "something" }, ...]
  //       (selectedCustomIdentifiers || []).forEach((custom) => {
  //         if (custom && custom.identifierName) {
  //           validIdentifiers.add(custom.identifierName);
  //         }
  //       });

  //       // 2) Parse the expression tokens, ignoring operators, parentheses, etc.
  //       //    A naive approach: split on whitespace.
  //       //    We'll consider "AND", "OR", "NOT", "NEAR", "(" and ")" as valid operators.
  //       const operators = new Set(["AND", "OR", "NOT", "(", ")", "NEAR"]);
  //       const tokens = value ? value.split(/\s+/) : [];

  //       for (let i = 0; i < tokens.length; i++) {
  //         const rawToken = tokens[i].trim();
  //         const upperToken = rawToken.toUpperCase();

  //         // Skip recognized operators or numeric NEAR values (e.g. "NEAR 100")
  //         if (
  //           operators.has(upperToken) ||  // e.g. "AND", "OR", "NOT", "NEAR"
  //           /^\d+$/.test(upperToken)      // e.g. "50" after NEAR
  //         ) {
  //           continue;
  //         }

  //         // If we see "NEAR", we skip the next numeric token, etc. 
  //         // But a simple approach is to skip if operator or numeric.
  //         // Now check if the token is in our set of valid identifiers:
  //         if (!validIdentifiers.has(rawToken)) {
  //           // This means user typed an identifier that doesn’t exist in either
  //           // Predefined or Custom. Return false to fail validation.
  //           return false;
  //         }
  //       }

  //       // If we never hit a bad token, the expression is valid.
  //       return true;
  //     }
  //   ),
  severityThresholds: yup.object({
    low: yup
      .number()
      .typeError("Low severity must be a number")
      .min(1, "Low severity must be at least 1")
      .required("Low severity is required"),
    medium: yup
      .number()
      .typeError("Medium severity must be a number")
      .min(1, "Medium severity must be at least 1")
      .required("Medium severity is required"),
    high: yup
      .number()
      .typeError("High severity must be a number")
      .min(1, "High severity must be at least 1")
      .required("High severity is required"),
    critical: yup
      .number()
      .typeError("Critical severity must be a number")
      .min(1, "Critical severity must be at least 1")
      .required("Critical severity is required"),
  }),
  policyActionLevel: yup
    .string()
    .oneOf(["low", "medium", "high", "critical"], "Invalid policy action level")
    .required("Policy action level is required"),

  scanOptions: yup.object()
    .test(
      'at-least-one-selected',
      'Please select at least one scan option.',
      (value) => Object.values(value || {}).some((v) => v === true)
    )
    .required('Please select at least one scan option.'),
});

const DLPProfileForm = ({ onClose, onProfileCreated, selectedDlpProfile }) => {
  const steps = [
    "PREDEFINED",
    "CUSTOM",
    "ADVANCED OPTIONS",
    "CONTENT",
    "SEVERITY THRESHOLD",
    "SET RULE",
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const scrollableContainerRef = useRef(null);
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      profileName: "",
      selectedIdentifiers: {},
      selectedCustomIdentifiers: [],
      ruleExpression: "",
      regexConditions: [],
      scanOptions: {},
      severityThresholds: {
        low: 5,
        medium: 50,
        high: 100,
        critical: 1000,
      },
    },
    mode: "onChange",
  });

  const {
    getValues,
    reset,
    handleSubmit,
    trigger,
    setError,
    clearErrors,
  } = methods;

  useEffect(() => {
    if (selectedDlpProfile) {
      const apiIdentifiers = selectedDlpProfile.selectedIdentifiers || [];
      const convertedIdentifiers = apiIdentifiers.reduce((acc, item) => {
        const category = categories.find(cat => 
          cat.dataIdentifiers.some(di => di === item.identifierName)
        );
        if (category) {
          const categoryIdStr = category.id.toString(); // Convert to string
          acc[categoryIdStr] = acc[categoryIdStr] || [];
          acc[categoryIdStr].push(item.identifierName);
        }
        return acc;
      }, {});
  
      reset({
        profileName: selectedDlpProfile.name,
        selectedIdentifiers: convertedIdentifiers,
        selectedCustomIdentifiers: selectedDlpProfile.selectedIdentifiers || [], 
        ruleExpression: selectedDlpProfile.ruleExpression,
        scanOptions: selectedDlpProfile.scanOptions,
        severityThresholds: selectedDlpProfile.severityThresholds,
        regexConditions: selectedDlpProfile.regexConditions || [],
      });
    }
  }, [selectedDlpProfile, reset, categories]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode); // get the appropriate theme colors
  const isLastStep = activeStep === steps.length - 1;
  // const [hasSelectedIdentifiers, setHasSelectedIdentifiers] = useState(false);

  const checkProfileNameUnique = async (profileName) => {
    try {
      const response = await api.get(`/dlp/profiles?action=check-name&name=${encodeURIComponent(profileName)}`);
      return response.data.isUnique;
    } catch (error) {
      console.error("Error checking profile name uniqueness:", error);
      return false; // Treat errors as the name not being unique
    }
  };

  const handleNext = useCallback(async () => {
    let isStepValid = true;
  
    if (activeStep === 1) {
      // Validate Predefined or Custom Identifiers
      const predefinedValid = Object.values(getValues("selectedIdentifiers") || {}).some(
        (identifiers) => identifiers.length > 0
      );
      const customValid = (getValues("selectedCustomIdentifiers") || []).length > 0;
  
      // Trigger error only if both are invalid
      if (!predefinedValid && !customValid) {
        setError("selectedCustomIdentifiers", {
          type: "manual",
          message: "You must select at least one identifier from Predefined or Custom Step.",
        });
        isStepValid = false;
      } else {
        clearErrors("selectedCustomIdentifiers"); // Clear errors if validation passes
      } 
    } else if (activeStep === 2) {
      isStepValid = await trigger("ruleExpression");
    } else if (activeStep === 3) {
      isStepValid = await trigger("scanOptions");
    } else if (activeStep === 4) {
      isStepValid = await trigger("severityThresholds");
    }
  
    if (isStepValid) {
      setActiveStep((prev) => prev + 1);
    }
  }, [activeStep, getValues, setError, clearErrors, trigger]);

  useEffect(() => {
    // Scroll to the top of the scrollable container when activeStep changes
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTop = 0;
    }
  }, [activeStep]);

  const handleBack = useCallback(() => {
    setActiveStep((prev) => prev - 1);
  }, []);

  const onSubmit = async (data) => {
    console.log("Submitting data:", data);
  
    // Check if Predefined or Custom Identifiers are valid
    const predefinedValid = Object.values(data.selectedIdentifiers || {}).some(
      (identifiers) => identifiers.length > 0
    );
    const customValid = (data.selectedCustomIdentifiers || []).length > 0;
  
    // If neither is valid, block the submission
    if (!predefinedValid && !customValid) {
      setError("selectedCustomIdentifiers", {
        type: "manual",
        message: "You must select at least one identifier from Predefined or Custom Step.",
      });
      return;
    }
  
    // Create a Set of predefined identifiers to deduplicate
    const predefinedIdentifiers = new Set(
      Object.values(data.selectedIdentifiers).flat()
    );
  
    // Filter custom identifiers to exclude any that exist in predefined
    const filteredCustomIdentifiers = data.selectedCustomIdentifiers.filter(
      (custom) => !predefinedIdentifiers.has(custom.identifierName)
    );
  
    // Transform form's object structure to API array format
    const transformedIdentifiers = Object.entries(data.selectedIdentifiers)
      .flatMap(([categoryId, identifiers]) =>
        identifiers.map((identifier) => ({
          identifierName: identifier,
          // Add null check and use optional chaining
          condition: data.regexConditions?.find((c) => c.identifier === identifier)?.condition || null,
        }))
      );
  
    // Add filtered custom identifiers to the transformed array
    const finalSelectedIdentifiers = [
      ...transformedIdentifiers,
      ...filteredCustomIdentifiers.map((custom) => ({
        identifierName: custom.identifierName,
        condition: custom.condition,
      })),
    ];
  
    setIsSaving(true);
    try {
      const trimmedProfileName = data.profileName.trim();
  
      // Check if the profile name is unique (only when creating/updating the profile)
      if (!selectedDlpProfile || trimmedProfileName !== selectedDlpProfile.name) {
        const isUnique = await checkProfileNameUnique(trimmedProfileName);
        if (!isUnique) {
          setError("profileName", {
            type: "manual",
            message: "A profile with this name already exists. Choose a different name.",
          });
          setIsSaving(false);
          return;
        }
      }
  
      // Prepare the final payload
      const payload = {
        profileName: data.profileName,
        ruleExpression: data.ruleExpression,
        scanOptions: data.scanOptions,
        severityThresholds: data.severityThresholds,
        thresholdType: data.thresholdType,
        countUniqueRecord: data.countUniqueRecord,
        policyActionLevel: data.policyActionLevel,
        isCustom: true,
        selectedIdentifiers: finalSelectedIdentifiers, // Use the transformed array
      };
      console.log("Saving payload:", payload);
  
      let response;
      if (selectedDlpProfile) {
        // Update existing profile
        response = await api.put(
          `/dlp/profiles?action=update&EntityId=${selectedDlpProfile.id}`,
          { Attributes: payload },
          { withCredentials: true }
        );
      } else {
        // Create new profile
        response = await api.post(
          "/dlp/profiles?action=create",
          { Attributes: payload },
          { withCredentials: true }
        );
      }
  
      if (response.status === 201 || response.status === 200) {
        console.log("Profile saved successfully:", response.data);
        onProfileCreated(response.data); // Notify parent component
      } else {
        console.error("Error saving profile:", response.data);
      }
    } catch (error) {
      console.error("Error in onSubmit:", error.response || error.message);
    } finally {
      setIsSaving(false);
    }
  };

  const renderStepContent = useMemo(
    () => (step) => {
      switch (step) {
        case 0:
          return <PredefinedStep />;
        case 1:
          return <CustomStep />;
        case 2:
          return <AdvancedOptionsStep />;
        case 3:
          return <ContentStep />;
        case 4:
          return <SeverityThreshold />;
        case 5:
          return <SetRule />;
        default:
          return null;
      }
    },
    []
  );

  return (
    <Box className="container py-10">
      <Container
        sx={{
          backgroundColor: colors.primary[400],
          maxWidth: "90vw", // Increase maximum width to cover more screen space
          width: "90vw", // Set width to 90% of the viewport width for more room
          maxHeight: "80vh",
          overflow: "hidden",
          marginLeft: "-250px",
          marginRight: "auto",
        }}
      >
        {/* Header with Close Icon */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "40px",
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            sx={{
              fontWeight: "bold",
              color: colors.blueAccent[500],
              maxWidth: { xs: "90%", sm: "70%", md: "700px" },
              textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
            }}
          >
            {selectedDlpProfile ? 'Edit DLP Profile' : 'Create New DLP Profile'}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Stepper */}
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<CustomConnector />}
          sx={{
            width: "100%",
            maxWidth: "90vw", // Adjust to fit the new container width
            marginBottom: 6,
            marginRight: 3,
            flexWrap: { xs: "wrap", sm: "nowrap" },
            "& .MuiStep-root": {
              flex: 1,
              minWidth: "100px",
            },
          }}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                StepIconComponent={() =>
                  StepIcon({
                    icon: stepIcons[index],
                    active: activeStep === index,
                    completed: activeStep > index,
                  })
                }
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: "medium",
                    color: activeStep === index ? colors.blueAccent[400] : colors.grey[500],
                    transition: "color 0.3s ease",
                  }}
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        {/* Form Content */}
        <Box
          ref={scrollableContainerRef}
          sx={{
            overflowY: 'auto',
            maxHeight: '60vh',
            pr: 1
          }}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {renderStepContent(activeStep)}

              {/* Buttons */}
              <Box
                sx={{
                  position: "sticky",
                  bottom: 36,
                  display: "flex",
                  justifyContent: "space-between",
                  py: 2,
                }}
              >
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  variant="outlined"
                  color="secondary"
                  sx={{
                    borderColor: activeStep === 0 ? colors.grey[500] : colors.blueAccent[500],
                    color: activeStep === 0 ? colors.grey[500] : colors.blueAccent[500],
                    "&:hover": {
                      borderColor: colors.blueAccent[400],
                    },
                    transition: "all 0.3s",
                  }}
                >
                  Back
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  disabled={isSaving}
                  onClick={isLastStep ? handleSubmit(onSubmit) : handleNext}
                  sx={{
                    backgroundColor: colors.blueAccent[400],
                    color: colors.grey[100],
                    "&:hover": {
                      backgroundColor: colors.blueAccent[300],
                    },
                    transition: "all 0.3s",
                  }}
                >
                  {isSaving ? <CircularProgress size={24} /> : isLastStep ? "Save" : "Next"}
                </Button>
              </Box>
            </form>
          </FormProvider>
        </Box>
        <Divider />
      </Container>
    </Box>
  );
};

export default DLPProfileForm;